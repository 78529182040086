import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Stack, Button, Chip, Typography, Switch, FormControlLabel } from "@mui/material";
import { getOrganisation, createOrganisation, updateOrganisation } from "./../common/apiHelpers";
import { BooleanDropdown, Dropdown } from "./../components/fields";
import { useSnackbar } from "notistack";

const CreateOrganisationForm  = (props: any) => {
	const editing: boolean = props.edit !== undefined ? props.edit : false;
  const entityId = editing ? props.entityId : undefined;
  const { enqueueSnackbar } = useSnackbar(); 

	// data, fetched from backend
	const [organisation, setOrganisation] = useState<any|null>(null);

	// state, derived from above
	const [name, setName] = useState<string>("");
	const [type, setType] = useState<string|null|undefined>("");
	const [description, setDescription] = useState<string>("");
	const [shortDescription, setShortDescription] = useState<string>("");
	const [services, setServices] = useState<string>("");
	const [website, setWebsite] = useState<string>("");
  const [address, setAddress]  = useState<string>("");
	const [email, setEmail] = useState<string|null|undefined>("");
	const [phone, setPhone] = useState<string|null|undefined>("");
	const [errors, setErrors] = useState<any|null|undefined>(null);
	const [showOnVis, setShowOnVis] = useState<boolean>(true);

	// other data
	const organisationTypeOptions = [
    {value:"Government", text:"Government"},
    {value:"Government agency", text:"Government Agency"},
    {value:"Industry group", text:"Industry Group"},
    {value:"Informal group", text:"Informal Group"},
    {value:"Lobby group", text:"Lobby Group"},
    {value:"Non-government organisation", text:"Non-government Organisation"},
    {value:"Sector peak body", text:"Sector Peak Body"},
    {value:"Other", text:"Other"}
	];

	// events
	const onSubmitClicked = () => {
		// payload for creating and editing a business record
		const payload : any = {
			org_name : name,
			org_type: type,
			org_description: description,
      org_short_description: shortDescription,
			org_services: services,
			org_website: website,
			org_email: email,
			org_phone: phone,
			org_address: address,
			show_on_visualisations: showOnVis
		};
		const s = async (response: any) => {
		  if(response.ok === false){
				if(response.status === 401){
					enqueueSnackbar(`You dont have permission to create/update organisations.`, {variant:"error"});
					return;
				}
				const errors = await response.json();
				setErrors(errors);
				return;
			}
			if(editing){
				props.onOrganisationUpdated();
				return;
			}
			props.onOrganisationCreated();
		};
    const e = (error: any) => {
			console.log("Something went wrong");
		};
		if(editing){
			updateOrganisation(entityId, payload, s, e);
			return;
		}
		createOrganisation(payload, s, e);
	};
	// effect handlers
	// load organisation
	const loadOrganisation = () => {
		if(editing && organisation === null){
			const s = async (response: any) => {
				const data = await response.json();
				// set entity data first
				// this will stop this effect from running
				setOrganisation(data);	
				// set other data properties
				setName(data.org_name);
				setType(data.org_type);
				setDescription(data.org_description);
        setShortDescription(data.org_short_description);
				setServices(data.org_services);
				setWebsite(data.org_website);
				setPhone(data.org_phone);
				setEmail(data.org_email);
				setAddress(data.org_address);
				setShowOnVis(data.show_on_visualisations);
			};
			const e = () => {
				enqueueSnackbar(`Failed to get organisation for id ${entityId}`, {variant:"error"});
			};
			getOrganisation(entityId, s, e);
		}
	};	
	const onShortDescriptionChanged = (event:any) => {
		let desc: string = event.target.value;
		const limit = 80;
		if(desc.length > 80){
			enqueueSnackbar("80 character limit for short description.", {variant:"warning"});
		}
		desc = desc.slice(0, limit);
		setShortDescription(desc);
	};
	// effects
	useEffect(loadOrganisation);
	if(organisation === null && editing){
		return (
			<p>Loading ...</p>
		);	
	}
	let errorDetails = null;
	if(errors !== null && errors !== undefined){
		const detail = errors.detail;
		errorDetails = (
			<Box id="errors" sx={{textAlign:"center", padding:"15px", borderRadius:"5px", border:"1px solid red", backgroundColor:"rgba(255,0,0,0.5)"}}>
				<Typography sx={{fontWeight:"bold"}}>
					{detail}
				</Typography>
			</Box>	
		);
	}
	return (
		<Grid container>
			<Grid item sm={12} sx={{padding:"10px"}}>
				<Stack spacing={3}>
					{errorDetails}
					<TextField value={name} onChange={(event)=>{setName(event.target.value);}} fullWidth label="Name" helperText={"The organisations name."}/>
					<Dropdown 
						value={type} 
						onValueChanged={(event:any)=>{setType(event.target.value);}} 
						fullWidth 
						label="Organisation Type" 
						options={organisationTypeOptions} 
						helperText={"What type of organisation is this?"} />
					<TextField value={shortDescription} onChange={onShortDescriptionChanged} fullWidth label="Short Description" helperText={"A single line description of the organisation (80 character limit)."}/>
					<TextField value={description} onChange={(event)=>{setDescription(event.target.value);}} fullWidth minRows={5} multiline={true} label="Description" helperText={"A longer description of the organisation."}/>
					<TextField value={services} onChange={(event)=>{setServices(event.target.value);}} fullWidth label="Services" helperText={"What services does this organisation provide?"} />
					<TextField value={website} onChange={(event)=>{setWebsite(event.target.value);}} fullWidth label="Wesbite" helperText={"Website of the organisation"} />
					<TextField value={email} onChange={(event)=>{setEmail(event.target.value);}} fullWidth label="Email" helperText={"Contract email address of the organisation."} />
					<TextField value={phone} onChange={(event)=>{setPhone(event.target.value);}} fullWidth label="Phone" helperText={"Contact phone number of the organisation."} />
					<TextField value={address} onChange={(event)=>{setAddress(event.target.value);}} fullWidth label="Address" helperText={"Address of the organisation."} />
					<Box sx={{textAlign:"center"}}>
						<FormControlLabel control={<Switch checked={showOnVis} onChange={()=>{setShowOnVis(!showOnVis);}}/>} label={"Show on Visualisations?"} labelPlacement={"start"}/>
					</Box>
					<Button variant={"contained"} fullWidth onClick={onSubmitClicked} size={"large"}>Submit</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default CreateOrganisationForm;
