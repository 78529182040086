import user from "./User";
import { OutletState, BusinessEntity } from "./types";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type OutletStateId = {
  outletId: string | null;
  outletStateId: string | number | null;
};

export const getHeaders = () => ({
  Authorization: `Bearer ${
    user.currentUser && user.currentUser.signInUserSession.accessToken.jwtToken
  }`
});

export function getActiveDates({ outletId }: Partial<OutletStateId>) {
  return fetch(`${BASE_URL}/outlets/${outletId}/active_dates`, {
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export const uploadWebsiteHealthData = (s: any, e: any, payload: any) => {
	fetch(`${BASE_URL}/contacts/website-health-information`, {headers: getHeaders(), method:"POST", body: JSON.stringify(payload)}).then(s).catch(e);
};

export const unlinkCopFromOutlet = (s:any, e:any, copId:number, outletId:string) => {
	fetch(`${BASE_URL}/outlets/${outletId}/codes_of_practice/${copId}`, {headers: getHeaders(), method:"DELETE"}).then(s).catch(e);
};

export const linkCodesOfPractice = (s:any, e:any, outletId:number, payload:any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/codes_of_practice`, {headers: getHeaders(), method:"POST", body: JSON.stringify(payload)}).then(s).catch(e);
};

export const getOutletCodesOfPractice = (s:any, e:any, outletId:string) => {
	fetch(`${BASE_URL}/outlets/${outletId}/codes_of_practice`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getCodeOfPractice = (s:any, e:any, copId:string, includeOutlets:string) => {
	fetch(`${BASE_URL}/code_of_practice/${copId}?includeOutlets=${includeOutlets}`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getCodesOfPractice = (s:any, e:any) => {
	fetch(`${BASE_URL}/code_of_practice/`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getCopPdf = (s:any, e:any, copId:string) => {
	fetch(`${BASE_URL}/code_of_practice/${copId}/pdf`, {headers: getHeaders(), method:"GET"}).then(s).catch(e);
};

export const getCopLinkedOutlets = (s:any, e:any, copId:string) => {
	fetch(`${BASE_URL}/code_of_practice/${copId}/outlets`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getChangeTypeChoices = (s:any, e:any) => {
	fetch(`${BASE_URL}/outlets/changes/types`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getOutletPublicExport = (s:any, e:any) => {
	fetch(`${BASE_URL}/outlets/?export=true&public=true`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getOutletExportAll = (s:any, e:any) => {
	fetch(`${BASE_URL}/outlets/?export=true`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getOutletExportActive = (s:any, e:any) => {
	fetch(`${BASE_URL}/outlets/?export=true&active=true`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getOutletBroadcastAreas = (outletId: string, s:any, e:any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/broadcast_areas`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const deleteBroadcastAreaOutletLinkage = (areaId: number, outletId: string, s:any, e:any) => {
	fetch(`${BASE_URL}/broadcast_areas/${areaId}/outlets/${outletId}`, {headers: getHeaders(), method:"DELETE" }).then(s).catch(e);
};

export const getChangesExport = (s:any, e:any) => {
	fetch(`${BASE_URL}/outlets/changes/export`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const getBusinessExport = (s:any, e:any) => {
	fetch(`${BASE_URL}/business_entities?export=true`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export const deleteBusinessEntityOutletLinkage = (entityId: number, outletId: string, s: any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/business_entities/${entityId}`, {headers: getHeaders(), method:"DELETE" }).then((response)=>{
	if(!response.ok){
		throw new Error(response.statusText);
	}
		s();
	});
};

export const unlinkOrganisationFromBusiness = (organisationId: number, businessId: string, s: any, e: any) => {
	fetch(`${BASE_URL}/organisations/${organisationId}/business-entities/${businessId}`, {headers: getHeaders(), method:"DELETE" }).then(s).catch(e);
};

export const unlinkOrganisationFromOutlet = (organisationId: number, outletId: string, s: any, e: any) => {
	fetch(`${BASE_URL}/organisations/${organisationId}/outlets/${outletId}`, {headers: getHeaders(), method:"DELETE" }).then(s).catch(e);
};

export const linkBusinessesToOutlet = (outletId: string, payload:any, s:any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/business_entities`, {headers: getHeaders(), method:"POST", body: JSON.stringify(payload)}).then((response)=>{
	if(!response.ok){
		throw new Error(response.statusText);
	}
		s();
	});

};

export const linkBroadcastAreasToOutlet = (outletId: string, broadcastAreaIds: any, s: any, e: any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/broadcast_areas`, {headers: getHeaders(), method:"POST", body: JSON.stringify(broadcastAreaIds)}).then(s).catch(e);

};

export const linkOrganisationsToOutlet = (outletId: string, payload: any, s: any, e: any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/organisations`, {headers: getHeaders(), method:"POST", body: JSON.stringify(payload)}).then(s).catch(e);
};

export const linkOrganisationsToBusiness = (entityId: string, payload: any, s: any, e: any) => {
	fetch(`${BASE_URL}/business_entities/${entityId}/organisations`, {headers: getHeaders(), method:"POST", body: JSON.stringify(payload)}).then(s).catch(e);
};

export const deleteOrganisation = (entityId: any, s:any, e:any) => {
	fetch(`${BASE_URL}/organisations/${entityId}`, {headers: getHeaders(), method:"DELETE"}).then(s).catch(e);
};

export const getOrganisationsExport = (s:any, e:any) => {
	fetch(`${BASE_URL}/organisations?export=true`, {headers: getHeaders(), method:"GET"}).then(s).catch(e);
};

export const getOrganisations = (s:any, e:any) => {
	fetch(`${BASE_URL}/organisations?includeHidden=true`, {headers: getHeaders(), method:"GET"}).then(s).catch(e);
};

export const getOrganisation = (organisationId: string, s:any, e:any) => {
	fetch(`${BASE_URL}/organisations/${organisationId}`, {headers: getHeaders(), method:"GET"}).then(s).catch(e);
};

export const createOrganisation = (payload: any, s:any, e:any) => {
	fetch(`${BASE_URL}/organisations`, {headers: getHeaders(), method:"POST", body: JSON.stringify(payload)}).then(s).catch(e);
};

export const updateOrganisation = (organisationId: string, payload: any, s:any, e:any) => {
	fetch(`${BASE_URL}/organisations/${organisationId}`, {headers: getHeaders(), method:"PUT", body: JSON.stringify(payload)}).then(s).catch(e);
};

export const getOutletOrganisations = (outletId: string, s:any, e:any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/organisations`, {headers: getHeaders(), method:"GET"}).then(s).catch(e);
};

export const getBroadcastAreas = (s:any, e:any) => {
	fetch(`${BASE_URL}/broadcast_areas?includeGeometry=false`, {headers: getHeaders(), method:"GET"}).then(s).catch(e);
};

export const getBusinessEntitiesForOutlet = (outletId: string, s: any, e: any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/business_entity`, {headers: getHeaders(), method:"GET"}).then(s).catch(e);
};

export function createBusinessEntity(payload: BusinessEntity, callback: any, e: any){
	fetch(`${BASE_URL}/business_entities`, {headers: getHeaders(), method:"POST", body: JSON.stringify(payload)}).then((response)=>{
		if(!response.ok){
    	const status = response.status;
    	e(status);
			return;
		}
  	callback();
	});
}

export function updateBusinessEntity(entityId: string, payload: BusinessEntity, callback: any){
	fetch(`${BASE_URL}/business_entities/${entityId}`, { 
		headers: getHeaders(), 
		method: "PUT",
		body: JSON.stringify(payload)
	}).then((response)=>{
		if(!response.ok){
			 throw new Error(response.statusText);	 
		}		
		callback();
	});
}

export function deleteBusiness(entityId: string, callback: any){
	fetch(`${BASE_URL}/business_entities/${entityId}`, {
		headers: getHeaders(),
		method: "DELETE"
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		callback();
	});
}

export function deleteBusinessOutlet(entityId: string, outletId: number, callback: any){
	fetch(`${BASE_URL}/business_entities/${entityId}/outlets/${outletId}`, {
		headers: getHeaders(),
		method: "DELETE"
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);
	});
}

export function updateBusinessOutlets(entityId: string, payload: any, callback: any){
	fetch(`${BASE_URL}/business_entities/${entityId}/outlets`, {
		headers: getHeaders(),
		method: "POST",
		body: JSON.stringify(payload)
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);
	});
}

export function getBusinessOrganisations(entityId: string, callback: any){
	fetch(`${BASE_URL}/business_entities/${entityId}/organisations`, {
		headers: getHeaders()
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);
	});
}

export function getBusinessOutlets(entityId: string, callback: any){
	fetch(`${BASE_URL}/business_entities/${entityId}/outlets`, {
		headers: getHeaders()
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);
	});
}

export function doApproveChange(id: number, callback: any){
	fetch(`${BASE_URL}/change_requests/${id}/approve`, {
		method: "PUT",
		headers: getHeaders()
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);
	});
}

export function doRejectChange(id: number, s: any, e: any){
	fetch(`${BASE_URL}/change_requests/${id}/deny`, {
		method: "PUT",
		headers: getHeaders()
	}).then(s).catch(e);
}

export function getChangeRequests(callback: any){
	fetch(`${BASE_URL}/change_requests?includeActioned=false`, {
		headers: getHeaders()
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);
	});
}

export const getBusinessOutletsAsync = async (entityId: string) => {
	const response: any = await fetch(`${BASE_URL}/business_entities/${entityId}/outlets`, {
		headers: getHeaders()
	});
	if(!response.ok){
		throw new Error(response.statusText);
	}
	return await response.json();
}

export const getBusinessEntityAsync =(entityId: string, s: any, e: any) => {		
	fetch(`${BASE_URL}/business_entities/${entityId}?includeOutlets=false`, {headers: getHeaders()}).then(s).catch(e);
};

export function getBusinessEntity(entityId: string, callback: any){
	fetch(`${BASE_URL}/business_entities/${entityId}?includeOutlets=false`, {
		headers: getHeaders()
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);	
	});
}

export const getOutletChanges = (callback: any) => {
	fetch(`${BASE_URL}/outlets/changes`, {
		headers: getHeaders()
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);	
	});
};

export function getBusinessEntities(callback: any){
	fetch(`${BASE_URL}/business_entities`, {
		headers: getHeaders()
	}).then((response)=>{
		if(!response.ok){
			throw new Error(response.statusText);
		}
		response.json().then(callback);	
	});
}

export function getOutletsSync(callback: any) {
  fetch(`${BASE_URL}/outlets/`, {
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    response.json().then(callback);
  });
}

export function getOutlets() {
  return fetch(`${BASE_URL}/outlets/`, {
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function createOutlet(body: any) {
  return fetch(`${BASE_URL}/outlets/`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function deleteOutlet({ id }: { id: string | number | null }) {
  return fetch(`${BASE_URL}/outlets/${id}/`, {
    method: "DELETE",
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function createContact({ data, id }: any) {
  return fetch(`${BASE_URL}/contacts/${id}/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function getContactsAsync(s:any, e:any) {
  fetch(`${BASE_URL}/contacts/`, {
    headers: getHeaders()
  }).then(s).catch(e);
}

export function getContacts() {
  return fetch(`${BASE_URL}/contacts/`, {
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function getContact({ id }: { id: string }) {
  return fetch(`${BASE_URL}/contacts/${id}/`, {
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function updateContact({ data, id }: any) {
  return fetch(`${BASE_URL}/contacts/${id}/`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function deleteContact({ id }: { id: string | number | null }) {
  return fetch(`${BASE_URL}/contacts/${id}/`, {
    method: "DELETE",
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function getOutletStates({ id }: { id: string }) {
  return fetch(`${BASE_URL}/outlets/${id}/outlet-state/`, {
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function deleteOutletState({ outletId, outletStateId }: OutletStateId) {
  return fetch(
    `${BASE_URL}/outlets/${outletId}/outlet-state/${outletStateId}/`,
    {
      method: "DELETE",
      headers: getHeaders()
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export const getOutletStateAsync =(outletId: string, outletStateId: string, s: any, e: any) => {
	fetch(`${BASE_URL}/outlets/${outletId}/outlet-state/${outletStateId}`, {headers: getHeaders(), method:"GET" }).then(s).catch(e);
};

export function getOutletState({ outletId, outletStateId }: OutletStateId) {
  return fetch(
    `${BASE_URL}/outlets/${outletId}/outlet-state/${outletStateId}/`,
    {
      headers: getHeaders()
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function retireOutletState({
  outletId,
  outletStateId,
  data
}: OutletStateId & {
  data: { change_type_id: any; change_description: string; deprecated_at: string };
}) {
  return fetch(
    `${BASE_URL}/outlets/${outletId}/outlet-state/${outletStateId}/retire/`,
    { method: "PUT", body: JSON.stringify(data), headers: getHeaders() }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function createOutletState({
  outletId,
  outletState
}: Omit<OutletStateId, "outletStateId"> & { outletState: OutletState }) {
  return fetch(`${BASE_URL}/outlets/${outletId}/outlet-state/`, {
    method: "POST",
    body: JSON.stringify(outletState),
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function updateOutletState({
  outletId,
  outletStateId,
  outletState
}: OutletStateId & { outletState: OutletState }) {
  return fetch(
    `${BASE_URL}/outlets/${outletId}/outlet-state/${outletStateId}/`,
    { method: "PUT", body: JSON.stringify(outletState), headers: getHeaders() }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}
