// vim: ts=2
import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { OutletForm } from "./OutletForm";
import { 
	getOutlets,
	deleteOutlet, 
	getHeaders, 
	getOutletExportAll, 
	getOutletExportActive, 
	getOutletPublicExport 
} from "../common/apiHelpers";
import { OutletsResponse } from "../common/types";
import { OutletsTable } from "./OutletsTable";
import { SearchBar } from "../components/SearchBar";

const doExportAll = () => {
  const s = async (response: any)  => { 
      const data = await response.json();
      const encoded = data.data; // ascii encoded text ....
      const binary = atob(encoded);
      //@ts-ignore
      const buffer = Uint8Array.from(binary, (m)=>m.codePointAt(0));
      const blob = new Blob([buffer]);
      const url = URL.createObjectURL(blob);
      const download = document.getElementById("download");
      if(download instanceof HTMLAnchorElement){
        download.download = "piji-outlet-export-all.xlsx";
        download.href = url;
        download.click();
      }
   }
   const e = (error:any) => {};
   getOutletExportAll(s, e);
};

const doExportActive = () => {
  const s = async (response: any)  => { 
      const data = await response.json();
      const encoded = data.data; // ascii encoded text ....
      const binary = atob(encoded);
      //@ts-ignore
      const buffer = Uint8Array.from(binary, (m)=>m.codePointAt(0));
      const blob = new Blob([buffer]);
      const url = URL.createObjectURL(blob);
      const download = document.getElementById("download");
      if(download instanceof HTMLAnchorElement){
        download.download = "piji-outlet-export-active.xlsx";
	download.href = url;
        download.click();
      }
   }
   const e = (error:any) => {};
   getOutletExportActive(s, e);
};

export function Outlets() {
  const [query, setQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(
    undefined
  );
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    id: number | string | null;
    name: string | null;
  }>({
    open: false,
    id: null,
    name: null
  });
  const {
    isLoading,
    error,
    isError: isErrorOutlets,
    data: outletsData,
    refetch: refetchOutlets
  } = useQuery<OutletsResponse[]>("outletsAll", getOutlets);

  const refetch = () => {
    refetchOutlets();
  };
  const {
    mutate: mutateOutlet,
    isLoading: isLoadingOutlet,
    isError: isErrorMutateOutlet,
    isSuccess: isSuccessDeleteOutlet,
    data: deleteData
  } = useMutation(deleteOutlet, {
    onSuccess: () => {
      setDeleteDialog({
        open: false,
        id: null,
        name: null
      });
      refetch();
    }
  });

  useEffect(() => {
    if (isErrorOutlets) {
      enqueueSnackbar("Unable to get all outlets", { variant: "error" });
    }
    if (isErrorMutateOutlet) {
      enqueueSnackbar("Unable to delete outlet", { variant: "error" });
    }
  }, [isErrorOutlets, isErrorMutateOutlet]);

  const toggleCreateDialog = useCallback(() => {
    setCreateDialogOpen(!createDialogOpen);
  }, [createDialogOpen]);

  const closeCreateDialog = useCallback(() => {
    refetch();
    setCreateDialogOpen(false);
  }, [refetch]);

  const toggleDeleteDialog = useCallback(() => {
    refetch();
    setDeleteDialog({ ...deleteDialog, open: !deleteDialog.open });
  }, [deleteDialog]);

  const closeMenu = () => {
    setAnchorElement(undefined);
  };

  const handleExportButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
 
  const handlePublicExportButton = (event: React.MouseEvent<HTMLButtonElement>) => {
      const s = async (response: any)  => { 
          const data = await response.json();
          const encoded = data.data; // ascii encoded text ....
          const binary = atob(encoded);
          //@ts-ignore
          const buffer = Uint8Array.from(binary, (m)=>m.codePointAt(0));
          const blob = new Blob([buffer]);
          const url = URL.createObjectURL(blob);
          const download = document.getElementById("download");
          if(download instanceof HTMLAnchorElement){
              download.download = "piji-outlet-export-public.xlsx";
              download.href = url;
              download.click();
          }
      }
      const e = (error:any) => {};
      getOutletPublicExport(s, e);
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (error || !outletsData) {
    return <div>Error ...</div>;
  }

  const filteredOutlets = query
    ? outletsData.filter(
        (item) =>
          item.outlet_state.name.toLowerCase().search(
            /**
             * Ensure characters are escaped when using string literals
             * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
             */
            query.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
          )  !== -1 || ( item.outlet_state.callsign !== null && item.outlet_state.callsign !== undefined && item.outlet_state.callsign.toUpperCase().indexOf(query.toUpperCase()) !== -1 )
      )
    : outletsData;

  const handleSetQuery = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setQuery(e.target.value);
  };

  const handleClearQuery = () => {
    setQuery("");
  };

  return (
    <>
      <Dialog
        open={createDialogOpen}
        onClose={toggleCreateDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <OutletForm closeDialog={closeCreateDialog} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteDialog.open}
        onClose={toggleDeleteDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <Typography>This cannot be undone.</Typography>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setDeleteDialog({ open: false, id: null, name: null });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                mutateOutlet({ id: deleteDialog.id });
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        position="relative"
        marginBottom="24px"
        marginTop="8px"
      >
        <Box position="absolute" left={`calc(50% - 300px)`}>
          <SearchBar
            label="Search outlets"
            query={query}
            handleClearQuery={handleClearQuery}
            handleSetQuery={handleSetQuery}
            doQuery={()=>{}}
          />
        </Box>
        <Box
          sx={{
            marginLeft: "auto"
          }}
        >
          <Box>
            <Button
              aria-controls="export-outlets-menu"
              aria-haspopup="true"
              variant="outlined"
              aria-expanded={Boolean(anchorElement) ? "true" : undefined}
              onClick={handleExportButton}
              sx={{ marginRight: "16px" }}
            >
              Export
            </Button>
            <Menu
              open={Boolean(anchorElement)}
              onClose={closeMenu}
              anchorEl={anchorElement}
              id="export-outlets-menu"
            >
              <MenuItem
                divider
                component="a"
                href={"/outlets"}
                onClick={(event:any)=>{event.preventDefault();doExportActive()}}
              >
                <ListItemIcon>
                  <DownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Reporting</ListItemText>
              </MenuItem>
              <MenuItem
                divider
                component="a"
                href={"/outlets"}
                onClick={(event:any)=>{event.preventDefault();handlePublicExportButton(event);}}
              >
                <ListItemIcon>
                  <DownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Public</ListItemText>
              </MenuItem>
              <MenuItem
                component="a"
                href={"/outlets"}
                onClick={(event:any)=>{event.preventDefault();doExportAll()}}
              >
                <ListItemIcon>
                  <DownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>All</ListItemText>
              </MenuItem>
            </Menu>
            <Button
              variant="contained"
              onClick={toggleCreateDialog}
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </Box>
        </Box>
	<a download={"piji-outlet-export.csv"} href="" style={{display:"none"}} id="download" />
      </Box>
      {filteredOutlets && (
        <OutletsTable
          outlets={filteredOutlets}
          setDeleteDialog={(options) => {
            setDeleteDialog(options);
          }}
        />
      )}
    </>
  );
}
