import { parse } from "date-fns";
import { OutletState } from "./types";

/** These fields are not needed on the form, and should't be including when updating an outlet state */
export const cleanOutletState = (outlet: OutletState) => {
  const {
    id,
    is_broadcast,
    status,
    deprecated_at,
    change_type,
    change_category,
    ...rest
  } = outlet;

  const updated = Object.entries(rest).reduce((accumulator, [key, value]) => {
    if (value === null) {
      return {
        ...accumulator,
        [key]: undefined
      };
    }
    if (key === "current_at") {
      return {
        ...accumulator,
        [key]: parse(value, "yyyy-MM-dd", new Date())
      };
    }
    return { ...accumulator, [key]: value };
  }, {});

  return updated;
};
