// vim: ts=2
import React, { useContext, useEffect, useState, useCallback } from "react" ;
import DataTable from "./../components/DataTable/DataTable";
import Dialogs from "./../common/dialogs";
import {
  Box,
	Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
	List,
	ListItem,
	IconButton,
  Menu,
  MenuItem,
  Typography,
	CircularProgress,
  Grid,
	Tabs,
	Tab
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { getCodeOfPractice, getCopLinkedOutlets, getCopPdf } from "../common/apiHelpers";
import { SearchContext } from "./../common/searchContext";
import { SearchBar } from "../components/SearchBar";
import { Link, useParams } from "react-router-dom";

const LINKED_OUTLETS_TAB = 0;
const COP_PREVIEW = 1;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const DocumentView = (props:any) => {
	const cop = props.cop;
	const [pdf, setPdf] = useState<any|null>(null);
	const getPdf = () => {
		if(pdf !== null){
			return;
		}
		const s = async (response:any) => {
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);
			setPdf(url);
		};
		const e = (error:any) => {
			console.log("Failed to get pdf for CoP");
		};
		getCopPdf(s, e, cop.id);
	};
	useEffect(getPdf);
	if(pdf === null){
		return (
			<Grid container>
				<Grid item xs={12} sx={{textAlign:"center"}}>
					<CircularProgress/>
				</Grid>
			</Grid>
		);
	}
	return (
		<Grid container>
			<Grid item xs={12} sx={{textAlign:"center"}}>
				<embed type="application/pdf" src={pdf}  width={"640"} height={"720"}/>
			</Grid>
		</Grid>
	);
};

const LinkedOutletsView = (props:any) => {
	const cop = props.cop;
	const copId = cop.id;
	const linkedOutlets = cop.outlets;
	if(linkedOutlets === null){
		return (
			<Grid container>
				<Grid item xs={12}>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}
	return (
		<Grid container>
			<Grid item xs={12}>
				<List>
				{linkedOutlets.map((e:any,i:any)=>{ return <ListItem><ListItemText secondary={"Outlet"} primary={e.name}/></ListItem>})}
				</List>
			</Grid>
		</Grid>
	);
};

const ViewCodeOfPractice = (props:any) => {
  const params = useParams<any>();
	const [code, setCode] = useState<null|any>(null);
	const [tab, setTab] = useState<number>(LINKED_OUTLETS_TAB);
	useEffect(()=>{
		if(code !== null){
			return;
		}	
		const s = async (response:any) => {	
			const data = await response.json();
			setCode(data);
		};
		const e = (error:any) => {
			console.log("Failed to load code of practice ...");
		};
		// include outlets ...
		getCodeOfPractice(s, e, params.copId, "True");
	});
	if(code === null){
		return <p>Loading code ...</p>;
	}
	const getLinkedOutlets = () => {
		return (
			<LinkedOutletsView cop={code} />
		);
	};
	const getTabContent = () => {
		switch(tab){
			case LINKED_OUTLETS_TAB:
				return getLinkedOutlets();
			case COP_PREVIEW:
				return <DocumentView cop={code} />;
			default:
				return getLinkedOutlets();
		}
	};
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant={"h4"}>{`Viewing - ${code.name}`}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Tabs value={tab}>
					<Tab label={"Linked Outlets"} onClick={()=>{setTab(0);}}/>
					<Tab label={"Code Of Practice"} onClick={()=>{setTab(1);}}/>
				</Tabs>
			</Grid>
			<Grid item xs={12}>
				{getTabContent()}
			</Grid>
		</Grid>
	);
};

export default ViewCodeOfPractice;
