import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  Typography
} from "@mui/material";
import { MobileDatePicker } from "@mui/lab";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { formatISO } from "date-fns";
import {
  ControlledSelect,
  ControlledMultipleSelect
} from "../components/ControlledSelect";
import { lgaChoices } from "../sources/lga";
import { broadcastChoices } from "../sources/broadcast";
import {
  stateChoices,
  primaryFormatChoices,
  publicationFormatsChoices,
  publicationScheduleChoices,
  scaleChoices,
  metroChoices,
  broadcastLicenseSubserviceChoices
} from "../common/constants";
import { updateOutletState } from "../common/apiHelpers";
import { OutletStateEditSchema } from "../common/schemas";
import { InputWrapper } from "../components/InputWrapper";
import { minDate } from "../common/formatDate";
import { OutletState } from "../common/types";
import { useScaleWatch } from "./form-hooks/useScaleWatch";
import { useShowFields } from "./form-hooks/useShowFields";
import { useLgaCoverageError } from "./form-hooks/useLgaCoverageError";
import { cleanOutletState } from "../common/cleanUpdateState";

type OutletStateEditFormProps = {
  closeDialog: () => void;
  outletState?: OutletState | null;
  outletId: string;
};

export function OutletStateEditForm({
  closeDialog,
  outletState,
  outletId
}: OutletStateEditFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
		getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(OutletStateEditSchema),
    defaultValues: {
      ...(outletState ? cleanOutletState(outletState) : {})
    }
  });

  const lgaCoverageError = useLgaCoverageError(watch);

  const {
    mutate: mutateUpdateOutletState,
    isLoading: isLoadingUpdateOutlet,
    isError: isErrorUpdateOutlet,
    isSuccess: isSuccessUpdateOutlet
  } = useMutation(updateOutletState, { onSuccess: closeDialog });
  const [mediaType, setMediaType] = useState("alternative");

  const onSubmit = (event: any) => {
    let outletStateEdited = event;

    // 1. if scale !== metro, metro_area = null, otherwise take value
    if (event.scale !== "metro") {
      outletStateEdited = { ...outletStateEdited, metro_area: null };
    }
    // 2. if scale === national, state = null, otherwise take value
    if (event.scale === "national") {
      outletStateEdited = { ...outletStateEdited, state: null };
    }
    // 3. if scale !== local, secondary_coverage = null, lga_name = null
    if (event.scale !== "local") {
      outletStateEdited = {
        ...outletStateEdited,
        secondary_coverage: null,
        lga_name: null
      };
    }
    if (!event.primary_coverage) {
      outletStateEdited = {
        ...outletStateEdited,
        primary_coverage: null
      };
    }

    if (mediaType === "alternative") {
      outletStateEdited = {
        ...outletStateEdited,
        broadcast_area: null
      };
    }

    mutateUpdateOutletState({
      outletId,
      outletStateId: outletState?.id || "",
      outletState: {
        ...outletStateEdited,
        current_at: formatISO(event.current_at)
      }
    });
    return;
  };

  const scaleWatch = watch("scale");
  const { scaleCleanup } = useScaleWatch();
  useEffect(() => {
    scaleCleanup(scaleWatch, mediaType === "broadcast", setValue);
  }, [scaleWatch, mediaType]);

  const broadcastWatch = watch("broadcast_area");
  useEffect(() => {
    setMediaType(broadcastWatch ? "broadcast" : "alternative");
  }, [watch("broadcast_area")]);

  const handleMediaTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMediaType(e.target?.value);
  };

  useEffect(() => {
    if (isErrorUpdateOutlet) {
      enqueueSnackbar("Unable to update outlet state", { variant: "error" });
    }
  }, [isErrorUpdateOutlet]);

	const getSubService = (format: string, scale: string) => {
		// logic for determining subservice from format and scale
		// commercial radio
		if(format === "radio" && scale === "local"){
			return "commercial_radio";
		}
		// commercial television
		if(format === "television" && scale === "local"){
			return "commercial_television";
		}
		// community radio
		if(format === "radio" && scale === "community"){
			return  "community_broadcasting";
		}
	};

	useEffect(()=>{
		const values = getValues();
		const subservice = values.broadcast_license_subservice;
		const scale = values.scale;
		const format = values.primary_format;
		if(format === undefined || format === null)
			return;
		if(scale === undefined || scale === null)
			return;
		const subserviceEmpty = subservice === undefined || subservice === null || subservice === "";
		const desiredValue = getSubService(format, scale);
		if(desiredValue === undefined){
			return;
		}
		if(subserviceEmpty){
			setValue("broadcast_license_subservice", desiredValue);
		}
	});

  const {
    showMetro,
    showState,
    showLgaCoverage,
    showBroadcastArea,
		showCallsign,
		showBroadcastLicenseSubservice
  } = useShowFields(watch, mediaType);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "grid",
        gap: "16px"
      }}
    >
      <>
        <Typography variant="h6">Edit outlet state information</Typography>
        <FormControl>
          <FormLabel>Outlet type</FormLabel>
          <RadioGroup
            row
            value={mediaType}
            onChange={(e) => handleMediaTypeChange(e)}
          >
            <FormControlLabel
              value="alternative"
              control={<Radio />}
              label="Non-broadcast media"
            />
            <FormControlLabel
              value="broadcast"
              control={<Radio />}
              label="Broadcast media"
            />
          </RadioGroup>
        </FormControl>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputWrapper errorMessage={errors.name?.message}>
              <TextField
                label="Outlet name"
                error={!!errors.name?.message}
                {...field}
              />
            </InputWrapper>
          )}
        />
        <Controller
          name="current_at"
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              {...field}
              label="Current at"
              renderInput={(params) => <TextField {...params} />}
              minDate={minDate}
            />
          )}
        />
        <ControlledSelect
          name="scale"
          label="Scale"
          control={control}
          choices={scaleChoices}
          errorMessage={errors.scale?.message}
        />
        <ControlledSelect
          name="primary_format"
          label="Primary format"
          control={control}
          choices={primaryFormatChoices}
          errorMessage={errors.primary_format?.message}
        />
        <ControlledMultipleSelect
          name="publication_formats"
          label="Publication formats"
          control={control}
          choices={publicationFormatsChoices}
          errorMessage={
            errors?.publication_formats &&
            errors.publication_formats[0]?.message
          }
        />
        <ControlledMultipleSelect
          name="publication_schedule"
          label="Publication Schedule"
          control={control}
          choices={publicationScheduleChoices}
        />
        <Controller
          name="primary_coverage"
          control={control}
          render={({ field }) => (
            <InputWrapper errorMessage={errors.primary_coverage?.message}>
              <TextField
                label="Primary coverage"
                error={!!errors.primary_coverage?.message}
                {...field}
              />
            </InputWrapper>
          )}
        />
			{showCallsign && (
      <Controller
        name="callsign"
        control={control}
        render={({ field }) => (
        	<TextField
          	label="Callsign"
            {...field}
          />
        )}
      />)
			}
			{showBroadcastLicenseSubservice && (
      <ControlledSelect
        name="broadcast_license_subservice"
        label="License Subservice"
        control={control}
        choices={broadcastLicenseSubserviceChoices}
        errorMessage={errors.broadcast_license_subservice?.message}
      />)
			}
        {showMetro && (
          <ControlledSelect
            name="metro_area"
            label="Metro area"
            control={control}
            choices={metroChoices}
            errorMessage={errors.metro_area?.message}
            disabled={scaleWatch !== "metro"}
          />
        )}
        {showState && (
          <ControlledSelect
            name="state"
            label="State or territory"
            control={control}
            choices={stateChoices}
            errorMessage={errors.state?.message}
            disabled={scaleWatch === "national"}
          />
        )}
        {showLgaCoverage && (
          <Controller
            name="lga_coverage"
            control={control}
            render={({ field }) => (
              // @ts-ignore
              <Autocomplete
                {...field}
                value={
                  field?.value &&
                  Array.isArray(field?.value) &&
                  field?.value?.length
                    ? lgaChoices.filter((lga) => field?.value?.includes(lga.id))
                    : []
                }
                onChange={(_event, newValue) => {
                  field.onChange(newValue.map((value) => value?.id));
                }}
                disabled={scaleWatch !== "local" && scaleWatch !== "community"}
                multiple
                id="lga_coverage-auto"
                options={lgaChoices}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="LGA coverage"
                    helperText={
                      lgaCoverageError
                        ? "You must select at least one LGA."
                        : "Start typing for suggestions"
                    }
                    error={lgaCoverageError}
                  />
                )}
              />
            )}
          />
        )}
        {showBroadcastArea && (
          <Controller
            name="broadcast_area"
            control={control}
            render={({ field }) => (
              // @ts-ignore
              <Autocomplete
                {...field}
                value={broadcastChoices.find(
                  (broadcast) => broadcast.id === field.value
                )}
                onChange={(_event, newValue) => {
                  field.onChange(newValue?.id);
                }}
                id="broadcast-auto"
                options={broadcastChoices}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Broadcast area"
                    helperText="Start typing for suggestions"
                  />
                )}
              />
            )}
          />
        )}
        <Typography variant="h6">Visualisation</Typography>
        <Controller
          name="display"
          control={control}
          render={({ field }) => (
            <Box style={{ justifySelf: "start" }}>
              <FormControlLabel
                label="Display on visualisations?"
                labelPlacement="start"
                control={<Switch {...field} checked={field.value} />}
              />
            </Box>
          )}
        />
      </>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{
            marginRight: "16px"
          }}
          variant="outlined"
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoadingUpdateOutlet}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}
