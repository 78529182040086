import React, { useState } from "react";
import ContactTable from "../components/ContactTable";
import { TextField, Button, Grid, Typography, Tab, Tabs, Box, Stack, InputAdornment, IconButton } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { uploadWebsiteHealthData } from "./../common/apiHelpers";
import { useSnackbar } from "notistack";

const WebsiteStatusUploadForm = (props:any) => {
	const {enqueueSnackbar} = useSnackbar();
	const [filename, setFilename] = useState<any|null>("choose a file ...");
	const [data, setData] = useState<string|null>(null);
	const onFileChanged = (event:any) => {
		const reader = new FileReader();
		reader.onload = (event:any) => {
			const results = event.currentTarget.result;
			setData(JSON.parse(results));
		};
		setFilename(event.target.files[0].name);
		reader.readAsText(event.target.files[0]);
	};
	const onFormSubmitted = () => {
		let submitResult: any = document.getElementById("submitResult");
		const s = (response:any) => {
			// capture status of submit
			if(submitResult !== null){
				submitResult.value = response.status;
			}
			// check for response status
			// give user feedback
			if(!(response.status === 204 || response.status === 200 || response.status === 201)){
				enqueueSnackbar(`Failed to upload data, status was ${response.status}`, {variant:"error"});
				return;
			}
			enqueueSnackbar("Data uploaded", {variant:"success"});
		};	
		const e = (error:any) => {
			enqueueSnackbar("Failed to upload data", {variant:"error"});
		};
		// before submitting reset value of submit result
		uploadWebsiteHealthData(s, e, data);
	};
	return (
		<Grid container>
			<Grid item lg={4}/>
			<Grid item xs={12} lg={4}>
				<Box sx={{border:"1px solid #CDCDCD", textAlign:"center", borderRadius:"10px", padding:"15px"}}>
					<Stack spacing={2}>
						<Typography variant={"h4"}>Upload Website Health Data</Typography>
						<Typography variant={"body1"}>Use this form to upload health data about outlets' websites.</Typography>
						<TextField value={filename} disabled helperText={"Please upload a JSON file containing the report results."} InputProps={{endAdornment: <InputAdornment position="end"><IconButton id="upload" onClick={()=>{document.getElementById("upload-file")?.click();}}><FileUploadIcon/></IconButton></InputAdornment>}} />
						<Button size={"large"} variant={"contained"} onClick={onFormSubmitted} id={"submitButton"}>Submit</Button>
						<input type="hidden" value="None" id={"submitResult"} />
						<input type="file" id="upload-file" style={{display:"block", width:"1px", height:"1px", position:"absolute", right:"0px", bottom:"0px"}} 
							onChange={onFileChanged} />
					</Stack>
				</Box>
			</Grid>
		</Grid>
	);	
};

const table = (
	<Grid item xs={12} id={"panel-0"}>
   	<ContactTable />
	</Grid>	
);
const upload = (
	<Grid item xs={12} id={"panel-0"}>
		<WebsiteStatusUploadForm />
	</Grid>	
);

const Contacts = (props:any) => {
	const [tab, setTab] = useState<any>(0);
  return (
		<Grid container id={"contactsPage"}>
		<Grid item xs={12}>
		<Tabs value={tab} onChange={(event:any, t:number)=>{setTab(t);}}>
			<Tab label={"View Contacts"} id={"simple-tab-0"} aria-controls={"panel-0"} />
			<Tab label={"Upload Website Status"} id={"simple-tab-1"} aria-controls={"panel-1"} />
		</Tabs>
		<Box sx={{mt:"15px"}}></Box>
		{tab === 0 ? table : null}
		{tab === 1 ? upload : null}
		</Grid>
		</Grid>
  );
}

export default Contacts;
