import React from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Box,
  Card,
  CardContent,
  TextField,
  Typography
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { NewPasswordScheme } from "../common/schemas";
import { InputWrapper } from "../components/InputWrapper";
import user from "../common/User";

type NewPasswordProps = {
  successCallback: () => void;
};

export function NewPassword({ successCallback }: NewPasswordProps) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(NewPasswordScheme)
  });

  const onSubmit = async ({ password }: any) => {
    try {
      await user.completeNewPassword({ password });
      successCallback();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className=".mui-fixed">
          <Toolbar></Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <img
          style={{
            width: "400px",
            marginBottom: "4rem"
          }}
          src="/assets/piji.svg"
        ></img>
        <Card
          sx={{
            minWidth: "200px",
            maxWidth: "600px",
            minHeight: "200px",
            maxHeight: "600px"
          }}
        >
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  marginBottom: "2rem"
                }}
              >
                <Typography variant="h6">
                  Australian Newsroom Mapping Project
                </Typography>
                <Typography>Enter a new password</Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  rowGap: "1rem",
                  placeItems: "center",
                  maxWidth: "250px",
                  margin: "0 auto"
                }}
              >
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <InputWrapper errorMessage={errors.password?.message}>
                      <TextField
                        label="New Password"
                        type="password"
                        autoComplete="current-password"
                        fullWidth
                        error={!!errors.password?.message}
                        {...field}
                      />
                    </InputWrapper>
                  )}
                />
              </Box>
              <Box
                sx={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Button variant="contained" type="submit">
                  Submit new password
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
