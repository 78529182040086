// vim: ts=2
import React, { useEffect, useState, useCallback, useContext } from "react";
import DataTable from "./../components/DataTable/DataTable";
import { Link } from "react-router-dom"; 
import { ReactWindowTable } from "../components/ReactWindowTable/ReactWindowTable";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Dialogs from "./../common/dialogs";
import { format } from 'date-fns';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Grid
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
import { OutletForm } from "./OutletForm";
import { getOutletChanges, deleteBusiness, getHeaders, getChangesExport } from "../common/apiHelpers";
import { OutletsResponse, SearchContextType, CreateBusinessDialog } from "../common/types";
import { SearchBar } from "../components/SearchBar";
import { SearchContext } from "./../common/searchContext";

const COLS = [
	{ headerName: "State Created", field: "outlet_state_create_date" },
	{ headerName: "Outlet", field: "outlet_name" },
	{ headerName: "State", field: "outlet_state" },
	{ headerName: "Change Type", field: "change_type" },
	{ headerName: "Change Date", field: "change_date" },
	{ headerName: "Show", field: "show" } 
];

const SORT = {
	"Outlet": { sorted: false, mode: "none" },
	"State Created": { sorted: true, mode: "desc" },
	"Change Date": { sorted: false, mode: "none" },
	"Change Type": { sorted: false, mode: "none" }
};

export function OutletChanges() {
	const context: SearchContextType = useContext(SearchContext);
	const [changes, setChanges] = useState<any>(null);
	const [query, setQuery] = useState<string>("");
	const [sort, setSort] = useState<any>(SORT);
	const onDeleteClicked = (event: any, id: number) => {	
	};
	const watchQuery = () => {
		if(query === null || query === undefined || query === null || query === ""){
			return;
		}	
		if(context.previousQuery === null || context.previousQuery === undefined || context.previousQuery !== query){
			doQuery();
			return;
		}
		if(context.previousQuery === query){
			return;
		}
	};
	const loadOutletChanges = () => {
		if(changes !== null){
			return;
		}
		getOutletChanges((data: any)=>{
			const records = data.map((e: any, i: any)=>{
				return {
					id: e.id,
					outlet_name: e.outlet_name,
					outlet_state: e.outlet_state === null || e.outlet_state === undefined ? "NULL" : e.outlet_state.toUpperCase(),
          change_type: e.change_type,
         	change_date: format(new Date(Date.parse(e.change_date)), 'yyyy-MM-dd'),
         	outlet_state_create_date: format(new Date(Date.parse(e.outlet_state_create_date)), 'yyyy-MM-dd'),
					delete: (
        		<Button
							key={e.id}
          		variant="text"
          		onClick={(event)=>{onDeleteClicked(event, e.id);}}
          		startIcon={<CloseIcon />}>
          		DELETE
       			</Button>
					),
					show: (
        		<Button
							key={e.id}
          		variant="text"
          		component={Link}
          		to={`/changes/${e.id}`}
          		startIcon={<AddIcon />}>
          	SHOW
        		</Button>
					) 
				};
			});
			context.records = [...records];
			setChanges(records);
		});
	};
	useEffect(loadOutletChanges);
	useEffect(watchQuery);
	const clearQuery = () => {
		const copy = [...context.records];
		context.previousQuery = "";
		setQuery("");
		setChanges(copy);
	};
	const updateQuery = (event: any) => {
		const q = event.target.value;	
		if(q === ""){
			clearQuery();
			return;
		}
		setQuery(q);
	};
	const doQuery = () => {
		const filter = (e: any) => {
			const lowerCase = query.toLowerCase();
			return e.outlet_name.toLowerCase().indexOf(lowerCase) !== -1;
		};
		const matches = context.records.filter(filter);
		context.previousQuery = query;
		setChanges(matches);
	};
	// wait for changes to load ..et
	if(changes === null){
		return (
			<Grid container>
				<Grid item sm={12}>
					<p>Loading ...</p>
				</Grid>
			</Grid>
		);	
	}
	const onDialogClosed = () => {
	};
	const onCreateBusinessClosed = () => {
	};
	const onBusinessCreated = () => {
	};
	const doExportChanges = () => {
		const s = async (response:any) => {
			const data = await response.text();
			const url = URL.createObjectURL(new Blob([data]));
			const link = document.getElementById("download");
			if(link === null){
				console.log("Failed to find download link ....");
				return;
			}
			if(link instanceof HTMLAnchorElement){
				link.href = url;
				link.click();
			}
		};
		const e = (error:any) => {
				console.log("Failed to fetch ...");
		};
		getChangesExport(s, e);
	};
	const doCreateBusiness = () => {
	};
	const sortData = (sortState:any, headerName: string) => {
		const funs: any = {
			"Outlet": { 
				"asc": ( left: any, right: any ) => { return left.outlet_name.localeCompare(right.outlet_name) }, 
				"desc": ( left: any, right: any ) => { return right.outlet_name.localeCompare(left.outlet_name) } 
			},
			"State Created": {
				"asc": ( left: any, right: any ) => { return Date.parse(left.outlet_state_create_date) - Date.parse(right.outlet_state_create_date) },
				"desc": ( left: any, right: any ) => { return Date.parse(right.outlet_state_create_date) - Date.parse(left.outlet_state_create_date) }
			},
			"Change Date": {
				"asc": ( left: any, right: any ) => { return Date.parse(left.change_date) - Date.parse(right.change_date) },	
				"desc": ( left: any, right: any ) => { return Date.parse(right.change_date)  - Date.parse(left.change_date) }
			},
			"Change Type": {
				"asc": ( left: any, right: any ) => { return left.change_type.localeCompare(right.change_type) }, 
				"desc": ( left: any, right: any ) => { return right.change_type.localeCompare(left.change_type) } 
			}
		};
		const f: any = funs[headerName][sortState.mode];
		context.records.sort(f);	
		setChanges([...context.records]);
	};
	const onSortClicked = (event: any, headerName: string) => {
		const sorted: any = {...sort};
		const sortState = sorted[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			sortData(sortState, headerName);
			setSort(sorted);
			return;
		}
		const mode = sortState.mode === "asc" ? "desc" : "asc";
		sortState.mode = mode;
		sortData(sortState, headerName);
		setSort(sorted);
	};
	const onDeleteConfirmed = () => {
	};
  return (
		<Dialogs onDeleteConfirmed={onDeleteConfirmed}>
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				position="relative"
				marginBottom="45px"
				marginTop="30px"
			>
				<Box position="absolute" left={`calc(50% - 400px)`}>
					<SearchBar
						label="Search outlet changes ..."
						query={query}
						handleClearQuery={clearQuery}
						handleSetQuery={updateQuery}
						doQuery={doQuery}
					/>
					<Button variant="contained" onClick={doExportChanges} sx={{marginTop:"20px", marginLeft:"15px"}} size="large">EXPORT</Button>
				</Box>
			</Box>
			<Box sx={{width:"100%"}}>
				<DataTable rows={changes} columns={COLS} sort={sort} onRowClicked={()=>{}} onHeaderClicked={()=>{}} onSortClicked={onSortClicked} filters={[]}/>
			</Box>
			<a id="download" download={"piji-outlet-changes.csv"} href="" style={{display:"none"}} />
		</Dialogs>
  );
}
