import React, { PropsWithChildren } from "react";
import { Box, Typography } from "@mui/material";

export function InputWrapper({
  errorMessage,
  children
}: PropsWithChildren<{ errorMessage?: string }>) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      {children}
      {errorMessage && (
        <Typography
          variant="caption"
          sx={{
            color: "error.main",
            marginTop: "4px"
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}
