export const lgaChoices = [
  {
    id: "campbelltown city council (NSW)",
    name: "campbelltown city council (NSW)"
  },
  {
    id: "campbelltown city council (SA)",
    name: "campbelltown city council (SA)"
  },
  { id: "bellingen shire council", name: "bellingen shire council" },
  { id: "lithgow city council", name: "lithgow city council" },
  { id: "walgett shire council", name: "walgett shire council" },
  { id: "weddin shire council", name: "weddin shire council" },
  { id: "bourke shire council", name: "bourke shire council" },
  { id: "gilgandra shire council", name: "gilgandra shire council" },
  { id: "muswellbrook shire council", name: "muswellbrook shire council" },
  { id: "narromine shire council", name: "narromine shire council" },
  { id: "berrigan shire council", name: "berrigan shire council" },
  { id: "albury city council", name: "albury city council" },
  { id: "blayney shire council", name: "blayney shire council" },
  { id: "upper lachlan shire council", name: "upper lachlan shire council" },
  { id: "waverley council", name: "waverley council" },
  { id: "lane cove municipal council", name: "lane cove municipal council" },
  { id: "goulburn mulwaree council", name: "goulburn mulwaree council" },
  { id: "woollahra municipal council", name: "woollahra municipal council" },
  { id: "ryde city council", name: "ryde city council" },
  { id: "clarence valley council", name: "clarence valley council" },
  { id: "australian capital territory", name: "australian capital territory" },
  {
    id: "port macquarie-hastings council",
    name: "port macquarie-hastings council"
  },
  { id: "ballina shire council", name: "ballina shire council" },
  { id: "uralla shire council", name: "uralla shire council" },
  { id: "yass valley council", name: "yass valley council" },
  { id: "parkes shire council", name: "parkes shire council" },
  { id: "byron shire council", name: "byron shire council" },
  {
    id: "strathfield municipal council",
    name: "strathfield municipal council"
  },
  { id: "kyogle council", name: "kyogle council" },
  { id: "lockhart shire council", name: "lockhart shire council" },
  { id: "wagga wagga city council", name: "wagga wagga city council" },
  {
    id: "council of the city of sydney",
    name: "council of the city of sydney"
  },
  { id: "narrandera shire council", name: "narrandera shire council" },
  { id: "tamworth regional council", name: "tamworth regional council" },
  { id: "dungog shire council", name: "dungog shire council" },
  { id: "lake macquarie city council", name: "lake macquarie city council" },
  { id: "newcastle city council", name: "newcastle city council" },
  { id: "coolamon shire council", name: "coolamon shire council" },
  {
    id: "liverpool plains shire council",
    name: "liverpool plains shire council"
  },
  {
    id: "the council of the municipality of kiama",
    name: "the council of the municipality of kiama"
  },
  { id: "north sydney council", name: "north sydney council" },
  { id: "carrathool shire council", name: "carrathool shire council" },
  { id: "randwick city council", name: "randwick city council" },
  { id: "penrith city council", name: "penrith city council" },
  {
    id: "central darling shire council",
    name: "central darling shire council"
  },
  { id: "coonamble shire council", name: "coonamble shire council" },
  { id: "port stephens council", name: "port stephens council" },
  { id: "cowra shire council", name: "cowra shire council" },
  { id: "bega valley shire council", name: "bega valley shire council" },
  {
    id: "the council of the municipality of hunters hill",
    name: "the council of the municipality of hunters hill"
  },
  { id: "griffith city council", name: "griffith city council" },
  { id: "broken hill city council", name: "broken hill city council" },
  { id: "mosman municipal council", name: "mosman municipal council" },
  { id: "inverell shire council", name: "inverell shire council" },
  {
    id: "the council of the shire of hornsby",
    name: "the council of the shire of hornsby"
  },
  { id: "bathurst regional council", name: "bathurst regional council" },
  { id: "lismore city council", name: "lismore city council" },
  { id: "wentworth shire council", name: "wentworth shire council" },
  { id: "cabonne shire council", name: "cabonne shire council" },
  { id: "orange city council", name: "orange city council" },
  { id: "hay shire council", name: "hay shire council" },
  { id: "blue mountains city council", name: "blue mountains city council" },
  { id: "wollondilly shire council", name: "wollondilly shire council" },
  { id: "oberon council", name: "oberon council" },
  { id: "maitland city council", name: "maitland city council" },
  { id: "bland shire council", name: "bland shire council" },
  { id: "ku-ring-gai council", name: "ku-ring-gai council" },
  { id: "gwydir shire council", name: "gwydir shire council" },
  { id: "tweed shire council", name: "tweed shire council" },
  { id: "lachlan shire council", name: "lachlan shire council" },
  { id: "camden council", name: "camden council" },
  { id: "forbes shire council", name: "forbes shire council" },
  { id: "richmond valley council", name: "richmond valley council" },
  { id: "burwood council", name: "burwood council" },
  { id: "junee shire council", name: "junee shire council" },
  { id: "the hills shire council", name: "the hills shire council" },
  { id: "walcha council", name: "walcha council" },
  { id: "liverpool city council", name: "liverpool city council" },
  { id: "leeton shire council", name: "leeton shire council" },
  { id: "hawkesbury city council", name: "hawkesbury city council" },
  { id: "balranald shire council", name: "balranald shire council" },
  { id: "tenterfield shire council", name: "tenterfield shire council" },
  { id: "cessnock city council", name: "cessnock city council" },
  { id: "mid-western regional council", name: "mid-western regional council" },
  { id: "city of canada bay council", name: "city of canada bay council" },
  { id: "blacktown city council", name: "blacktown city council" },
  { id: "narrabri shire council", name: "narrabri shire council" },
  { id: "gunnedah shire council", name: "gunnedah shire council" },
  { id: "wingecarribee shire council", name: "wingecarribee shire council" },
  { id: "cobar shire council", name: "cobar shire council" },
  { id: "temora shire council", name: "temora shire council" },
  { id: "bogan shire council", name: "bogan shire council" },
  { id: "willoughby city council", name: "willoughby city council" },
  { id: "fairfield city council", name: "fairfield city council" },
  { id: "moree plains shire council", name: "moree plains shire council" },
  { id: "sutherland shire council", name: "sutherland shire council" },
  { id: "warren shire council", name: "warren shire council" },
  { id: "brewarrina shire council", name: "brewarrina shire council" },
  { id: "eurobodalla shire council", name: "eurobodalla shire council" },
  { id: "shellharbour city council", name: "shellharbour city council" },
  { id: "kempsey shire council", name: "kempsey shire council" },
  { id: "wollongong city council", name: "wollongong city council" },
  { id: "coffs harbour city council", name: "coffs harbour city council" },
  { id: "shoalhaven city council", name: "shoalhaven city council" },
  { id: "city of parramatta council", name: "city of parramatta council" },
  { id: "cumberland council", name: "cumberland council" },
  { id: "murray river council", name: "murray river council" },
  { id: "edward river council", name: "edward river council" },
  { id: "murrumbidgee council", name: "murrumbidgee council" },
  { id: "federation council", name: "federation council" },
  {
    id: "snowy monaro regional council",
    name: "snowy monaro regional council"
  },
  {
    id: "queanbeyan-palerang regional council",
    name: "queanbeyan-palerang regional council"
  },
  { id: "snowy valleys council", name: "snowy valleys council" },
  { id: "armidale regional council", name: "armidale regional council" },
  { id: "georges river council", name: "georges river council" },
  { id: "central coast council (NSW)", name: "central coast council (NSW)" },
  { id: "central coast council (TAS)", name: "central coast council (TAS)" },
  { id: "mid-coast council", name: "mid-coast council" },
  { id: "northern beaches council", name: "northern beaches council" },
  { id: "hilltops council", name: "hilltops council" },
  { id: "inner west council", name: "inner west council" },
  { id: "canterbury-bankstown council", name: "canterbury-bankstown council" },
  { id: "dubbo regional council", name: "dubbo regional council" },
  {
    id: "cootamundra-gundagai regional council",
    name: "cootamundra-gundagai regional council"
  },
  { id: "bayside council", name: "bayside council" },
  { id: "singleton council", name: "singleton council" },
  { id: "nambucca valley council", name: "nambucca valley council" },
  { id: "alice springs municipality", name: "alice springs municipality" },
  { id: "belyuen shire", name: "belyuen shire" },
  { id: "litchfield municipality", name: "litchfield municipality" },
  { id: "coomalie shire", name: "coomalie shire" },
  { id: "katherine municipality", name: "katherine municipality" },
  { id: "palmerston municipality", name: "palmerston municipality" },
  { id: "darwin municipality", name: "darwin municipality" },
  { id: "wagait shire", name: "wagait shire" },
  { id: "central desert region", name: "central desert region" },
  { id: "macdonnell region", name: "macdonnell region" },
  { id: "barkly region", name: "barkly region" },
  { id: "west daly region", name: "west daly region" },
  { id: "victoria daly region", name: "victoria daly region" },
  { id: "west arnhem region", name: "west arnhem region" },
  { id: "roper gulf region", name: "roper gulf region" },
  { id: "east arnhem region", name: "east arnhem region" },
  { id: "tiwi islands region", name: "tiwi islands region" },
  { id: "mornington shire", name: "mornington shire" },
  { id: "mount isa city", name: "mount isa city" },
  { id: "murweh shire", name: "murweh shire" },
  { id: "paroo shire", name: "paroo shire" },
  { id: "quilpie shire", name: "quilpie shire" },
  { id: "richmond shire", name: "richmond shire" },
  { id: "torres shire", name: "torres shire" },
  { id: "townsville city", name: "townsville city" },
  { id: "weipa town", name: "weipa town" },
  { id: "winton shire", name: "winton shire" },
  { id: "boulia shire", name: "boulia shire" },
  { id: "brisbane city", name: "brisbane city" },
  { id: "bulloo shire", name: "bulloo shire" },
  { id: "burdekin shire", name: "burdekin shire" },
  { id: "barcaldine regional", name: "barcaldine regional" },
  { id: "burke shire", name: "burke shire" },
  { id: "bundaberg regional", name: "bundaberg regional" },
  { id: "cairns regional", name: "cairns regional" },
  { id: "cassowary coast regional", name: "cassowary coast regional" },
  { id: "central highlands regional", name: "central highlands regional" },
  { id: "charters towers regional", name: "charters towers regional" },
  { id: "fraser coast regional", name: "fraser coast regional" },
  { id: "gladstone regional", name: "gladstone regional" },
  { id: "goondiwindi regional", name: "goondiwindi regional" },
  { id: "gympie regional", name: "gympie regional" },
  { id: "isaac regional", name: "isaac regional" },
  { id: "lockyer valley regional", name: "lockyer valley regional" },
  { id: "longreach regional", name: "longreach regional" },
  { id: "mackay regional", name: "mackay regional" },
  { id: "moreton bay regional", name: "moreton bay regional" },
  { id: "north burnett regional", name: "north burnett regional" },
  {
    id: "northern peninsula area regional",
    name: "northern peninsula area regional"
  },
  { id: "redland city", name: "redland city" },
  { id: "rockhampton regional", name: "rockhampton regional" },
  { id: "scenic rim regional", name: "scenic rim regional" },
  { id: "somerset regional", name: "somerset regional" },
  { id: "south burnett regional", name: "south burnett regional" },
  { id: "southern downs regional", name: "southern downs regional" },
  { id: "sunshine coast regional", name: "sunshine coast regional" },
  { id: "tablelands regional", name: "tablelands regional" },
  { id: "toowoomba regional", name: "toowoomba regional" },
  {
    id: "torres strait island regional",
    name: "torres strait island regional"
  },
  { id: "whitsunday regional", name: "whitsunday regional" },
  { id: "aurukun shire", name: "aurukun shire" },
  { id: "woorabinda aboriginal shire", name: "woorabinda aboriginal shire" },
  { id: "doomadgee aboriginal shire", name: "doomadgee aboriginal shire" },
  {
    id: "lockhart river aboriginal shire",
    name: "lockhart river aboriginal shire"
  },
  { id: "napranum aboriginal shire", name: "napranum aboriginal shire" },
  { id: "mapoon aboriginal shire", name: "mapoon aboriginal shire" },
  { id: "palm island aboriginal shire", name: "palm island aboriginal shire" },
  { id: "wujal wujal aboriginal shire", name: "wujal wujal aboriginal shire" },
  { id: "kowanyama aboriginal shire", name: "kowanyama aboriginal shire" },
  { id: "pormpuraaw aboriginal shire", name: "pormpuraaw aboriginal shire" },
  { id: "hope vale aboriginal shire", name: "hope vale aboriginal shire" },
  { id: "cherbourg aboriginal shire", name: "cherbourg aboriginal shire" },
  { id: "western downs regional", name: "western downs regional" },
  { id: "maranoa regional", name: "maranoa regional" },
  { id: "yarrabah aboriginal shire", name: "yarrabah aboriginal shire" },
  { id: "douglas shire", name: "douglas shire" },
  { id: "livingstone shire", name: "livingstone shire" },
  { id: "mareeba shire", name: "mareeba shire" },
  { id: "noosa shire", name: "noosa shire" },
  { id: "carpentaria shire", name: "carpentaria shire" },
  { id: "cloncurry shire", name: "cloncurry shire" },
  { id: "cook shire", name: "cook shire" },
  { id: "croydon shire", name: "croydon shire" },
  { id: "diamantina shire", name: "diamantina shire" },
  { id: "balonne shire", name: "balonne shire" },
  { id: "etheridge shire", name: "etheridge shire" },
  { id: "flinders shire", name: "flinders shire" },
  { id: "gold coast city", name: "gold coast city" },
  { id: "hinchinbrook shire", name: "hinchinbrook shire" },
  { id: "banana shire", name: "banana shire" },
  { id: "ipswich city", name: "ipswich city" },
  { id: "logan city", name: "logan city" },
  { id: "barcoo shire", name: "barcoo shire" },
  { id: "mckinlay shire", name: "mckinlay shire" },
  {
    id: "municipal council of roxby downs",
    name: "municipal council of roxby downs"
  },
  { id: "city of tea tree gully", name: "city of tea tree gully" },
  { id: "city of west torrens", name: "city of west torrens" },
  {
    id: "the rural city of murray bridge",
    name: "the rural city of murray bridge"
  },
  { id: "wakefield regional council", name: "wakefield regional council" },
  { id: "city of holdfast bay", name: "city of holdfast bay" },
  { id: "adelaide hills council", name: "adelaide hills council" },
  { id: "alexandrina council", name: "alexandrina council" },
  { id: "the barossa council", name: "the barossa council" },
  { id: "the dc of ceduna", name: "the dc of ceduna" },
  {
    id: "clare and gilbert valleys council",
    name: "clare and gilbert valleys council"
  },
  { id: "the dc of cleve", name: "the dc of cleve" },
  { id: "the dc of coober pedy", name: "the dc of coober pedy" },
  { id: "the dc of franklin harbour", name: "the dc of franklin harbour" },
  {
    id: "the regional council of goyder",
    name: "the regional council of goyder"
  },
  { id: "the dc of grant", name: "the dc of grant" },
  { id: "kingston dc", name: "kingston dc" },
  { id: "light regional council", name: "light regional council" },
  { id: "the dc of loxton waikerie", name: "the dc of loxton waikerie" },
  { id: "the flinders ranges council", name: "the flinders ranges council" },
  {
    id: "the dc of karoonda east murray",
    name: "the dc of karoonda east murray"
  },
  { id: "the dc of kimba", name: "the dc of kimba" },
  { id: "mid murray council", name: "mid murray council" },
  { id: "the dc of mount remarkable", name: "the dc of mount remarkable" },
  { id: "northern areas council", name: "northern areas council" },
  { id: "the dc of orroroo carrieton", name: "the dc of orroroo carrieton" },
  { id: "dc of robe", name: "dc of robe" },
  { id: "city of victor harbor", name: "city of victor harbor" },
  { id: "the dc of streaky bay", name: "the dc of streaky bay" },
  { id: "kangaroo island council", name: "kangaroo island council" },
  {
    id: "the dc of lower eyre peninsula",
    name: "the dc of lower eyre peninsula"
  },
  { id: "renmark paringa council", name: "renmark paringa council" },
  { id: "yorke peninsula council", name: "yorke peninsula council" },
  { id: "dc of elliston", name: "dc of elliston" },
  {
    id: "mount barker district council",
    name: "mount barker district council"
  },
  { id: "adelaide plains council", name: "adelaide plains council" },
  { id: "copper coast council", name: "copper coast council" },
  {
    id: "the corporation of the town of walkerville",
    name: "the corporation of the town of walkerville"
  },
  { id: "barunga west council", name: "barunga west council" },
  { id: "adelaide city council", name: "adelaide city council" },
  { id: "city of burnside", name: "city of burnside" },
  { id: "city of mitcham", name: "city of mitcham" },
  { id: "city of mount gambier", name: "city of mount gambier" },
  {
    id: "city of port adelaide enfield",
    name: "city of port adelaide enfield"
  },
  { id: "port augusta city council", name: "port augusta city council" },
  { id: "city of port lincoln", name: "city of port lincoln" },
  { id: "city of prospect", name: "city of prospect" },
  { id: "city of salisbury", name: "city of salisbury" },
  { id: "city of unley", name: "city of unley" },
  {
    id: "the corporation of the city of whyalla",
    name: "the corporation of the city of whyalla"
  },
  { id: "town of gawler", name: "town of gawler" },
  { id: "the dc of tumby bay", name: "the dc of tumby bay" },
  { id: "wattle range council", name: "wattle range council" },
  { id: "the dc of yankalilla", name: "the dc of yankalilla" },
  { id: "city of charles sturt", name: "city of charles sturt" },
  { id: "city of marion", name: "city of marion" },
  { id: "city of onkaparinga", name: "city of onkaparinga" },
  { id: "the dc of peterborough", name: "the dc of peterborough" },
  { id: "southern mallee dc", name: "southern mallee dc" },
  { id: "tatiara dc", name: "tatiara dc" },
  { id: "wudinna district council", name: "wudinna district council" },
  { id: "city of playford", name: "city of playford" },
  { id: "break o'day council", name: "break o'day council" },
  { id: "brighton council", name: "brighton council" },
  { id: "central highlands council", name: "central highlands council" },
  { id: "circular head council", name: "circular head council" },
  { id: "derwent valley council", name: "derwent valley council" },
  { id: "dorset council", name: "dorset council" },
  { id: "flinders council", name: "flinders council" },
  { id: "george town council", name: "george town council" },
  { id: "huon valley council", name: "huon valley council" },
  { id: "kentish council", name: "kentish council" },
  { id: "king island council", name: "king island council" },
  { id: "kingborough council", name: "kingborough council" },
  { id: "latrobe council", name: "latrobe council" },
  { id: "meander valley council", name: "meander valley council" },
  { id: "northern midlands council", name: "northern midlands council" },
  { id: "sorell council", name: "sorell council" },
  { id: "southern midlands council", name: "southern midlands council" },
  { id: "tasman council", name: "tasman council" },
  { id: "west tamar council", name: "west tamar council" },
  { id: "west coast council", name: "west coast council" },
  { id: "mount alexander shire", name: "mount alexander shire" },
  { id: "strathbogie shire", name: "strathbogie shire" },
  { id: "moonee valley city", name: "moonee valley city" },
  { id: "moreland city", name: "moreland city" },
  { id: "melbourne city", name: "melbourne city" },
  { id: "hindmarsh shire", name: "hindmarsh shire" },
  { id: "horsham rural city", name: "horsham rural city" },
  { id: "northern grampians shire", name: "northern grampians shire" },
  { id: "central goldfields shire", name: "central goldfields shire" },
  { id: "bass coast shire", name: "bass coast shire" },
  { id: "glen eira city", name: "glen eira city" },
  { id: "south gippsland shire", name: "south gippsland shire" },
  { id: "warrnambool city", name: "warrnambool city" },
  { id: "swan hill rural city", name: "swan hill rural city" },
  { id: "bayside city", name: "bayside city" },
  { id: "buloke shire", name: "buloke shire" },
  { id: "loddon shire", name: "loddon shire" },
  { id: "wangaratta rural city", name: "wangaratta rural city" },
  { id: "towong shire", name: "towong shire" },
  { id: "banyule city", name: "banyule city" },
  { id: "brimbank city", name: "brimbank city" },
  { id: "moira shire", name: "moira shire" },
  { id: "indigo shire", name: "indigo shire" },
  { id: "wodonga city", name: "wodonga city" },
  { id: "greater bendigo city", name: "greater bendigo city" },
  { id: "baw baw shire", name: "baw baw shire" },
  { id: "manningham city", name: "manningham city" },
  { id: "whitehorse city", name: "whitehorse city" },
  { id: "whittlesea city", name: "whittlesea city" },
  { id: "hume city", name: "hume city" },
  { id: "benalla rural city", name: "benalla rural city" },
  { id: "yarriambiack shire", name: "yarriambiack shire" },
  { id: "boroondara city", name: "boroondara city" },
  { id: "yarra city", name: "yarra city" },
  { id: "stonnington city", name: "stonnington city" },
  { id: "mornington peninsula shire", name: "mornington peninsula shire" },
  { id: "darebin city", name: "darebin city" },
  { id: "yarra ranges shire", name: "yarra ranges shire" },
  { id: "maroondah city", name: "maroondah city" },
  { id: "port phillip city", name: "port phillip city" },
  { id: "knox city", name: "knox city" },
  { id: "monash city", name: "monash city" },
  { id: "mitchell shire", name: "mitchell shire" },
  { id: "cardinia shire", name: "cardinia shire" },
  { id: "casey city", name: "casey city" },
  { id: "gannawarra shire", name: "gannawarra shire" },
  { id: "greater geelong city", name: "greater geelong city" },
  { id: "nillumbik shire", name: "nillumbik shire" },
  { id: "pyrenees shire", name: "pyrenees shire" },
  { id: "wellington shire", name: "wellington shire" },
  { id: "latrobe city", name: "latrobe city" },
  { id: "ararat rural city", name: "ararat rural city" },
  { id: "wyndham city", name: "wyndham city" },
  { id: "mildura rural city", name: "mildura rural city" },
  { id: "glenelg shire", name: "glenelg shire" },
  { id: "surf coast shire", name: "surf coast shire" },
  { id: "kingston city", name: "kingston city" },
  { id: "frankston city", name: "frankston city" },
  { id: "macedon ranges shire", name: "macedon ranges shire" },
  { id: "moyne shire", name: "moyne shire" },
  { id: "golden plains shire", name: "golden plains shire" },
  { id: "corangamite shire", name: "corangamite shire" },
  { id: "west wimmera shire", name: "west wimmera shire" },
  { id: "southern grampians shire", name: "southern grampians shire" },
  { id: "maribyrnong city", name: "maribyrnong city" },
  { id: "hobsons bay city", name: "hobsons bay city" },
  { id: "moorabool shire", name: "moorabool shire" },
  { id: "melton city", name: "melton city" },
  { id: "queenscliffe borough", name: "queenscliffe borough" },
  { id: "campaspe shire", name: "campaspe shire" },
  { id: "ballarat city", name: "ballarat city" },
  { id: "alpine shire", name: "alpine shire" },
  { id: "east gippsland shire", name: "east gippsland shire" },
  { id: "mansfield shire", name: "mansfield shire" },
  { id: "greater dandenong city", name: "greater dandenong city" },
  { id: "murrindindi shire", name: "murrindindi shire" },
  { id: "greater shepparton city", name: "greater shepparton city" },
  { id: "hepburn shire", name: "hepburn shire" },
  { id: "shire of narembeen", name: "shire of narembeen" },
  { id: "shire of east pilbara", name: "shire of east pilbara" },
  { id: "shire of cuballing", name: "shire of cuballing" },
  { id: "shire of wandering", name: "shire of wandering" },
  { id: "shire of halls creek", name: "shire of halls creek" },
  { id: "shire of donnybrook-balingup", name: "shire of donnybrook-balingup" },
  { id: "shire of york", name: "shire of york" },
  { id: "shire of dalwallinu", name: "shire of dalwallinu" },
  { id: "shire of woodanilling", name: "shire of woodanilling" },
  { id: "shire of ravensthorpe", name: "shire of ravensthorpe" },
  { id: "shire of narrogin", name: "shire of narrogin" },
  { id: "shire of ngaanyatjarraku", name: "shire of ngaanyatjarraku" },
  { id: "shire of laverton", name: "shire of laverton" },
  { id: "shire of goomalling", name: "shire of goomalling" },
  { id: "city of bunbury", name: "city of bunbury" },
  { id: "shire of murchison", name: "shire of murchison" },
  { id: "shire of plantagenet", name: "shire of plantagenet" },
  { id: "shire of dumbleyung", name: "shire of dumbleyung" },
  { id: "shire of quairading", name: "shire of quairading" },
  { id: "city of nedlands", name: "city of nedlands" },
  { id: "city of rockingham", name: "city of rockingham" },
  { id: "city of wanneroo", name: "city of wanneroo" },
  { id: "city of vincent", name: "city of vincent" },
  { id: "city of cockburn", name: "city of cockburn" },
  { id: "city of melville", name: "city of melville" },
  { id: "city of swan", name: "city of swan" },
  { id: "city of bayswater", name: "city of bayswater" },
  {
    id: "shire of serpentine-jarrahdale",
    name: "shire of serpentine-jarrahdale"
  },
  { id: "town of victoria park", name: "town of victoria park" },
  { id: "city of canning", name: "city of canning" },
  { id: "city of south perth", name: "city of south perth" },
  { id: "shire of mundaring", name: "shire of mundaring" },
  { id: "city of gosnells", name: "city of gosnells" },
  { id: "city of armadale", name: "city of armadale" },
  { id: "shire of exmouth", name: "shire of exmouth" },
  { id: "shire of ashburton", name: "shire of ashburton" },
  { id: "shire of carnarvon", name: "shire of carnarvon" },
  { id: "shire of chapman valley", name: "shire of chapman valley" },
  { id: "shire of nannup", name: "shire of nannup" },
  { id: "shire of wongan-ballidu", name: "shire of wongan-ballidu" },
  { id: "shire of victoria plains", name: "shire of victoria plains" },
  { id: "shire of corrigin", name: "shire of corrigin" },
  { id: "shire of coolgardie", name: "shire of coolgardie" },
  { id: "shire of dundas", name: "shire of dundas" },
  { id: "shire of westonia", name: "shire of westonia" },
  { id: "shire of yilgarn", name: "shire of yilgarn" },
  { id: "city of stirling", name: "city of stirling" },
  { id: "shire of wyalkatchem", name: "shire of wyalkatchem" },
  { id: "shire of pingelly", name: "shire of pingelly" },
  { id: "shire of brookton", name: "shire of brookton" },
  { id: "shire of dandaragan", name: "shire of dandaragan" },
  { id: "shire of denmark", name: "shire of denmark" },
  { id: "shire of dardanup", name: "shire of dardanup" },
  { id: "shire of capel", name: "shire of capel" },
  { id: "shire of collie", name: "shire of collie" },
  { id: "city of albany", name: "city of albany" },
  { id: "shire of koorda", name: "shire of koorda" },
  { id: "shire of west arthur", name: "shire of west arthur" },
  { id: "shire of boyup brook", name: "shire of boyup brook" },
  { id: "shire of kojonup", name: "shire of kojonup" },
  { id: "city of mandurah", name: "city of mandurah" },
  { id: "shire of meekatharra", name: "shire of meekatharra" },
  { id: "shire of waroona", name: "shire of waroona" },
  { id: "shire of murray", name: "shire of murray" },
  { id: "shire of mukinbudin", name: "shire of mukinbudin" },
  { id: "shire of trayning", name: "shire of trayning" },
  { id: "shire of nungarin", name: "shire of nungarin" },
  { id: "shire of coorow", name: "shire of coorow" },
  { id: "shire of perenjori", name: "shire of perenjori" },
  { id: "shire of katanning", name: "shire of katanning" },
  { id: "city of belmont", name: "city of belmont" },
  { id: "shire of cranbrook", name: "shire of cranbrook" },
  {
    id: "shire of broomehill-tambellup",
    name: "shire of broomehill-tambellup"
  },
  { id: "shire of harvey", name: "shire of harvey" },
  { id: "city of greater geraldton", name: "city of greater geraldton" },
  { id: "shire of kulin", name: "shire of kulin" },
  { id: "shire of kondinin", name: "shire of kondinin" },
  { id: "town of claremont", name: "town of claremont" },
  { id: "town of east fremantle", name: "town of east fremantle" },
  { id: "town of cambridge", name: "town of cambridge" },
  { id: "city of joondalup", name: "city of joondalup" },
  { id: "city of subiaco", name: "city of subiaco" },
  { id: "shire of peppermint grove", name: "shire of peppermint grove" },
  { id: "town of mosman park", name: "town of mosman park" },
  { id: "town of cottesloe", name: "town of cottesloe" },
  { id: "town of bassendean", name: "town of bassendean" },
  { id: "shire of mingenew", name: "shire of mingenew" },
  { id: "shire of kent", name: "shire of kent" },
  { id: "shire of moora", name: "shire of moora" },
  { id: "shire of merredin", name: "shire of merredin" },
  { id: "shire of menzies", name: "shire of menzies" },
  { id: "shire of morawa", name: "shire of morawa" },
  { id: "shire of shark bay", name: "shire of shark bay" },
  { id: "shire of northam", name: "shire of northam" },
  { id: "shire of carnamah", name: "shire of carnamah" },
  { id: "shire of toodyay", name: "shire of toodyay" },
  { id: "shire of cunderdin", name: "shire of cunderdin" },
  { id: "shire of wiluna", name: "shire of wiluna" },
  { id: "shire of tammin", name: "shire of tammin" },
  { id: "shire of three springs", name: "shire of three springs" },
  { id: "shire of upper gascoyne", name: "shire of upper gascoyne" },
  { id: "shire of mount magnet", name: "shire of mount magnet" },
  { id: "shire of bruce rock", name: "shire of bruce rock" },
  { id: "shire of wickepin", name: "shire of wickepin" },
  { id: "shire of chittering", name: "shire of chittering" },
  { id: "shire of gnowangerup", name: "shire of gnowangerup" },
  { id: "shire of jerramungup", name: "shire of jerramungup" },
  { id: "shire of leonora", name: "shire of leonora" },
  { id: "shire of irwin", name: "shire of irwin" },
  { id: "shire of beverley", name: "shire of beverley" },
  { id: "shire of sandstone", name: "shire of sandstone" },
  { id: "shire of cue", name: "shire of cue" },
  { id: "shire of yalgoo", name: "shire of yalgoo" },
  { id: "shire of mount marshall", name: "shire of mount marshall" },
  { id: "shire of williams", name: "shire of williams" },
  { id: "shire of gingin", name: "shire of gingin" },
  { id: "shire of dowerin", name: "shire of dowerin" },
  { id: "shire of lake grace", name: "shire of lake grace" },
  {
    id: "shire of bridgetown-greenbushes",
    name: "shire of bridgetown-greenbushes"
  },
  { id: "shire of boddington", name: "shire of boddington" },
  { id: "shire of wagin", name: "shire of wagin" },
  { id: "shire of kellerberrin", name: "shire of kellerberrin" },
  {
    id: "shire of derby-west kimberley",
    name: "shire of derby-west kimberley"
  },
  { id: "city of fremantle", name: "city of fremantle" },
  { id: "town of port hedland", name: "town of port hedland" },
  { id: "shire of northampton", name: "shire of northampton" },
  { id: "shire of esperance", name: "shire of esperance" },
  { id: "shire of manjimup", name: "shire of manjimup" },
  {
    id: "shire of wyndham-east kimberley",
    name: "shire of wyndham-east kimberley"
  },
  { id: "shire of broome", name: "shire of broome" },
  { id: "city of perth", name: "city of perth" },
  { id: "city of busselton", name: "city of busselton" },
  { id: "city of kwinana", name: "city of kwinana" },
  { id: "city of karratha", name: "city of karratha" },
  { id: "city of kalamunda", name: "city of kalamunda" },
  { id: "unincorporated", name: "unincorporated" },
  {
    id: "glen innes severn shire council",
    name: "glen innes severn shire council"
  },
  { id: "warrumbungle shire council", name: "warrumbungle shire council" },
  { id: "unincorporated far west", name: "unincorporated far west" },
  { id: "upper hunter shire council", name: "upper hunter shire council" },
  { id: "greater hume shire council", name: "greater hume shire council" },
  {
    id: "un-incorporated (alyangula) area",
    name: "un-incorporated (alyangula) area"
  },
  {
    id: "darwin waterfront precinct municipality",
    name: "darwin waterfront precinct municipality"
  },
  {
    id: "un-incorporated (elrundie) area",
    name: "un-incorporated (elrundie) area"
  },
  {
    id: "un-incorporated (yulara) area",
    name: "un-incorporated (yulara) area"
  },
  {
    id: "un-incorporated (nhulunbuy) area",
    name: "un-incorporated (nhulunbuy) area"
  },
  {
    id: "un-incorporated (marrakai-dougla area",
    name: "un-incorporated (marrakai-dougla area"
  },
  {
    id: "un-incorporated (cox-daly) area",
    name: "un-incorporated (cox-daly) area"
  },
  {
    id: "northern territory rates act area",
    name: "northern territory rates act area"
  },
  { id: "shire of christmas island", name: "shire of christmas island" },
  { id: "shire of cocos islands", name: "shire of cocos islands" },
  { id: "blackall tambo regional", name: "blackall tambo regional" },
  { id: "naracoorte lucindale council", name: "naracoorte lucindale council" },
  { id: "the berri barmera council", name: "the berri barmera council" },
  {
    id: "anangu pitjantjatjara yankunytjatjara",
    name: "anangu pitjantjatjara yankunytjatjara"
  },
  { id: "pastoral unincorporated area", name: "pastoral unincorporated area" },
  { id: "maralinga tjarutja", name: "maralinga tjarutja" },
  { id: "coorong district council", name: "coorong district council" },
  { id: "uia torrens island", name: "uia torrens island" },
  { id: "uia riverland", name: "uia riverland" },
  { id: "uia whyalla", name: "uia whyalla" },
  {
    id: "the city of norwood payneham and st peters",
    name: "the city of norwood payneham and st peters"
  },
  { id: "port pirie regional council", name: "port pirie regional council" },
  { id: "clarence city council", name: "clarence city council" },
  { id: "glamorgan-spring bay council", name: "glamorgan-spring bay council" },
  { id: "waratah-wynyard council", name: "waratah-wynyard council" },
  { id: "burnie city council", name: "burnie city council" },
  { id: "devonport city council", name: "devonport city council" },
  { id: "hobart city council", name: "hobart city council" },
  { id: "launceston city council", name: "launceston city council" },
  { id: "glenorchy city council", name: "glenorchy city council" },
  { id: "gabo island (unincorporated)", name: "gabo island (unincorporated)" },
  {
    id: "lake mountain alpine resort (unincorporated)",
    name: "lake mountain alpine resort (unincorporated)"
  },
  { id: "colac otway shire", name: "colac otway shire" },
  {
    id: "mount stirling alpine resort (unincorporated)",
    name: "mount stirling alpine resort (unincorporated)"
  },
  {
    id: "falls creek alpine resort (unincorporated)",
    name: "falls creek alpine resort (unincorporated)"
  },
  {
    id: "mount buller alpine resort (unincorporated)",
    name: "mount buller alpine resort (unincorporated)"
  },
  {
    id: "mount hotham alpine resort (unincorporated)",
    name: "mount hotham alpine resort (unincorporated)"
  },
  {
    id: "mount baw baw alpine resort (unincorporated)",
    name: "mount baw baw alpine resort (unincorporated)"
  },
  {
    id: "french-elizabeth-sandstone islands (uninc)",
    name: "french-elizabeth-sandstone islands (uninc)"
  },
  { id: "city of kalgoorlie-boulder", name: "city of kalgoorlie-boulder" },
  {
    id: "shire of augusta margaret river",
    name: "shire of augusta margaret river"
  }
];
