import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import { OutletState, PublicationFormatEnum, ScaleEnum } from "../common/types";
import { Link } from "react-router-dom";

export function OutletStateTable({
  outletStates,
  deleteRowHandler,
  editOnClickHandler,
  outletId
}: {
  outletStates: OutletState[];
  deleteRowHandler: (options: {
    open: boolean;
    id: string | number;
    name: string;
  }) => void;
  editOnClickHandler: (outletState: OutletState) => void;
  outletId: string | number;
}) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="Outlet state table">
        <TableHead>
          <TableRow>
            <TableCell>Outlet name</TableCell>
            <TableCell>Current at</TableCell>
            <TableCell>Deprecated at</TableCell>
            <TableCell>Scale</TableCell>
            <TableCell>Primary format</TableCell>
            <TableCell>Publication type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {outletStates.map((outlet) => (
            <TableRow
              key={outlet.id}
              hover
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{outlet.name}</TableCell>
              <TableCell>{outlet.current_at}</TableCell>
              <TableCell>
                {!outlet.deprecated_at ? "--/--/--" : outlet.deprecated_at}
              </TableCell>
              <TableCell>{outlet.scale && ScaleEnum[outlet.scale]}</TableCell>
              <TableCell>{outlet.primary_format}</TableCell>
              <TableCell>
                {Array.isArray(outlet.publication_formats)
                  ? outlet.publication_formats
                      .map((format) => PublicationFormatEnum[format])
                      .join(", ")
                  : outlet.publication_formats &&
                    PublicationFormatEnum[outlet.publication_formats]}
              </TableCell>
              <TableCell>
                <Box
                  component="span"
                  sx={{
                    color: outlet.status === "deprecated" ? "inherit" : "green"
                  }}
                >
                  {outlet.status}
                </Box>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={() => {
                    deleteRowHandler({
                      open: true,
                      id: outlet.id,
                      name: outlet.name
                    });
                  }}
                  startIcon={<CloseIcon />}
                >
                  DELETE
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={() => {
                    editOnClickHandler(outlet);
                  }}
                  startIcon={<CreateIcon />}
                >
                  EDIT
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  startIcon={<AddIcon />}
                  component={Link}
                  to={`/outlets/${outletId}/show/${outlet.id}`}
                >
                  SHOW
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
