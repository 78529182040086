import React from "react";
import { Controller } from "react-hook-form";
import {
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Chip,
  Box,
  Select
} from "@mui/material";
import { InputWrapper } from "./InputWrapper";

type ControlledSelectProps = {
  name: string;
  label: string;
  control: any;
  choices: { id: string; name: string }[];
  errorMessage?: string;
  disabled?: boolean;
};

export function ControlledSelect({
  name,
  label,
  control,
  choices,
  errorMessage,
  disabled = false,
}: ControlledSelectProps) {
  return (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      render={({ field }) => (
        <InputWrapper errorMessage={errorMessage}>
          <FormControl error={!!errorMessage} fullWidth disabled={disabled}>
            <InputLabel id={field.name}>{label}</InputLabel>
            <Select
              labelId={field.name}
              input={<OutlinedInput id={field.name} label={field.name} />}
              {...field}
            >
              {choices.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputWrapper>
      )}
    />
  );
}

export function ControlledMultipleSelect({
  name,
  label,
  control,
  choices,
  errorMessage,
  disabled = false
}: ControlledSelectProps) {
  return (
    <Controller
      name={name}
      defaultValue={[]}
      control={control}
      render={({ field }) => (
        <InputWrapper errorMessage={errorMessage}>
          <FormControl error={!!errorMessage} fullWidth disabled={disabled}>
            <InputLabel id={field.name}>{label}</InputLabel>
            <Select
              {...field}
              multiple
              labelId={field.name}
              input={<OutlinedInput id={field.name} label={field.name} />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value: any) => {
                    return (
                      <Chip
                        key={value}
                        label={
                          choices.find((choice) => choice.id === value)?.name
                        }
                      />
                    );
                  })}
                </Box>
              )}
            >
              {choices.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputWrapper>
      )}
    />
  );
}
