import React, { useEffect, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, TextField, Stack, Button, Chip, Typography } from "@mui/material";
import { getBusinessEntities, linkBusinessesToOutlet } from "./../common/apiHelpers";
import { BusinessEntity, BusinessEntityOutlet, OutletSearchContextType } from "./../common/types";
import { OutletSearchContext } from "./../common/outletSearchContext";
import { Dropdown } from "./../components/fields";

const LinkBusinessForm  = (props: any) => {
	const outletId = props.outletId;
	const [businesses, setBusinesses] = useState<any[]|null>(null);
	const [businessIds, setBusinessIds] = useState<number[]>([]);
	const [currentId, setCurrentId] = useState<number>(-1);
	const [query, setQuery] = useState<string>("");
	const outletSearch: OutletSearchContextType = useContext<OutletSearchContextType>(OutletSearchContext);
	// events
	const onSubmitClicked = () => {
		const payload = {
			business_entity_ids: businessIds	
		};
		const s = (response: any) => {
			props.onBusinessLinked();
		};
		linkBusinessesToOutlet(outletId, payload, s);
	};
	// effect handlers
	// load outlets
	// correlate to dropdown options
	const loadBusinesses = () => {
		if(businesses !== null){
			return;
		}
		const s = (businesses: any[]) => {
			let options = businesses.map((e:any)=>{return {value: e.id, text: e.entity_name}});
			options = [{value:-1, text:"Please select an option ..."}, ...options];
			outletSearch.records = options;
			setBusinesses(options);
		};
		getBusinessEntities(s);
	};	
	// effects
	useEffect(loadBusinesses);
	// event handlers	
	const applySearch = () => { 
		const matches: any[] = outletSearch.records.filter((x)=>{ return x.text.toLowerCase().indexOf(query) !== -1});
		if(matches.length > 0){
			const first = matches[0];
			setCurrentId(first.value);	
		}	
		setBusinesses(matches);
	};
	const unlinkBusiness = (id: number) => {
		const copy = [...businessIds];
		const i = copy.findIndex((x)=>{ return x === id});
		if(i === undefined){
			throw new Error(`Failed to find outlet for id ${id}`);
		}
		copy.splice(i, 1);	
		setBusinessIds(copy);
	};
	const linkBusiness = (event: any) => {
		const copy = [...businessIds];
		copy.push(currentId);
		setBusinessIds(copy);
		setCurrentId(-1);
	};
	if(businesses === null){
		return (
			<p>Loading ...</p>
		);	
	}
	let linkedBusinesses = (
		<Box sx={{textAlign:"center"}}>
			<Typography>Select businesses from then following dropdown and click the plus button to link them.</Typography>
		</Box>
	);
	if(businessIds.length > 0){
		linkedBusinesses = (
			<Box>
				{businessIds.map((e:any,i:any)=>{ return <Chip key={e} label={businesses.find((x)=>{ return x.value === e}).text} color="primary" onDelete={()=>{unlinkBusiness(e);}} />})}
			</Box>
		);
	}
	return (
		<Grid container>
			<Grid item sm={12} sx={{padding:"10px"}}>
				<Stack spacing={3}>
					<Box sx={{border:"1px solid #CDCDCD", padding:"15px"}}>
						{linkedBusinesses}
					</Box>
					<Box sx={{border:"1px solid #CDCDCD", padding:"15px"}}>
						<Grid container>
							<Grid item sm={12} sx={{marginBottom:"15px"}}>
								<TextField value={query} onBlur={applySearch} onChange={(event)=>{setQuery(event.target.value);}} helperText={"Search for a business by entering its name ..."} label={"Search Businesses"} fullWidth />
							</Grid>
							<Grid item sm={10}>
								<Dropdown 
									options={businesses} 
									onValueChanged={(event: any)=>{setCurrentId(event.target.value);}} 
									label="Business" 
									value={currentId} 
									helperText={"Select a business to link to this outlet."} />
							</Grid>
							<Grid item sm={2}>
								<Button variant="contained" onClick={linkBusiness} sx={{marginLeft:"10px", verticalAlign:"middle"}}><AddIcon/></Button>
							</Grid>
						</Grid>
					</Box>
					<Button variant={"contained"} fullWidth onClick={onSubmitClicked} size={"large"}>Submit</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default LinkBusinessForm;
