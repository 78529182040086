// vim: ts=2
import React, { useContext, useEffect, useState, useCallback } from "react";
import DataTable from "./../components/DataTable/DataTable";
import { 
	getChangeRequests, doApproveChange, 
	doRejectChange, getOrganisation, 
	getBusinessEntity, getOutletStateAsync, 
	getBusinessEntityAsync 
} from "./../common/apiHelpers";
import { UserContext } from "./../common/userContext";
import { SearchContext } from "./../common/searchContext";
import { UserContextType, SearchContextType } from "./../common/types";
import { Info, Visibility, Check, Clear } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  IconButton,
  ListItemText,
	Paper,
	List,
	ListItem,
  Menu,
  MenuItem,
  Typography,
	Tooltip,
	Stack,
  Grid
} from "@mui/material";
const COLS = [
	{ headerName: "ID", field: "id" },
	{ headerName: "Submitted By", field: "submitted_by" },
	{ headerName: "Entity Type", field: "entity_type" },
	{ headerName: "Change Type", field: "change_type" },
	{ headerName: "Entity ID", field: "entity_id" },
	{ headerName: "Actions", field: "actions" }
];
const SORT = {
	"ID": { sorted: true, mode: "asc" },
};
export const ChangeRequestPreview = (props: any) => {
	// properties and non state variables
	const change: any = props.change;
	const data: any = change.payload_data;
	// state
	const [entity, setEntity] = useState<any|null>(null);
	// effects
	const loadEntity = () => {
		if(change.change_type.toUpperCase() === "INSERT"){
			return;
		}
		if(entity !== null){
			return;
		}
		const s = (response: any) => {
			// retired outlet state payload needs to be handled differently from
			// oher change types
			if(response === "RetiredOutletState"){
				setEntity("RetiredOutletState");
				return;
			}
			setEntity(response);
		};
		const e = (response: any) => {
		};
		// determine how to get the entity
		// could be organisation, business, outlet ...
		const mapping: any = {
			"ORGANISATION": (x: any, y: any) => { getOrganisation(`${change.entity_id}`, 
				async (response: any)=>{
					const org = await response.json();
					delete org["outlets"];
					delete org["created_at"];
					delete org["updated_at"];
					org["show_on_visualisations"] = normalizeBoolean(org, "show_on_visualisations");
					x(org);
				}, y); },
			"OUTLETSTATE": (x: any, y: any) => { 
				getOutletStateAsync(`${change.entity_id}`, `${change.sub_entity_id}`, 
					async (response: any) => {
						const os = await response.json(); 
						const converted =	convertOutletStatePayload(os.outlet_state);
						converted["publication_formats"] = normalizeArray(os.outlet_state, "publication_formats");
						converted["publication_schedule"] = normalizeArray(os.outlet_state, "publication_schedule");
						x(converted); 
					}, 
				y); },
			"RETIREDOUTLETSTATE": (x: any, y: any) => { x("RetiredOutletState"); },
			"BUSINESS": (x: any, y: any) => { getBusinessEntity(`${change.entity_id}`, (entity: any) => {const normalized = convertBusinessPayload(entity); x(normalized); } ); },
		};
		mapping[change.entity_type.toUpperCase()](s, e);
	};
	// effects
	useEffect(loadEntity);
	const convertOutletStatePayload = (x: any) => {
		const y = {...x};
		const keys = Object.keys(y);
		delete y["current_at"];
		delete y["deprecated_at"];
		delete y["business_structure"];
		y["id"] = change.sub_entity_id;
		y["scale"] = y["scale"]["_value_"];
		y["primary_format"] = y["primary_format"]["_value_"];
		if(keys.indexOf("publication_formats") !== -1 && y["publication_formats"] !== null){
			y["publication_formats"] = y["publication_formats"].map((e:any, i:any)=>{ return e["_value_"]; }).join(", ");
		}
		if(keys.indexOf("publication_schedule") !== -1 && y["publication_schedule"] !== null){
			y["publication_schedule"] = y["publication_schedule"].map((e:any, i:any)=>{ return e["_value_"]; }).join(", ");
		}
		if(keys.indexOf("state") !== -1 && y["state"] !== null){
			y["state"] = y["state"]["_value_"];
		}
		if(keys.indexOf("broadcast_license_subservice") !== -1 && y["broadcast_license_subservice"] !== null){
			y["broadcast_license_subservice"] = y["broadcast_license_subservice"]["_value_"];
		}
		y["broadcast_areas"] = normalizeString(y, "broadcast_areas");
		y["business_entities"] = normalizeString(y, "business_entities");
		y["broadcast_license_subservice"] = normalizeString(y, "broadcast_license_subservice");
		y["callsign"] = normalizeString(y, "callsign");
		y["change_category"] = normalizeString(y, "change_category");
		y["change_type"] = normalizeString(y, "change_type");
		y["is_broadcast"] = normalizeBoolean(y, "is_broadcast");
		y["lga_coverage"]  = normalizeString(y, "lga_coverage");
		y["name"]  = normalizeString(y, "name");
		y["primary_coverage"]  = normalizeString(y, "primary_coverage");
		y["primary_format"]  = normalizeString(y, "primary_format");
		y["scale"]  = normalizeString(y, "scale");
		y["state"]  = normalizeString(y, "state");
		y["status"]  = normalizeString(y, "status");
		return y;
	};
	const normalizeBoolean = (x: any, field: string) => {
		return x[field] === null || x[field] === undefined ? "false" : "true";
	};
	const convertRetiredOutletStatePayload = (x: any) => {
		const y = {...x};		
		delete y["deprecated_at"];
		return y;
	};
	const convertBusinessPayload = (x: any) => {
		const y = {...x};
		y["id"] = change.entity_id;
		delete y["created_at"];
		y["business_names"] = y["business_names"] === null || y["business_names"] === undefined ? "None" : y["business_names"].join(", ");
		y["child_entities"] = y["child_entities"] === null || y["child_entities"] === undefined  || y["child_entities"].length === 0 ? "None" : y["child_entities"].join(", ");
		y["entity_owned_by_entity_ids"] = y["entity_owned_by_entity_ids"] === null || y["entity_owned_by_entity_ids"] === undefined || y["entity_owned_by_entity_ids"] ? "None" : y["entity_owned_by_entity_ids"].join(", ");
		y["entity_owns_entity_ids"] = y["entity_owns_entity_ids"] === null || y["entity_owns_entity_ids"] === undefined || y["entity_owns_entity_ids"].length === 0 ? "None" : y["entity_owns_entity_ids"].join(", ");
		y["outlets"] = y["outlets"] === null || y["outlets"] === undefined || y["outlets"].length === 0 ? "None" : y["outlets"].join(", ");
		y["parent_entities"] = y["parent_entities"] === null || y["parent_entities"] === undefined || y["parent_entities"].length === 0 ? "None" : y["parent_entities"].join(", ");
		y["trading_names"] = y["trading_names"] === null || y["trading_names"] === undefined || y["trading_names"].length === 0 ? "None" : y["trading_names"].join(", ");
		return y;
	};
	const normalizeArray = (data: any, field: string) => {
		return data[field] === null || data[field] === undefined || data[field].length === 0 ? "None" : data[field].join(", ");
	};
	const normalizeString = (data: any, field: string) => {
		return data[field] === null || data[field] === undefined ? "None" : data[field];
	};
	// events and helper functions
	const sanitise = (data: any, entityType: any) => {
		const mapping: any = {
			"ORGANISATION": (x: any) => { const y = {...x}; y["org_type"] = y["org_type"]["_value_"]; y["org_id"] = change.entity_id; y["show_on_visualisations"] = normalizeBoolean(y, "show_on_visualisations"); return y; },
			"BUSINESS": (x: any) => { return convertBusinessPayload(x); },
			"OUTLETSTATE": (x: any) => { return convertOutletStatePayload(x); },
			"OUTLET": (x: any) => { return convertOutletStatePayload(x); },
			"RETIREDOUTLETSTATE": (x: any) => { return convertRetiredOutletStatePayload(x); }
		};	
		return mapping[entityType.toUpperCase()](data);
	};
	// main body
	if((entity === null && change.change_type === "UPDATE")||(entity === null && change.change_type === "DELETE")){
		return (
			<Box sx={{margin:"auto"}}>
				<Typography>Loading ...</Typography>
			</Box>
		);
	}
	// entity is not null
	// needs to be represented on the frontend
	// differently for all types ...
	let payload: any | null = null;
	let proposed: any = (
		<Box sx={{margin: "auto", textAlign:"center", padding:"15px"}}>
			<span>
				<Info sx={{verticalAlign:"middle", mr:"5px"}}/>
				<span style={{verticalAlign:"middle"}}>This change doesn't have any payload associated with it.</span>
			</span>
		</Box>
	);
	let current = <p>Current data here ...</p>;
	if(change.change_type === "UPDATE"){
		payload = JSON.parse(data);
		payload = sanitise(payload, change.entity_type);
		const et = change.entity_type.toUpperCase();
		let keys: any = [];
		if(et === "RETIREDOUTLETSTATE"){
			keys = Object.keys(payload);
		}else{
			keys = Object.keys(entity);
			payload["lga_coverage"] = normalizeArray(payload, "lga_coverage");
		}
		keys = keys.sort();
		const proposedItems = keys.map((e: any, i: number)=>{return <ListItem sx={{backgroundColor: payload[e] !== entity[e] ? "yellow": "inherit"}} key={`payload_data_${e}`}><ListItemText secondary={e} primary={payload[e]}/></ListItem>});
		let currentItems = [];
		if(et !== "RETIREDOUTLETSTATE"){
			currentItems = keys.map((e: any, i: number)=>{return <ListItem key={`current_data_${e}`}><ListItemText secondary={e} primary={entity[e]}/></ListItem>});
			current = <List>{currentItems}</List>;
		}else{
			// retiring an outlet state 
			// need to show current state ...
			current = (
				<Box sx={{border:"1px solid #CDCDCD", borderRadius:"5px", backgroundColor:"#EFEFEF", padding:"25px", textAlign:"center", margin:"auto"}}>
					<span>
						<Info sx={{mr:"15px", height:"30px", width:"auto", verticalAlign:"middle"}}/>
						<Typography component={"span"} variant={"body1"} sx={{verticalAlign:"middle"}}>
							Outlet is currently active
						</Typography>
					</span>
				</Box>
			);
		}
		proposed = <List>{proposedItems}</List>;
	}else if(change.change_type === "INSERT"){
		payload = JSON.parse(data);
		payload = sanitise(payload, change.entity_type);
		let keys: any = Object.keys(payload);
		const proposedItems = keys.map((e: any, i: number)=>{return <ListItem sx={{backgroundColor: "yellow" }} key={`payload_data_${e}`}><ListItemText secondary={e} primary={payload[e]}/></ListItem>});
		proposed = <List>{proposedItems}</List>;
		current = (
			<Box sx={{border:"1px solid #CDCDCD", borderRadius:"5px", backgroundColor:"#EFEFEF", padding:"25px", textAlign:"center", margin:"auto"}}>
				<span>
					<Info sx={{mr:"15px", height:"30px", width:"auto", verticalAlign:"middle"}}/>
					<Typography component={"span"} variant={"body1"} sx={{verticalAlign:"middle"}}>
						This entity doesn't exist yet.
					</Typography>
				</span>
			</Box>
		);
	}else if(change.change_type === "DELETE"){
		let keys: any = Object.keys(entity);
		const currentItems = keys.map((e: any, i: number)=>{return <ListItem sx={{backgroundColor: "inherit" }} key={`payload_data_${e}`}><ListItemText secondary={e} primary={entity[e]}/></ListItem>});
		current = <List>{currentItems}</List>;
	}
	return (
		<Stack spacing={2} alignItems={"center"}>
			<Typography variant={"h4"}>Review Change Request</Typography>
			<Grid container>
				<Grid item xs={12}>
					<Paper elevation={3}>
						<Grid container>
							<Grid item xs={6} sx={{borderRight:"1px dashed black", padding:"15px"}}>
								<Typography variant={"h5"} sx={{textAlign:"center"}}>Current</Typography>
								{current}
							</Grid>
							<Grid item xs={6} sx={{borderLeft:"1px dashed black", padding:"15px"}}>
								<Typography variant={"h5"} sx={{textAlign:"center"}}>Proposed</Typography>
								{proposed}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Grid container>	
						<Grid item lg={6} xs={12}>
							<Button color={"success"} size={"large"} fullWidth onClick={props.onApprove}>Approve <Check sx={{ml:"10px"}}/></Button>
						</Grid>
						<Grid item lg={6} xs={12}>
							<Button color={"error"} size={"large"} fullWidth onClick={props.onReject}>Reject <Clear sx={{ml:"10px"}}/></Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Stack>
	);
};
export const ChangeRequestPage = (props: any) => {
	// context
	const userContext: UserContextType = useContext(UserContext);
	const tableContext: SearchContextType = useContext(SearchContext);
	const { enqueueSnackbar } = useSnackbar();
	// state
	const [changes, setChanges] = useState<any|null>(null);
	const [sort, setSort] = useState<any>(SORT);
	const [dialog, setDialog] = useState<any>(null);
	// event handling
	const rejectChange = (id: number) => {
		const s = (response: any) => {
			if(!response.ok){
				enqueueSnackbar("Failed to reject change.", {variant: "error"});
				return;
			}
			enqueueSnackbar("Change rejected", {variant: "success"});
			setChanges(null);
		};
		const e =  () => {	
				enqueueSnackbar("Failed to reject change, caught exception.", {variant: "error"});
		};
		doRejectChange(id, s, e);
	};	
	const approveChange = (id: number) => {
		const s = () => {
			enqueueSnackbar("Change approved", {variant: "success"});
			setChanges(null);
		};
		doApproveChange(id, s);
	};
	const reviewChange = (change: any) => {
		const dialog = (
			<Dialog open={true} onClose={()=>{setDialog(null);}} maxWidth={"lg"} fullWidth>
				<DialogContent>
					<ChangeRequestPreview change={change} onApprove={()=>{approveChange(change.id);}} onReject={()=>{rejectChange(change.id);}} />
				</DialogContent>
			</Dialog>
		);
		setDialog(dialog);
	};
	// effects
	const loadChangeRequests = () => {
		if(changes !== null){
			return;
		}
		const s = (data: any) => {
			const queue: any = data.changes.map((e:any,i:number)=>{return {...e, "actions": <span><Tooltip title={"Review change"}><IconButton onClick={()=>{reviewChange(e);}}><Visibility sx={{color:"black"}}/></IconButton></Tooltip><Tooltip title={"Approve"}><IconButton onClick={()=>{approveChange(e.id);}}><Check sx={{color:"green"}}/></IconButton></Tooltip><Tooltip title={"Reject"}><IconButton onClick={()=>{rejectChange(e.id);}}><Clear sx={{color:"red"}}/></IconButton></Tooltip></span>}});
			tableContext.records = queue;
			setChanges(queue);
		};
		getChangeRequests(s);
	};
	const sortData = (sortState:any, headerName: string) => {
		const funs: any = {
			"ID": { "asc": (left:any, right:any) => { return left.id - right.id}, "desc": (left:any, right:any) => { return right.id - left.id } },
		};
		const f: any = funs[headerName][sortState.mode];
		tableContext.records.sort(f);	
		setChanges([...tableContext.records]);
	};
	const onSortClicked = (event: any, headerName: string) => {
		const sorted: any = {...sort};
		const sortState = sorted[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			sortData(sortState, headerName);
			setSort(sorted);
			return;
		}
		const mode = sortState.mode === "asc" ? "desc" : "asc";
		sortState.mode = mode;
		sortData(sortState, headerName);
		setSort(sorted);
	};
	useEffect(loadChangeRequests);
	if(changes === null){
		return (
			<Grid container>
				<Grid item sm={12}>
					<p>Loading ...</p>
				</Grid>
			</Grid>
		);	
	}
	let content = (
		<Box sx={{textAlign:"center", border:"1px solid #CDCDCD", padding:"25px", backgroundColor:"#EFEFEF", borderRadius:"5px"}}>
			<span><Info sx={{verticalAlign:"middle", mr:"15px", height:"30px", width:"auto"}}/><Typography variant={"body1"} component={"span"} sx={{verticalAlign:"middle"}}>There aren't any changes present in the queue, hooray!</Typography></span>
		</Box>
	);
	if(changes.length > 0){
		content = (
			<DataTable rows={changes} columns={COLS} sort={sort} onRowClicked={()=>{}} onHeaderClicked={()=>{}} onSortClicked={onSortClicked} filters={[]}/>
		);
	}
	return (
		<Box sx={{width:"100%"}}>
			{dialog}
			<Stack spacing={2}>
				<Typography variant={"h4"}>Pending Change Requests</Typography>
				<Typography variant={"body1"}>{`${changes.length} change(s) waiting to be reviewed.`}</Typography>
				<Box>
					<Stack spacing={2} direction={"row"}>
						<Button variant={"contained"} color={"primary"} onClick={()=>{setChanges(null);}}>Refresh</Button>
					</Stack>
				</Box>
				{content}
			</Stack>
		</Box>
	);
}
