// vim: ts=2
import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Autocomplete,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  CircularProgress
} from "@mui/material";
import { UserContext } from "../common/userContext";
import { UserContextType } from "./../common/types";
import { MobileDatePicker } from "@mui/lab";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatISO } from "date-fns";
import {
  ControlledSelect,
  ControlledMultipleSelect
} from "../components/ControlledSelect";
import { lgaChoices } from "../sources/lga";
import {
  businessStructureChoices,
  primaryFormatChoices,
  publicationFormatsChoices,
  publicationScheduleChoices,
  scaleChoices,
  metroChoices,
  stateChoices,
  broadcastLicenseSubserviceChoices	
} from "../common/constants";
import { createContact, createOutlet, getChangeTypeChoices } from "../common/apiHelpers";
import { OutletSchema } from "../common/schemas";
import { InputWrapper } from "../components/InputWrapper";
import { minDate } from "../common/formatDate";
import { useScaleWatch } from "./form-hooks/useScaleWatch";
import { useShowFields } from "./form-hooks/useShowFields";
import { useSnackbar } from "notistack";
import { useLgaCoverageError } from "./form-hooks/useLgaCoverageError";

type OutletFormProps = {
  closeDialog: () => void
};

export function OutletForm({ closeDialog }: OutletFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const context: UserContextType  = useContext(UserContext); 
  const [ changeTypeChoices, setChangeTypeChoices ] = useState<any|null>(null);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(OutletSchema),
    defaultValues: {
      current_at: new Date(),
      display: true
    }
  });

  const lgaCoverageError = useLgaCoverageError(watch);

  const { mutate: mutateContact, isLoading: isLoadingContact } = useMutation(
    createContact,
    {
      onSuccess: () => {
        closeDialog();
      }
    }
  );
  const {
    mutate: mutateOutlet,
    isLoading: isLoadingOutlet,
    isError: isErrorOutlet,
    isSuccess: isSuccessOutlet,
    data
  } = useMutation(createOutlet);
  const [mediaType, setMediaType] = useState("alternative");
  const {
    showMetro,
    showState,
    showLgaCoverage,
    showBroadcastArea,
    showCallsign,
		showBroadcastLicenseSubservice
  } = useShowFields(watch, mediaType);
	
	const getSubService = (format: string, scale: string) => {
		// logic for determining subservice from format and scale
		// commercial radio
		if(format === "radio" && scale === "local"){
			return "commercial_radio";
		}
		// commercial television
		if(format === "television" && scale === "local"){
			return "commercial_television";
		}
		// community radio
		if(format === "radio" && scale === "community"){
			return  "community_broadcasting";
		}
		return null;
	};

	useEffect(()=>{
		const values = getValues();
		const subservice = values.broadcast_license_subservice;
		const scale = values.scale;
		const format = values.primary_format;
		if(format === undefined || format === null)
			return;
		if(scale === undefined || scale === null)
			return;
		const subserviceEmpty = subservice === undefined || subservice === null || subservice === "";
		const desiredValue = getSubService(format, scale);
		if(desiredValue === undefined || desiredValue === null){
			setValue("broadcast_license_subservice", null);
			return;
		}
		if(subserviceEmpty){
			setValue("broadcast_license_subservice", desiredValue);
		}
	});

  useEffect(()=>{
		if(changeTypeChoices !== null){
			return;
		}	
		const s = async (response:any) => {
			let choices = await response.json();
			choices = choices.filter((x:any)=>{return x["category"].toLowerCase() === "expansion"});
			setChangeTypeChoices(choices);
		};
		const e = (error:any) => {
			console.log("Failed to get change type choices ...");
		};
		getChangeTypeChoices(s, e);
  });

  const onSubmit = (event: any) => {
    // remove contact as we can't make that call until this is called
    const { contact, ...rest } = event;
    let outletEdited = rest;
    // 1. if scale !== metro, metro_area = null, otherwise take value
    if (event.scale !== "metro") {
      outletEdited = { ...outletEdited, metro_area: null };
    }
    // 2. if scale === national, state = null, otherwise take value
    if (event.scale === "national") {
      outletEdited = { ...outletEdited, state: null };
    }
    // 3. if scale !== local, secondary_coverage = null
    if (event.scale !== "local") {
      outletEdited = {
        ...outletEdited,
        secondary_coverage: null
      };
    }
    // 5. clean up business structure because it defaults to empty string if not included
    if (!event.business_structure) {
      outletEdited = {
        ...outletEdited,
        business_structure: null
      };
    }
    // 6. if media type is not broadcast then remove a potential broadcast id
    if (mediaType === "alternative") {
      outletEdited = {
        ...outletEdited,
        broadcast_area: null
      };
    }
    outletEdited = {
      ...outletEdited,
      current_at: formatISO(outletEdited.current_at)
    };
    mutateOutlet(outletEdited);
  };

  useEffect(() => {
    if (
      !isLoadingOutlet &&
      !isErrorOutlet &&
      isSuccessOutlet &&
      data
    ) {
			// dont lodge another change if we're an editor
			// let the backend automatically create a contact when a new outlet is created
			if(context.role !== "ADMINISTRATOR"){
				closeDialog();
				return;
			}
      mutateContact({ data: getValues("contact"), id: data.outlet.id });
    }
  }, [isLoadingOutlet, isErrorOutlet, data, getValues, mutateContact]);

  const scaleWatch = watch("scale");
  const { scaleCleanup } = useScaleWatch();
  useEffect(() => {
    scaleCleanup(scaleWatch, mediaType === "broadcast", setValue);
  }, [scaleWatch, mediaType]);

  const handleMediaTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMediaType(e.target?.value);
  };

  useEffect(() => {
    if (isErrorOutlet) {
      enqueueSnackbar("Unable to create outlet", { variant: "error" });
    }
  }, [isErrorOutlet]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "grid",
        gap: "16px"
      }}
    >
      <Typography variant="h6">Outlet information</Typography>
      <FormControl>
        <FormLabel>Outlet type</FormLabel>
        <RadioGroup
          row
          value={mediaType}
          onChange={(e) => handleMediaTypeChange(e)}
        >
          <FormControlLabel
            value="alternative"
            control={<Radio />}
            label="Non-broadcast media"
          />
          <FormControlLabel
            value="broadcast"
            control={<Radio />}
            label="Broadcast media"
          />
        </RadioGroup>
      </FormControl>
	{ /* dynamically get change type choices ... */ }
	{changeTypeChoices !== null ? 
          <ControlledSelect
            name="change_type_id"
            label="Change type"
            control={control}
            choices={changeTypeChoices}
            errorMessage={errors.change_type_id?.message}
          /> : <Box sx={{padding:"15px", border:"1px solid #CDCDCD", borderRadius:"5px"}}><Typography sx={{textAlign:"center"}}>Loading change type options ...<CircularProgress sx={{verticalAlign:"middle", marginLeft:"10px"}}/></Typography></Box>}

          <Controller
            name="change_description"
            control={control}
            render={({ field }) => (
          		<InputWrapper errorMessage={errors.change_description?.message}>
                <TextField
            			error={!!errors.change_description?.message}
                  label="Change description"
                  {...field}
                />
							</InputWrapper>
            )}
          />
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.name?.message}>
            <TextField
              label="Outlet name"
              error={!!errors.name?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="current_at"
        control={control}
        render={({ field }) => (
          <MobileDatePicker
            {...field}
            label="Current at"
            renderInput={(params) => <TextField {...params} />}
            minDate={minDate}
          />
        )}
      />
      <ControlledSelect
        name="scale"
        label="Scale"
        control={control}
        choices={scaleChoices}
        errorMessage={errors.scale?.message}
      />
      <ControlledSelect
        name="primary_format"
        label="Primary format"
        control={control}
        choices={primaryFormatChoices}
        errorMessage={errors.primary_format?.message}
      />
      <ControlledMultipleSelect
        name="publication_formats"
        label="Publication formats"
        control={control}
        choices={publicationFormatsChoices}
        errorMessage={
          errors?.publication_formats && errors.publication_formats[0]?.message
        }
      />
      <ControlledMultipleSelect
        name="publication_schedule"
        label="Publication Schedule"
        control={control}
        choices={publicationScheduleChoices}
      />
      <Controller
        name="latitude"
        control={control}
        render={({ field }) => (
        	<TextField
          	label="Latitude"
            {...field}
          />
        )}
      />
      <Controller
        name="longitude"
        control={control}
        render={({ field }) => (
        	<TextField
          	label="Longitude"
            {...field}
          />
        )}
      />
			{showCallsign && (
      <Controller
        name="callsign"
        control={control}
        render={({ field }) => (
        	<TextField
          	label="Callsign"
            {...field}
          />
        )}
      />)
			}
			{showBroadcastLicenseSubservice && (
      <ControlledSelect
        name="broadcast_license_subservice"
        label="License Subservice"
        control={control}
        choices={broadcastLicenseSubserviceChoices}
        errorMessage={errors.broadcast_license_subservice?.message}
      />)
			}
      <Controller
        name="primary_coverage"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.primary_coverage?.message}>
            <TextField
              label="Primary coverage"
              error={!!errors.primary_coverage?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      {showMetro && (
        <ControlledSelect
          name="metro_area"
          label="Metro area"
          control={control}
          choices={metroChoices}
          errorMessage={errors.metro_area?.message}
          disabled={scaleWatch !== "metro"}
        />
      )}
      {showState && (
        <ControlledSelect
          name="state"
          label="State or territory"
          control={control}
          choices={stateChoices}
          errorMessage={errors.state?.message}
          disabled={scaleWatch === "national"}
        />
      )}
      {showLgaCoverage && (
        <Controller
          name="lga_coverage"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <Autocomplete
              {...field}
              value={
                field?.value &&
                Array.isArray(field?.value) &&
                field?.value?.length
                  ? lgaChoices.filter((lga) => field?.value?.includes(lga.id))
                  : []
              }
              onChange={(_event, newValue) => {
                field.onChange(newValue.map((value) => value?.id));
              }}
              disabled={scaleWatch !== "local" && scaleWatch !== "community" }
              multiple
              id="lga_coverage-auto"
              options={lgaChoices}
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="LGA coverage"
                  helperText={
                    lgaCoverageError
                      ? "You must select at least one LGA"
                      : "Start typing for suggestions"
                  }
                  error={lgaCoverageError}
                />
              )}
            />
          )}
        />
      )}
      <Typography variant="h6">Visualisation</Typography>
      <Controller
        name="display"
        control={control}
        render={({ field }) => (
          <Box style={{ justifySelf: "start" }}>
            <FormControlLabel
              label="Display on visualisations?"
              labelPlacement="start"
              control={<Switch {...field} checked={field.value} />}
            />
          </Box>
        )}
      />
      <Typography variant="h6">Contact information</Typography>
      <Controller
        name="contact.contact_name"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors?.contact?.contact_name?.message}>
            <TextField
              label="Contact name"
              error={!!errors?.contact?.contact_name?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="contact.website"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.contact?.website?.message}>
            <TextField
              label="Website"
              error={!!errors.contact?.website?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="contact.email"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors?.contact?.email?.message}>
            <TextField
              label="E-mail"
              error={!!errors?.contact?.email?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="contact.phone_number"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors?.contact?.phone_number?.message}>
            <TextField
              label="Phone"
              error={!!errors.contact?.phone_number?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="contact.address"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors?.contact?.address?.message}>
            <TextField
              label="Address"
              error={!!errors?.contact?.address?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{
            marginRight: "16px"
          }}
          variant="outlined"
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoadingContact || isLoadingOutlet}
        >
          Create
        </Button>
      </Box>
    </form>
  );
}
