// vim: ts=2
import React, { useContext, useEffect, useState, useCallback } from "react";
import DataTable from "./../components/DataTable/DataTable";
import { Link } from "react-router-dom";
import Dialogs from "./../common/dialogs";
import {
  Box,
	Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
	IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid,
	Tabs,
	Tab
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { OutletForm } from "./OutletForm";
import { getCodesOfPractice } from "../common/apiHelpers";
import { SearchContext } from "./../common/searchContext";
import { SearchBar } from "../components/SearchBar";

const COLS = [
	{ headerName: "Name", field: "cop_name" },
	{ headerName: "URL", field: "cop_url" },
	{ headerName: "Actions", field: "actions" },
];

const SORT = {
	"Name": { sorted: false, mode: "none" },
};

const CodesOfPracticePage = (props:any) => {
	const [codes, setCodes] = useState<null|any>(null);
	const getActions = (e:any) => {
		return <span><IconButton component={Link} to={`/code-of-practice/${e.id}`}><Visibility/></IconButton></span>;
	};
	useEffect(()=>{
		if(codes !== null){
			return;
		}	
		const s = async (response:any) => {	
			const data = await response.json();
			const mapped = data.map((e:any, i:number)=>{ return {...e, "actions": getActions(e)}});
			setCodes(mapped);
		};
		const e = (error:any) => {
			console.log("Failed to load codes of practice ...");
		};
		getCodesOfPractice(s, e);
	});
	if(codes === null){
		return <p>Loading codes ...</p>;
	}
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant={"h4"}>Codes of Practice</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{width:"100%"}}>
					<DataTable rows={codes} columns={COLS} onRowClicked={()=>{}} sort={SORT} onHeaderClicked={()=>{}} onSortClicked={()=>{}} filters={[]} />
				</Box>
			</Grid>
		</Grid>
	);
};


export default CodesOfPracticePage;
