// vim: ts=2
import React, { useState, useEffect } from "react";

import {	
	Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
	Button,
	Typography,
	Stack
} from "@mui/material";

import LinkOutletForm from "./../components/LinkOutletForm";
import { DeleteDialogType, UpdateBusinessDialog, CreateBusinessDialog, LinkOutletDialog } from "./types";
import UpdateBusinessForm from "./../components/UpdateBusinessForm";
import LinkBusinessForm from "./../components/LinkBusinessForm";
import LinkBroadcastAreaForm from "./../components/LinkBroadcastAreaForm";
import LinkOrganisationForm from "./../components/LinkOrganisationForm";
import CreateOrganisationForm from "./../components/CreateOrganisationForm";
import LinkCodeOfPracticeForm from "./../components/LinkCodeOfPracticeForm";

const Dialogs = (props: any) => {

	const [deleteDialog, setDeleteDialog] = useState<DeleteDialogType|null|undefined>(props.deleteDialog); 
	const [updateBusinessDialog, setUpdateBusinessDialog] = useState<UpdateBusinessDialog|null|undefined>(props.updateBusinessDialog);
	const [createBusinessDialog, setCreateBusinessDialog] = useState<CreateBusinessDialog|null|undefined>(props.createBusinessDialog);
	const [linkOutletDialog, setLinkOutletDialog] = useState<LinkOutletDialog|null|undefined>(props.linkOutletDialog);
	const [linkBusinessDialog, setLinkBusinessDialog] = useState<any|null|undefined>(props.linkBusinessDialog);
	const [linkBroadcastAreaDialog, setLinkBroadcastAreaDialog] = useState<any|null|undefined>(props.linkBroadcastAreaDialog);
	const [linkOrganisationDialog, setLinkOrganisationDialog] = useState<any|null|undefined>(props.linkOrganisationDialog);
	const [linkCodeOfPracticeDialog, setLinkCodeOfPracticeDialog] = useState<any|null|undefined>(props.linkCodeOfPracticeDialog);
  const [createOrganisation, setCreateOrganisation] = useState<null|any>(props.createOrganisation);
  const [editOrganisation, setEditOrganisation] = useState<null|any>(props.editOrganisation);
  const [confirmation, setConfirmation] = useState<null|any>(props.confirmation);

	// watches
  const watchConfirmation = () => {
		if(props.confirmation !== confirmation){
      setConfirmation(props.confirmation);
	    return;
    }
	};

	const watchEditOrganisation = () => {
		if(props.editOrganisation !== editOrganisation){
			setEditOrganisation(props.editOrganisation);
			return;
		}
	};

  const watchCreateOrganisation = () => {
		if(props.createOrganisation !== createOrganisation){
			setCreateOrganisation(props.createOrganisation);
			return;
		}
	};

	const watchOrganisationDialog  = () => {
		if(props.linkOrganisationDialog !== linkOrganisationDialog){
			setLinkOrganisationDialog(props.linkOrganisationDialog);
			return;
		}
	};

	const watchUpdateBusinessDialog = () => {
		if(props.updateBusinessDialog !== updateBusinessDialog){
			setUpdateBusinessDialog(props.updateBusinessDialog);
			return;
		}
	};

	const watchCreateBusinessDialog = () => {
		if(props.createBusinessDialog !== createBusinessDialog){
			setCreateBusinessDialog(props.createBusinessDialog);
			return;
		}
	};

	const watchDeleteDialog = () => { 
		if(props.deleteDialog !== deleteDialog){
			setDeleteDialog(props.deleteDialog);
			return;
		}
	};

	const watchLinkOutletDialog = () => {
		if(props.linkOutletDialog !== linkOutletDialog){
			setLinkOutletDialog(props.linkOutletDialog);
			return;
		}	
	};

	const watchLinkBusinessDialog = () => {
		if(props.linkBusinessDialog !== linkBusinessDialog){
			setLinkBusinessDialog(props.linkBusinessDialog);
			return;
		}	
	};

	const watchLinkBroadcastAreaDialog = () => {
		if(props.linkBroadcastAreaDialog !== linkBroadcastAreaDialog){
			setLinkBroadcastAreaDialog(props.linkBroadcastAreaDialog);
			return;
		}
	};

	const watchLinkCodeOfPracticeDialog = () => {
		if(props.linkCodeOfPracticeDialog !== linkCodeOfPracticeDialog){
			setLinkCodeOfPracticeDialog(props.linkCodeOfPracticeDialog);
			return;
		}
	};

	// effects
	useEffect(watchDeleteDialog);
	useEffect(watchUpdateBusinessDialog);
	useEffect(watchCreateBusinessDialog);
	useEffect(watchLinkOutletDialog);
	useEffect(watchLinkBusinessDialog);
	useEffect(watchLinkBroadcastAreaDialog);
	useEffect(watchOrganisationDialog);
  useEffect(watchCreateOrganisation);
  useEffect(watchEditOrganisation);
  useEffect(watchConfirmation);
	useEffect(watchLinkCodeOfPracticeDialog);

	// different dialogs
	let editOrganisationDialog = null;
	if(editOrganisation !== null && editOrganisation !== undefined){
		editOrganisationDialog = (
			<Dialog 
				open={editOrganisation.open} 
				onClose={()=>{props.onEditOrganisationClosed();}} 
				maxWidth="sm" 
				fullWidth>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Edit Organisation</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>Update this organisation record</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Box sx={{padding:"15px"}}>	
						<CreateOrganisationForm onOrganisationUpdated={props.onOrganisationUpdated} edit={true} entityId={editOrganisation.entityId} />
					</Box>
				</DialogContent>
			</Dialog>
		);
	}
	
	let linkCodeOfPractice = null;
	if(linkCodeOfPracticeDialog !== null && linkCodeOfPracticeDialog){
		linkCodeOfPractice = (
			<Dialog
				open={linkCodeOfPracticeDialog.open}
				onClose={()=>{props.onLinkCodeOfPracticeClosed();}}
				maxWidth={"sm"}
				fullWidth>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Link Code of Practice</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>Link one or many codes of practice to this outlet.</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Box sx={{padding:"15px"}}>
						<LinkCodeOfPracticeForm onCodeOfPracticeLinked={props.onCodeOfPracticeLinked} entityId={linkCodeOfPracticeDialog.entityId} />
					</Box>
				</DialogContent>
			</Dialog>
		);
	}

  // confirmation dialog 
  let confirmationDialog = null;
  if(confirmation !== undefined && confirmation !== null){
		confirmationDialog = (
			<Dialog 
				open={confirmation.open} 
				onClose={()=>{props.onConfirmationClosed();}} 
				maxWidth="md" 
				fullWidth>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Please Confirm</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>{confirmation.message}</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Box sx={{padding:"15px", textAlign:"center"}}>	
						<Button size="large" variant={"contained"} sx={{marginRight:"30px"}} onClick={props.onConfirmationAccepted}>Yes</Button>
						<Button size="large" color={"error"} variant={"contained"} onClick={props.onConfirmationCancelled}>No</Button>
					</Box>
				</DialogContent>
			</Dialog>
		);
	}
 
	// create organisation
	let createOrganisationDialog = null;
  if(createOrganisation !== null && createOrganisation !== undefined){
		createOrganisationDialog = (
			<Dialog 
				open={createOrganisation.open} 
				onClose={()=>{props.onCreateOrganisationClosed();}} 
				maxWidth="sm" 
				fullWidth>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Create Organisation</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>Create a new organisation record.</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Box sx={{padding:"15px"}}>	
						<CreateOrganisationForm onOrganisationCreated={props.onOrganisationCreated} edit={false} />
					</Box>
				</DialogContent>
			</Dialog>
		);
	}

	// link outlet to business ...
	let linkOutletDialogView = null;
	if(linkOutletDialog !== null && linkOutletDialog !== undefined){
		linkOutletDialogView = (
			<Dialog 
				open={linkOutletDialog.open} 
				onClose={()=>{props.onLinkOutletClosed();}} 
				maxWidth="sm" 
				fullWidth
				>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Link Outlet</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>Link one or more outlets to this business entity.</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Box sx={{padding:"15px"}}>	
						<LinkOutletForm entityId={linkOutletDialog.entityId} onOutletLinked={props.onOutletLinked}/>
					</Box>
				</DialogContent>
			</Dialog>	
		);
	}

	// link business to outlet
	let linkBusinessDialogView = null;
	if(linkBusinessDialog !== null && linkBusinessDialog !== undefined){
		linkBusinessDialogView = (
			<Dialog 
				open={linkBusinessDialog.open} 
				onClose={props.onLinkBusinessClosed} 
				maxWidth="sm" 
				fullWidth
				>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Link Business</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>Link one or more businesses to this outlet.</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<LinkBusinessForm outletId={linkBusinessDialog.entityId} onBusinessLinked={props.onBusinessLinked}/>
				</DialogContent>
			</Dialog>	
		);
	}
	// link organisation to outlet 
  // or link organisation to business
	let linkOrganisationView = null;
	if(linkOrganisationDialog !== null && linkOrganisationDialog !== undefined){
		const entity = linkOrganisationDialog.entityType;
		const isOutlet = entity.toUpperCase() === "OUTLET";
    const message = isOutlet ? "Link one or more organisations to an outlet." : "Link one or more organisations to a business.";
		linkOrganisationView = (
			<Dialog 
				open={linkOrganisationDialog.open} 
				onClose={props.onLinkOrganisationClosed} 
				maxWidth="sm" 
				fullWidth
				>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Link Organisation</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>{message}</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<LinkOrganisationForm outletId={linkOrganisationDialog.entityId} onOrganisationLinked={props.onOrganisationLinked} entityType={linkOrganisationDialog.entityType}/>
				</DialogContent>
			</Dialog>	
		);
	}
	// link broadcast area to outlet
	// broadcast areas symbolize a region of australia where certain TV stations
	// radio stations are allowed to broadcast
	let linkBroadcastAreaDialogView= null;
	if(linkBroadcastAreaDialog !== null && linkBroadcastAreaDialog !== undefined){
		linkBroadcastAreaDialogView = (
			<Dialog 
				open={linkBroadcastAreaDialog.open} 
				onClose={props.onLinkBroadcastAreaClosed} 
				maxWidth="sm" 
				fullWidth
				>
				<DialogTitle>
					<Stack spacing={2}>
						<Typography variant="h4" sx={{textAlign:"center"}}>Link Broadcast Area</Typography>
						<Typography variant="body1" sx={{textAlign:"center"}}>Link one or more broadcast areas to an outlet.</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<LinkBroadcastAreaForm outletId={linkBroadcastAreaDialog.entityId} onBroadcastAreaLinked={props.onBroadcastAreaLinked}/>
				</DialogContent>
			</Dialog>	
		);
	}
	return (
		<Box sx={{height:"100%"}}>
			{linkOutletDialogView}
			{linkBusinessDialogView}
			{linkBroadcastAreaDialogView}
			{linkOrganisationView}
			{createOrganisationDialog}
			{editOrganisationDialog}
			{confirmationDialog}
			{linkCodeOfPractice}
			<Dialog
				open={createBusinessDialog !== undefined && createBusinessDialog !== null && createBusinessDialog.open}
				onClose={()=>{props.onCreateBusinessClosed();}}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle>
					<Typography variant="h4" sx={{textAlign:"center"}}>Create Business Entity</Typography>
				</DialogTitle>
				<DialogContent>
					<Box sx={{padding:"15px"}}>
						<UpdateBusinessForm
							edit={false}
							onBusinessCreated={props.onBusinessEntityCreated}
							onBusinessUpdated={props.onBusinessEntityUpdated}
							entities={createBusinessDialog !== undefined && createBusinessDialog !== null ? createBusinessDialog.entities : null} />
					</Box>
				</DialogContent>
			</Dialog>
			<Dialog
				open={updateBusinessDialog !== undefined && updateBusinessDialog !== null && updateBusinessDialog.open}
				onClose={()=>{
					props.onUpdateBusinessClosed();
				}}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle>
					<Stack spacing={1}>
						<Typography style={{textAlign:"center"}} variant="h4">Update Business Entity</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Box sx={{padding:"15px"}}>
						<UpdateBusinessForm
							edit={true}
							entityId={updateBusinessDialog !== undefined && updateBusinessDialog !== null ? updateBusinessDialog.id : null} 
							entities={updateBusinessDialog !== undefined && updateBusinessDialog !== null ? updateBusinessDialog.entities : null}
							onBusinessUpdated={props.onBusinessUpdated} />
					</Box>
				</DialogContent>
			</Dialog>
      <Dialog
        open={deleteDialog !== undefined && deleteDialog !== null && deleteDialog.open}
        onClose={() => {
					props.onDialogClosed();
        }}
        maxWidth="sm"
        fullWidth
      >
       	<DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <Typography>This cannot be undone.</Typography>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
								props.onDialogClosed();
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={props.onDeleteConfirmed}>
              Yes
            </Button>
          </DialogActions>
				</DialogContent>
			</Dialog>
			<Box sx={{height:"100%"}}>
				{props.children}
			</Box>
		</Box>
	);
};
export default Dialogs;
