import React from "react";
import { TableRow, TableCell } from "@mui/material";

import { ROW_SIZE } from "./ReactWindowTable";

/**
 * Renders the headers row based on the columns provided.
 */
const TableColumns = ({ columns }: { columns: Array<any> }) => (
  <TableRow
    component="div"
    sx={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      minWidth: "100%",
      width: "100%",
      justifyContent: "center"
    }}
  >
    {columns.map((column, colIndex) => {
      return (
        <TableCell
          key={colIndex}
          component="div"
          variant="head"
          style={{
            height: ROW_SIZE,
            display: "block",
            flexGrow: 0,
            flex: 1,
            flexShrink: 0
          }}
          scope="col"
        >
          {column.label}
        </TableCell>
      );
    })}
  </TableRow>
);

export default TableColumns;
