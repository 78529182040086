import * as yup from "yup";

export const LoginSchema = yup.object({
  username: yup.string().email().required(),
  password: yup.string().min(8).required()
});

export const NewPasswordScheme = yup.object({
  password: yup.string().min(8).required()
});

export const ContactSchema = yup.object({
  contact_name: yup.string().nullable(),
  email: yup.string().email("Provided value is not a valid email").nullable(),
  phone_number: yup.string().nullable(),
  address: yup.string().nullable(),
  website: yup.string().nullable(),
  facebook_url: yup.string().nullable(),
  twitter_url: yup.string().nullable(),
  instagram_url: yup.string().nullable(),
  linkedin_url: yup.string().nullable(),
  tiktok_url: yup.string().nullable(),
  youtube_url: yup.string().nullable(),
  news_archive_url: yup.string().nullable()
});

export const OutletSchema = yup.object({
  name: yup.string().required("Outlet name is required"),
  current_at: yup.date().required("Current at is required"),
  primary_format: yup.string().required("Primary format is required"),
  publication_formats: yup.array().of(yup.string()),
  business: yup.string(),
  business_structure: yup.string(),
  callsign: yup.string(),
  longitude: yup.number(),
  latitude: yup.number(),
  broadcast_license_subservice: yup.string().nullable(),
  state: yup.string().when("scale", {
    is: (scale: string) => scale === "national",
    then: yup.string(),
    otherwise: yup.string().required("State is required")
  }),
  metro_area: yup.string().when("scale", {
    is: (scale: string) => scale === "metro",
    then: yup.string().required("Metro area is required when scale is metro")
  }),
  scale: yup.string().required("Scale is required"),
  location: yup.string(),
  primary_coverage: yup.string(),
  broadcast_area: yup.string(),
  lga_coverage: yup.array().of(yup.string()),
  display: yup.bool().required(),
  contact: ContactSchema,
  change_description: yup.string().required("Change description is required."),
  change_type_id: yup.string().required("Change type is required when creating an outlet")
});

/** Pretty ugly but each field is optional when isOutletInOperation is 'no' */
export const OutletStateEditSchema = yup.object({
  name: yup.string().required("Outlet name is required"),
  current_at: yup.date().required("Current at is required"),
  primary_format: yup.string().required("Primary format is required"),
  publication_formats: yup.array().of(yup.string()),
  business: yup.string(),
  callsign: yup.string(),
  broadcast_license_subservice: yup.string(),
  business_structure: yup.string(),
  state: yup.string().when(["scale"], {
    is: (scale: string) => scale === "national",
    then: yup.string(),
    otherwise: yup.string().required("State is required")
  }),
  scale: yup.string().required(),
  metro_area: yup.string().when("scale", {
    is: (scale: string) => scale === "metro",
    then: yup.string().required("Metro area is required when scale is metro")
  }),
  location: yup.string(),
  primary_coverage: yup.string(),
  broadcast_area: yup.string(),
  lga_coverage: yup.array().of(yup.string()),
  display: yup.bool().required()
});

export const OutletStateUpdateSchema = (canRetire: boolean) =>
  yup.object({
    isOutletInOperation: yup.string().required(),
    name: yup.string().when("isOutletInOperation", {
      is: (value: string) => value === "yes",
      then: yup.string().required("Outlet name is required")
    }),
    current_at: yup.date().when("isOutletInOperation", {
      is: (isOutletInOperation: string) => isOutletInOperation === "yes",
      then: yup
        .date()
        .required("Current at is required")
        .when(
          "deprecated_at",
          (deprecated: Date, yup: any) =>
            deprecated &&
            yup.min(
              deprecated,
              "Created date cannot be earlier than previous deprecated date"
            )
        )
    }),
    primary_format: yup.string().when("isOutletInOperation", {
      is: (value: string) => value === "yes",
      then: yup.string().required("Primary format is required")
    }),
    publication_formats: yup.array().of(yup.string()),
    business: yup.string(),
    business_structure: yup.string(),
    state: yup.string().when(["isOutletInOperation", "scale"], {
      is: (isOutletInOperation: string, scale: string) =>
        scale === "national" || isOutletInOperation === "no",
      then: yup.string(),
      otherwise: yup.string().required("State is required")
    }),
    scale: yup.string().when("isOutletInOperation", {
      is: (value: string) => value === "yes",
      then: yup.string().required("Scale is required")
    }),
    metro_area: yup.string().when(["isOutletInOperation", "scale"], {
      is: (isOutletInOperation: string, scale: string) =>
        isOutletInOperation === "yes" && scale === "metro",
      then: yup.string().required("Metro area is required when scale is metro")
    }),
    location: yup.string(),
    primary_coverage: yup.string(),
    broadcast_area: yup.string(),
    lga_coverage: yup.array().of(yup.string()),
    deprecated_at: canRetire ? yup.date().required() : yup.date(),
    change_description: yup.string().required("Change description is required."),
    change_type_id: canRetire
      ? yup.string().required("Change type is required when retiring an outlet")
      : yup.string(),
    display: yup.bool().required()
  });
