export type PublicationFormat =
  | "newspaper"
  | "newsletter"
  | "magazine"
  | "radio"
  | "television"
  | "website"
  | "digital_print"
  | "livestream"
  | "catchup_service"
  | "podcast"
  | "social_media"
  | "dedicated_app"
  | "other";

export type PublicationSchedule = 
  | "daily_monday_to_sunday"
  | "daily_monday_to_saturday"
  | "daily_monday_to_friday"
  | "four_per_week"
  | "three_per_week"
  | "two_per_week"
  | "weekly"
  | "every_three_weeks"
  | "fortnightly"
  | "monthly"
  | "every_two_months"
  | "quarterly"
  | "infrequent"
  | "other";

export type BusinessEntityType = 
  "other" | 
  "co-operative" | 
  "hybrid trust" |
  "other unincorporated entity" |
  "local government entity" |
  "state government entity" |
  "discretionary investment trust" |
  "australian public company" |
  "commonwealth government entity" |
  "individual/sole trader" |
  "discretionary trading trust" |
  "family partnership" |
  "australian private company" |
  "other partnership" |
  "fixed unit trust" |
  "other trust" | 
  "other incorporated entity";

export const PublicationScheduleEnum = {
  "daily_monday_to_sunday": "Daily (M-Su)",
  "daily_monday_to_saturday": "Daily (M-Sa)",
  "daily_monday_to_friday": "Daily (M-F)",
  "four_per_week": "4x week",
  "three_per_week": "3x week",
  "two_per_week": "2x week",
  "weekly": "Weekly",
  "every_three_weeks":"Every three weeks",
  "fortnightly": "Fortnightly",
  "monthly": "Monthly",
  "every_two_months": "Every two months",
  "quarterly": "Quarterly",
  "infrequent": "Infrequent",
  "other": "Other"
};

export const PublicationFormatEnum = {
  newspaper: "newspaper",
  newsletter: "newsletter",
  magazine: "magazine",
  radio: "radio",
  television: "television",
  website: "website",
  digital_print: "digital print",
  livestream: "livestream",
  catchup_service: "catchup service",
  podcast: "podcast",
  social_media: "social media",
  dedicated_app: "dedicated app",
  other: "other"
};

export type PrimaryFormat = "print" | "radio" | "television" | "digital";

export type BusinessStructure =
  | "private_or_public_company"
  | "public_broadcaster"
  | "not_for_profit_or_charity"
  | "sole_trader"
  | "other"
  | "unincorporated"
  | "undetermined";

export const BusinessStructureEnum = {
  private_or_public_company: "private or public company",
  public_broadcaster: "public broadcaster",
  not_for_profit_or_charity: "not for profit or charity",
  sole_trader: "sole trader",
  other: "other",
  unincorporated: "unincorporated",
  undetermined: "undetermined"
};

export type Scale =
  | "national"
  | "state_territory"
  | "local"
  | "not_applicable"
  | "metro"
  | "community";

export type BroadcastLicenseSubservice =  
    | "commercial_radio"
    | "commercial_radio_section_40_non_bsb"
    | "commercial_television"
    | "community_broadcasting"
    | "international_broadcasting"
    | "national_broadcasting"
    | "subscription_television_broadcasting"
    | "temporary_community_broadcasting";

export const ScaleEnum = {
  national: "national",
  state_territory: "state or territory",
  local: "local",
  not_applicable: "not applicable",
  metro: "metro",
  community: "community"
};

export type ChangeType =
  | "service_decrease"
  | "service_increase"
  | "masthead_or_station_closure"
  | "newsroom_closure"
  | "end_of_print_edition"
  | "merger"
  | "new_masthead_station_website"
  | "new_print_edition"
  | "newsroom_opened";

export const ChangeTypeEnum = {
  service_decrease: "service decrease",
  service_increase: "service increase",
  masthead_or_station_closure: "masthead or station closure",
  newsroom_closure: "newsroom closure",
  end_of_print_edition: "end of print edition",
  merger: "merger",
  new_masthead_station_website: "new masthead, station or website",
  new_print_edition: "new print edition",
  newsroom_opened: "newsroom opened"
};

export type ChangeCategory = "contraction" | "expansion";

export type MetroArea =
  | "australian_capital_territory"
  | "greater_adelaide"
  | "greater_brisbane"
  | "greater_darwin"
  | "greater_hobart"
  | "greater_melbourne"
  | "greater_perth"
  | "greater_sydney";

export type OutletState = {
  id: string | number;
  current_at: string;
  deprecated_at: string;
  name: string;
  status: Status;
  primary_format: PrimaryFormat;
  publication_formats: PublicationFormat[];
  business: string;
  business_structure: BusinessStructure;
  scale: Scale;
  location: string;
  primary_coverage: string;
  state: string;
  broadcast_area: string;
  lga_coverage: string[];
  is_broadcast: boolean;
  change_category: ChangeCategory;
  change_type: ChangeType;
};

export type Status = "active" | "deprecated";

export type Contact = {
  id: number;
  contact_name?: string;
  email?: string;
  phone_number?: string;
  address?: string;
  website?: string;
  website_status?: string;
  website_status_code?: number;
  website_last_visit?: string;
};

export type ContactResponse = {
  outlet: {
    id: number;
    name: string;
  };
  contact: Contact;
};

export type OutletsResponse = {
  outlet: {
    id: number;
    created_at: string;
  };
  outlet_state: {
    id: number;
    name: string;
    scale: Scale;
    primary_format: PrimaryFormat;
    status: Status;
    change_type?: ChangeType;
    callsign: string;
  };
};

export type OutletStatesResponse = {
  outlet: {
    id: number;
    created_at: string;
  };
  outlet_state: OutletState[];
};

export type ActiveDatesResponse = {
  active_dates: Array<{
    start_date: string;
    end_date: string;
  }>;
};

export type BusinessEntity = {
	entity_name: string;
	entity_abn: string;
	entity_acn: string;
	entity_status: string;
	entity_location: string;
	entity_tax_gst?: string;
	entity_id: number;
	entity_type: string;
	entity_abr_link?: string | null;
	entity_acnc_link?: string | null;
	entity_asic_link?: string | null;
	entity_charitable_status_long?: string;
	entity_charitable_status_short: boolean;
	entity_common_name?: string | null;
	created_at?: string | null;
	entity_deductible_gift_status_short: boolean;
	entity_deductible_gift_status_long?: string;
	entity_tax_gst_concession: boolean;
	entity_tax_fbt_rebate: boolean;
	entity_tax_income_exemption: boolean;
	entity_tax_dgr: boolean;
	entity_owns_entity_ids?: Array<number> | null;
	entity_owned_by_entity_ids?: Array<number> | null;
};

export type BusinessEntityOutlet = {
	name: string;
	id: number;
	business: string;
	state: string;
	primary_coverage?: string
};

export type BusinessEntityOutlets = {
	outlets: BusinessEntityOutlet[]
};
export type SearchContextType = {
	records: any[];
	previousQuery: string | null | undefined;
};
export type OutletSearchContextType = {
	records: any[]
};
export type UserContextType = {
        role?: string | null
};
export type DeleteDialogType = {
	open: boolean;
	id?: number | null;
	name?: string | null;	
};
export type UpdateBusinessDialog = {
	entities: any[];
	open: boolean;
	id?: string | null;
};
export type CreateBusinessDialog = {
	open: boolean;
	entities: any[];
};
export type LinkOutletDialog = {
	open: boolean;
	entityId: string;
};
