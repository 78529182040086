import React from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { OutletsResponse, ScaleEnum, ChangeTypeEnum } from "../common/types";
import { ReactWindowTable } from "../components/ReactWindowTable/ReactWindowTable";

const COLUMNS = [
  {
    label: "Outlet name",
    dataKey: "outlet_name"
  },
  {
    label: "Current at",
    dataKey: "current_at"
  },
  {
    label: "Scale",
    dataKey: "scale"
  },
  {
    label: "Primary format",
    dataKey: "primary_format"
  },
  {
    label: "Callsign",
    dataKey: "callsign"
  },
  {
    label: "Status",
    dataKey: "status"
  },
  {
    label: "Change type",
    dataKey: "change_type"
  },
  {
    label: "",
    dataKey: "delete"
  },
  {
    label: "",
    dataKey: "show"
  }
];

export const OutletsTable = React.memo(function ({
  outlets,
  setDeleteDialog
}: {
  outlets: OutletsResponse[];
  setDeleteDialog: (values: {
    open: boolean;
    id: number;
    name: string;
  }) => void;
}) {
  const outletData = outlets.map((outlet) => {
    return {
      id: outlet.outlet.id,
      outlet_name: outlet.outlet_state.name,
      current_at: outlet.outlet.created_at,
      scale: outlet.outlet_state.scale && ScaleEnum[outlet.outlet_state.scale],
      primary_format: outlet.outlet_state.primary_format,
      status: outlet.outlet_state.status,
      callsign: outlet.outlet_state.callsign,
      change_type:
        outlet.outlet_state.change_type &&
        ChangeTypeEnum[outlet.outlet_state.change_type],
      delete: (
        <Button
          variant="text"
          onClick={() => {
            setDeleteDialog({
              open: true,
              id: outlet.outlet.id,
              name: outlet.outlet_state.name
            });
          }}
          startIcon={<CloseIcon />}
        >
          DELETE
        </Button>
      ),
      show: (
        <Button
          variant="text"
          component={Link}
          to={`/outlets/${outlet.outlet.id}`}
          startIcon={<AddIcon />}
        >
          SHOW
        </Button>
      )
    };
  });

  return <ReactWindowTable data={outletData} columns={COLUMNS} />;
});
