import React, { FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import user from "../common/User";
import { useHistory } from "react-router-dom";

type HeaderProps = {
  toggleSignOut: () => void;
  showTitle: boolean;
};

export const Header: FC<HeaderProps> = ({ toggleSignOut, showTitle }) => {
  const history = useHistory();
  const handleSignOut = () => {
    user.signOut().then(() => {
      toggleSignOut();
      history.push("/login");
    });
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className=".mui-fixed">
        <Toolbar>
          {showTitle && (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              The Australian Newsroom Mapping Project
            </Typography>
          )}
          <Button
            style={{ color: "#ffffff" }}
            variant="text"
            onClick={handleSignOut}
          >
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
