// vim: ts=2
import React, { useState, useEffect } from "react";
import { getCodesOfPractice, linkCodesOfPractice } from "./../common/apiHelpers";
import { Chip, Box, Grid, CircularProgress, TextField, Stack, MenuItem, IconButton, InputAdornment, Button } from "@mui/material";
import { Add } from "@mui/icons-material";

const LinkCodeOfPracticeForm = (props:any) => {

	const [codes, setCodes] = useState<null|any>(null);
	const [selected, setSelected] = useState<null|any>(null);
	const [selection, setSelection] = useState<null|any>(null);
	const outletId = props.entityId;

	useEffect(()=>{
		if(codes !== null){
			return;
		}
		const s = async (response:any) => {
			const records = await response.json();	
			setCodes(records);
		};
		const e = (error:any) => {
			console.log("Failed to get codes of practice from server.");
		};
		getCodesOfPractice(s, e);
	});
	const submitCodesOfPractice = (event:any) => {
		const payload = {
			cop_ids: selection.map((e:any, i:number)=>{ return e.id })
		};		
		const s = (response:any) => {	
			props.onCodeOfPracticeLinked();
		};
		const e = (error:any) => {
			console.log("Failed to link codes of practice ...");
		};
		linkCodesOfPractice(s, e, outletId, payload);
	};
	const removeCop = (id:number) => {
		if(selection === null || selection.length === 0){
			return;
		}
		const copy = [...selection];
		const existing = copy.findIndex((x:any)=>{ return x.id === id});
		if(existing !== undefined){
			copy.splice(existing, 1);
			setSelection(copy);
			return;
		}
	};
	const addCodeOfPractice = (event:any) => {
		const result = codes.find((x:any)=>{ return x.id === selected});
		if(result === undefined) return;
		const copy = selection === null ? [] : [...selection];
		const existing = copy.find((x:any)=>{ return x.id === selected});
		if(existing !== undefined) return;
		copy.push(result);
		setSelection(copy);
	};
	if(codes === null){
		return <CircularProgress/>;
	}
	const options = codes.map((e:any,i:number)=>{return <MenuItem value={e.id}>{e.cop_name}</MenuItem>});
	return (
			<Grid container>
				<Grid item xs={11}>
			<TextField select fullWidth 
					label={"Code of Practice"} 	
					variant={"outlined"} 
					value={selected} 
					onChange={(event)=>{setSelected(event.target.value);}}>
				{options}
			</TextField>
			</Grid>
			<Grid item xs={1}>
				<IconButton sx={{mt:"5px", ml:"15px"}} onClick={addCodeOfPractice}><Add/></IconButton>				
			</Grid>
			<Grid item xs={12}>
				<Box sx={{mt:"15px", textAlign:"center", border:"1px solid #CDCDCD", borderRadius:"5px", padding:"5px"}}>
					{selection === null || selection.length === 0 ? <p>No codes selected.<br/>Select one from the above list and click the <Add sx={{verticalAlign:"middle"}}/> button.</p> : selection.map((e:any, i:number)=>{ return <Chip key={`selected_cop_${e.id}`} color={"primary"} onDelete={(event:any)=>{removeCop(e.id);}} label={e.cop_name}/>})}
				</Box>
			</Grid>
			<Grid item xs={12} sx={{textAlign:"center"}}>
				<Button sx={{mt:"15px"}} variant={"contained"} size={"large"} color={"primary"} onClick={submitCodesOfPractice}>Submit</Button>
				<Button sx={{mt:"15px", ml:"15px"}} variant={"contained"} size={"large"} color={"primary"} onClick={()=>{setSelection([]);}}>Clear</Button>
			</Grid>
		</Grid>
	);
};
export default LinkCodeOfPracticeForm;
