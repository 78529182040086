import React, { useEffect, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, TextField, Stack, Button, Chip, Typography } from "@mui/material";
import { getBusinessEntity, getBusinessOutletsAsync, updateBusinessEntity, createBusinessEntity, getOutletsSync, updateBusinessOutlets } from "./../common/apiHelpers";
import { BusinessEntity, BusinessEntityOutlet, OutletSearchContextType } from "./../common/types";
import { OutletSearchContext } from "./../common/outletSearchContext";
import { Dropdown } from "./../components/fields";

const LinkOutletForm  = (props: any) => {
	const entityId = props.entityId;
	const [outlets, setOutlets] = useState<any[]|null>(null);
	const [outletIds, setOutletIds] = useState<number[]>([]);
	const [currentId, setCurrentId] = useState<number>(-1);
	const [query, setQuery] = useState<string>("");
	const outletSearch: OutletSearchContextType = useContext<OutletSearchContextType>(OutletSearchContext);
	// events
	const onSubmitClicked = () => {
		const payload = {
			outlet_ids: outletIds	
		};
		const s = (response: any) => {
			props.onOutletLinked();
		};
		updateBusinessOutlets(entityId, payload, s);
	};
	// effect handlers
	// load outlets
	// correlate to dropdown options
	const loadOutlets = () => {
		if(outlets !== null){
			return;
		}
		const s = (outlets: any[]) => {
			let options = outlets.map((e:any)=>{return {value: e.outlet.id, text: e.outlet_state.name}});
			options = [{value:-1, text:"Please select an option ..."}, ...options];
			outletSearch.records = options;
			setOutlets(options);
		};
		getOutletsSync(s);
	};	
	// effects
	useEffect(loadOutlets);
	// event handlers	
	const applySearch = () => { 
		const matches: any[] = outletSearch.records.filter((x)=>{ return x.text.toLowerCase().indexOf(query) !== -1});
		if(matches.length > 0){
			const first = matches[0];
			setCurrentId(first.value);	
		}	
		setOutlets(matches);
	};
	const unlinkOutlet = (id: number) => {
		const copy = [...outletIds];
		const i = copy.findIndex((x)=>{ return x === id});
		if(i === undefined){
			throw new Error(`Failed to find outlet for id ${id}`);
		}
		copy.splice(i, 1);	
		setOutletIds(copy);
	};
	const linkOutlet = (event: any) => {
		const copy = [...outletIds];
		copy.push(currentId);
		setOutletIds(copy);
		setCurrentId(-1);
	};
	if(outlets === null){
		return (
			<p>Loading ...</p>
		);	
	}
	let linkedOutlets = (
		<Box sx={{textAlign:"center"}}>
		<Stack spacing={1}>
		<Typography>No outlets have been linked.</Typography>
		<Typography>Select outlets from then following dropdown and click the plus button to link them.</Typography>
		</Stack>
		</Box>
	);
	if(outletIds.length > 0){
		linkedOutlets = (
			<Box>
				{outletIds.map((e:any,i:any)=>{ return <Chip key={e} label={outlets.find((x)=>{ return x.value === e}).text} color="primary" onDelete={()=>{unlinkOutlet(e);}} />})}
			</Box>
		);
	}
	return (
		<Grid container>
			<Grid item sm={12} sx={{padding:"10px"}}>
				<Stack spacing={3}>
					<Box sx={{border:"1px solid #CDCDCD", padding:"15px"}}>
						{linkedOutlets}
					</Box>
					<Box sx={{border:"1px solid #CDCDCD", padding:"15px"}}>
						<Grid container>
							<Grid item sm={12} sx={{marginBottom:"15px"}}>
								<TextField value={query} onBlur={applySearch} onChange={(event)=>{setQuery(event.target.value);}} helperText={"Search for an outlet by entering its name ..."} label={"Search Outlets"} fullWidth/>
							</Grid>
							<Grid item sm={10}>
								<Dropdown 
									options={outlets} 
									onValueChanged={(event: any)=>{setCurrentId(event.target.value);}} 
									label="Outlet" 
									value={currentId} 
									helperText={"Select an outlet to link to this entity."} />
							</Grid>
							<Grid item sm={2}>
								<Button variant="contained" onClick={linkOutlet} sx={{marginLeft:"10px", verticalAlign:"middle"}}><AddIcon/></Button>
							</Grid>
						</Grid>
					</Box>
					<Button variant={"contained"} fullWidth onClick={onSubmitClicked} size={"large"}>Submit</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default LinkOutletForm;
