import React from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";

type DataDisplayProps = {
  items?: Record<string, string | number | string[] | boolean>;
  mapping?: Record<string, string>;
  valueMapping?: Record<string, Record<string, string>>;
};

// if key mapping provided use it pretty print values
const flattenValue = (
  value: string | number | string[] | boolean,
  keyMapping: any
) => {
  if (typeof value === "boolean") {
    return String(value);
  }
  if (Array.isArray(value)) {
    if (keyMapping) {
      return value.map((val) => keyMapping[val]).join(", ");
    }
    return value.join(", ");
  }
  return keyMapping ? keyMapping[value] : value;
};

export function DataDisplay({
  items,
  mapping,
  valueMapping
}: DataDisplayProps) {
  items &&
    Object.keys(items).forEach((k) => {
      if (
        items[k] === undefined ||
        items[k] === null ||
        // @ts-ignore
        (Array.isArray(items[k]) && items[k].length === 0)
      ) {
        delete items[k];
      }
    });

  return (
    <Box>
      {items && (
        <List>
          {Object.entries(items).map(([key, value]) => (
            <ListItem key={key} divider>
              <ListItemText
                primary={mapping && key ? mapping[key] : ""}
                secondary={flattenValue(
                  value,
                  valueMapping && key in valueMapping ? valueMapping[key] : null
                )}
              ></ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
