import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
export const BusinessEntityTable = (props: any) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="Business Entity Table">
        <TableHead>
          <TableRow>
            <TableCell>Business Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>ABN</TableCell>
            <TableCell>Business Structure</TableCell>
            <TableCell>{/*ACTIONS*/}</TableCell>
            <TableCell>{/*ACTIONS*/}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.businessEntities.map((entity:any) => (
            <TableRow
              key={entity.id}
              hover
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{entity.entity_name}</TableCell>
              <TableCell>{entity.entity_status}</TableCell>
              <TableCell>{entity.entity_abn}</TableCell>
              <TableCell>{entity.entity_type}</TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={()=>{props.onDeleteClicked(entity.id);}}
                  startIcon={<CloseIcon />}
                >
                  DELETE
                </Button>
	      </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  startIcon={<AddIcon />}
                  component={Link}
                  to={`/businesses/${entity.id}`}
                >
                  SHOW
                </Button>
	            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
