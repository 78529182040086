export const useShowFields = (watch: any, mediaType: string) => {
  const scaleWatch = watch("scale");
  const formatWatch = watch("primary_format");

  const showMetro = Boolean(
    (mediaType === "alternative" && scaleWatch === "metro") ||
      (mediaType === "alternative" && scaleWatch === "")
  );

  const showState = Boolean(scaleWatch !== "national" && scaleWatch);

  const showLgaCoverage = Boolean(
    (mediaType === "alternative" && scaleWatch === "local") ||
    (mediaType === "alternative" && scaleWatch === "community") ||
      (mediaType === "alternative" && scaleWatch === "")
  );

  const showBroadcastArea = Boolean(mediaType === "broadcast");
  const showCallsign = Boolean(formatWatch === "radio" || formatWatch === "television");
  const showBroadcastLicenseSubservice = Boolean(formatWatch === "radio" || formatWatch === "television");
  return {
    showMetro,
    showState,
    showLgaCoverage,
    showBroadcastArea,
    showCallsign,
    showBroadcastLicenseSubservice
  };
};
