import { Auth } from "aws-amplify";

class User {
  user: any = null;

  constructor() {}

  async build() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.user = user;
    } catch (e) {
      console.log(e);
      this.user = null
    }
  }

  isUserLoggedIn() {
    return !!this.user;
  }

  get currentUser() {
    return this.user;
  }

  async signIn({ username, password }: { username: string; password: string }) {
    this.user = await Auth.signIn({ username, password });
    return this.user;
  }

  async completeNewPassword({ password }: { password: string }) {
    this.user = await Auth.completeNewPassword(this.user, password);

    return this.user;
  }

  async signOut() {
    await Auth.signOut();
    return this.user;
  }
}

const user = new User();

export default user;
