export const useScaleWatch = () => {
  const scaleCleanup = (
    scale: string | undefined,
    isBroadcast: boolean,
    setValue: any
  ) => {
    if (scale !== "metro" && scale) {
      setValue("metro_area", "");
    }
    if ((scale !== "local" && scale) || (isBroadcast && scale)) {
      setValue("lga_name", "");
      setValue("secondary_coverage", undefined);
    }
    if (scale === "national" && scale) {
      setValue("state", "");
    }
  };
  return { scaleCleanup };
};
