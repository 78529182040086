import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, TextField } from "@mui/material";
import { useMutation } from "react-query";
import { omit } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Contact } from "../common/types";
import { ContactSchema } from "../common/schemas";
import { updateContact } from "../common/apiHelpers";
import { InputWrapper } from "../components/InputWrapper";

type ContactFormProps = {
  contact?: Contact;
  closeDialog: () => void;
};

export function ContactForm({ contact, closeDialog }: ContactFormProps) {
  const { mutate: mutateContact, isLoading } = useMutation(updateContact, {
    onSuccess: () => {
      closeDialog();
    }
  });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: omit(contact, ["id"]),
    resolver: yupResolver(ContactSchema)
  });

  const onSubmit = (event: Omit<Contact, "id">) => {
    Object.keys(event).forEach((item) => {
      // @ts-ignore
      if (!event[item]) {
        // @ts-ignore
        event[item] = null;
      }
    });
    mutateContact({ data: event, id: contact?.id });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "grid",
        gap: "16px"
      }}
    >
      <Controller
        name="contact_name"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.contact_name?.message}>
            <TextField
              label="Contact name"
              error={!!errors.contact_name?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="website"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.website?.message}>
            <TextField
              label="Website"
              error={!!errors.website?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="news_archive_url"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.news_archive_url?.message}>
            <TextField
              label="News Archive URL"
              error={!!errors.news_archive_url?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.email?.message}>
            <TextField
              label="E-mail"
              error={!!errors.email?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="phone_number"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.phone_number?.message}>
            <TextField
              label="Phone"
              error={!!errors.phone_number?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.address?.message}>
            <TextField
              label="Address"
              error={!!errors.address?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="facebook_url"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.facebook_url?.message}>
            <TextField
              label="Facebook URL"
              error={!!errors.facebook_url?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="twitter_url"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.twitter_url?.message}>
            <TextField
              label="Twitter URL"
              error={!!errors.twitter_url?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="instagram_url"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.instagram_url?.message}>
            <TextField
              label="Instagram URL"
              error={!!errors.instagram_url?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="linkedin_url"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.linkedin_url?.message}>
            <TextField
              label="LinkedIn URL"
              error={!!errors.linkedin_url?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="tiktok_url"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.tiktok_url?.message}>
            <TextField
              label="TikTok URL"
              error={!!errors.tiktok_url?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Controller
        name="youtube_url"
        control={control}
        render={({ field }) => (
          <InputWrapper errorMessage={errors.youtube_url?.message}>
            <TextField
              label="Youtube URL"
              error={!!errors.youtube_url?.message}
              {...field}
            />
          </InputWrapper>
        )}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{
            marginRight: "16px"
          }}
          variant="outlined"
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={isLoading}>
          Save
        </Button>
      </Box>
    </form>
  );
}
