import {
  PublicationFormat,
  PrimaryFormat,
  BusinessStructure,
  PublicationSchedule,
  BroadcastLicenseSubservice,
  Scale
} from "./types";

type Choices<T> = Array<{ id: T; name: string }>;

export const stateChoices = [
  { id: "act", name: "ACT" },
  { id: "nsw", name: "NSW" },
  { id: "nt", name: "NT" },
  { id: "qld", name: "QLD" },
  { id: "sa", name: "SA" },
  { id: "tas", name: "TAS" },
  { id: "vic", name: "VIC" },
  { id: "wa", name: "WA" },
  { id: "ot", name: "Other territory" }
];

export const publicationScheduleChoices: Choices<PublicationSchedule> = [
  { id: "daily_monday_to_sunday", name: "Daily (M-Su)" },
  { id: "daily_monday_to_saturday", name: "Daily (M-Sa)" },
  { id: "daily_monday_to_friday", name: "Daily (M-F)" },
  { id: "four_per_week", name: "4x week" },
  { id: "three_per_week", name: "3x week" },
  { id: "two_per_week", name: "2x week" },
  { id: "weekly", name: "Weekly" },
  { id: "every_three_weeks", name: "Every three weeks" },
  { id: "fortnightly", name: "Fortnightly" },
  { id: "monthly", name: "Monthly" },
  { id: "every_two_months", name: "Every two months" },
  { id: "quarterly", name: "Quarterly" },
  { id: "infrequent", name: "Infrequent" },
  { id: "other", name: "Other" },
];

export const primaryFormatChoices: Choices<PrimaryFormat> = [
  { id: "print", name: "Print" },
  { id: "radio", name: "Radio" },
  { id: "television", name: "Television" },
  { id: "digital", name: "Digital" }
];

export const publicationFormatsChoices: Choices<PublicationFormat> = [
  { id: "newspaper", name: "Newspaper" },
  { id: "newsletter", name: "Newsletter" },
  { id: "radio", name: "Radio"}, 
  { id: "magazine", name: "Magazine" },
  { id: "television", name: "Television" },
  { id: "website", name: "Website" },
  { id: "digital_print", name: "Digital print" },
  { id: "livestream", name: "Live Stream" },
  { id: "podcast", name: "Podcast" },
  { id: "catchup_service", name: "Catch-up Service" },
  { id: "social_media", name: "Social media" },
  { id: "dedicated_app", name: "Dedicated app" },
  { id: "other", name: "Other" }
];

export const businessStructureChoices: Choices<BusinessStructure> = [
  { id: "private_or_public_company", name: "Private or public company" },
  { id: "public_broadcaster", name: "Public broadcaster" },
  { id: "not_for_profit_or_charity", name: "Not for profit or charity" },
  { id: "sole_trader", name: "Sole trader" },
  { id: "unincorporated", name: "Unincorporated" },
  { id: "undetermined", name: "Undetermined" },
  { id: "other", name: "Other" }
];

export const scaleChoices: Choices<Scale> = [
  { id: "community", name: "Community" },
  { id: "local", name: "Local" },
  { id: "metro", name: "Metro" },
  { id: "state_territory", name: "State Territory" },
  { id: "national", name: "National" },
  { id: "not_applicable", name: "Not Applicable" }
];

/** Only if scale is type metro */
export const metroChoices = [
  { id: "australian_capital_territory", name: "Australian Capital Territory" },
  { id: "greater_adelaide", name: "Greater Adelaide" },
  { id: "greater_brisbane", name: "Greater Brisbane" },
  { id: "greater_darwin", name: "Greater Darwin" },
  { id: "greater_hobart", name: "Greater Hobart" },
  { id: "greater_melbourne", name: "Greater Melbourne" },
  { id: "greater_perth", name: "Greater Perth" },
  { id: "greater_sydney", name: "Greater Sydney" }
];

export const broadcastLicenseSubserviceChoices: Choices<BroadcastLicenseSubservice> = [
	{ id: "commercial_radio", name: "Commerical radio" },
	{ id: "commercial_radio_section_40_non_bsb", name: "Commercial radio (Section 40 - Non-BSB)" },
	{ id: "commercial_television", name: "Commercial television" },
	{ id: "community_broadcasting", name: "Community broadcasting" },
	{ id: "international_broadcasting", name: "International broadcasting" },
	{ id: "national_broadcasting", name: "National broadcasting" },
	{ id: "subscription_television_broadcasting", name: "Subscription television broadcasting" },
	{ id: "temporary_community_broadcasting", name: "Temporary community broadcasting" }
];

export const coverageTypeChoices = [
  { id: "primary", name: "Primary" },
  { id: "secondary", name: "Secondary" },
  { id: "metro", name: "Metro" }
];

export const changeCategoryChoices = [
  {
    id: "contraction",
    name: "Contraction"
  },
  {
    id: "expansion",
    name: "Expansion"
  }
];

export const changeTypeChoices = [
  {
    id: "new_masthead_station_website",
    name: "New masthead, station or website"
  },
  {
    id: "newsroom_opened",
    name: "Newsroom opened"
  },
  {
    id: "service_increase",
    name: "Service increase"
  },
  {
    id: "new_print_edition",
    name: "New print edition"
  },
  { id: "demerger", name: "Demerger" },
  {
    id: "merger",
    name: "Merger"
  },
  {
    id: "end_of_print_edition",
    name: "End of print edition"
  },
  {
    id: "service_decrease",
    name: "Service decrease"
  },
  {
    id: "newsroom_closure",
    name: "Newsroom closure"
  },
  {
    id: "masthead_or_station_closure",
    name: "Masthead or station closure"
  }
];

export const OutletStateLabels = {
  id: "ID",
  current_at: "Current at",
  deprecated_at: "Deprecated at",
  name: "Name",
  status: "Status",
  primary_format: "Primary format",
  publication_formats: "Publication formats",
  publication_schedule: "Publication schedule",
  business_entities: "Linked businesses",
  business_structure: "Business structure",
  scale: "Scale",
  location: "Location",
  primary_coverage: "Primary coverage",
  state: "State",
  broadcast_areas: "Broadcast areas",
  lga_coverage: "LGA coverage",
  is_broadcast: "Broadcast outlet",
  change_category: "Change category",
  change_type: "Change type",
  display: "Display on visualisations?"
};

export const ContactLabels = {
  id: "ID",
  contact_name: "Contact name",
  email: "Email",
  phone_number: "Phone number",
  address: "Address",
  website: "Website",
  news_archive_url: "News Archive URL",
  facebook_url: "Facebook URL",
  twitter_url: "Twitter URL",
  instagram_url: "Instagram URL",
  youtube_url: "Youtube URL",
  tiktok_url: "TikTok URL",
  linkedin_url: "LinkedIn URL"
};

export const BusinessEntityLabels = {
	name: "Name",
	abn: "ABN",
	status: "Status",
	location: "Main Business Location",
	type: "Business Type",
	commonName: "Common Name",
	gstConcession: "Receives GST Concession"	
};
