import React, { useMemo, useState, useContext, useEffect } from "react";
import { Button, Stack, TextField, Box, Grid, CircularProgress, Typography, Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "./DataTable/DataTable";
import { SearchContextType } from "../common/types";
import { SearchContext } from "./../common/searchContext";
import { getContactsAsync } from "./../common/apiHelpers";
import { ContactForm } from "./../pages/ContactForm";
import moment from "moment";

const COLS = [
	{ headerName: "Outlet name", field: "outlet_name" },
	{ headerName: "Contact name", field: "contact_name" },
	{ headerName: "Email", field: "email" },
	{ headerName: "Website", field: "website" },
	{ headerName: "Website health", field: "website_status" },
	{ headerName: "Website status code", field: "website_status_code" },
	{ headerName: "Time of last visit", field: "website_last_visit" },
	{ headerName: "Edit", field: "edit" } 
];

const SORT = {
	"Website health": { sorted: false, mode: "none" }
};

const ContactTable = (props: any) => {

	const [sort, setSort] = useState(SORT);
	const [query, setQuery] = useState("");
	const [editing, setEditing] = useState<boolean>(false);
	const context: any = useContext(SearchContext);
	const [contacts, setContacts] = useState<any|null>(null);

	const mapper = (contact: any, i: number) => {
		return {
			id: contact.outlet.id,
			outlet_name: contact.outlet.name,
      contact_name: contact.contact.contact_name,
      email: contact.contact.email,
			phone_number: contact.contact.phone_number,
			address: contact.contact.address,
      website: contact.contact.website,
      website_status: contact.contact.website_status,
      website_status_code: contact.contact.website_status_code,
      website_last_visit: contact.contact.website_last_visit,
			facebook_url: contact.contact.facebook_url,
			instagram_url: contact.contact.instagram_url,
			linkedin_url: contact.contact.linkedin_url,
			tiktok_url: contact.contact.tiktok_url,
			twitter_url: contact.contact.twitter_url,
			youtube_url: contact.contact.youtube_url,
      edit: (
				<Button
        	variant="text"
          startIcon={<CloseIcon />}
          onClick={() => {
						const mapped = [contact].map(mapper);
						context.selectedContact = mapped[0];
						setEditing(true);
       		}}
       	>
        	EDIT
        </Button>
      )
		};
	};

	useEffect(()=>{
		if(contacts === null){
			const s = async (response:any) => {
				const data = await response.json();
				const mapped = data.map(mapper);
				context.contacts = mapped;
				setContacts(mapped);
			};
			const e = () => {
				// snackbar ...
			};
			getContactsAsync(s, e);
		}	
	});
	const onSortClicked = (event: any, headerName: string) => {
		const sorted: any = {...sort};
		const sortState = sorted[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			sortData(sortState, headerName);
			setSort(sorted);
			return;
		}
		const mode = sortState.mode === "asc" ? "desc" : "asc";
		sortState.mode = mode;
		sortData(sortState, headerName);
		setSort(sorted);
	};
	const sortData = (sortState: any, headerName: string) => {
		const funs: any = {
			"Website health": { 
				"asc": (left: any, right: any) => { 
					const a = left.website_status === null || left.website_status === undefined ? "" : left.website_status;
					const b = right.website_status === null || right.website_status === undefined ? "" :  right.website_status;
					return a.localeCompare(b);
				}, 
				"desc": (left: any, right: any) => { 
					const a = left.website_status === null || left.website_status === undefined ? "" : left.website_status;
					const b = right.website_status === null || right.website_status === undefined ? "" :  right.website_status;
					return b.localeCompare(a);
				} 
			},
		};
		const f: any = funs[headerName][sortState.mode];
		contacts.sort(f)
	};
	const filterContacts = () => {
		const filtered = context.contacts.filter((x:any)=>{
			return x.outlet_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
		});
		setContacts(filtered);
	};
	const clearContacts = () => {
		setContacts([...context.contacts]);
		setQuery("");
	};
	if(contacts === null){
		return (
			<Box>
				<Stack spacing={1}>
					<span><Typography sx={{display:"inline"}} variant={"body1"}>Loading contacts ... <CircularProgress sx={{verticalAlign:"middle", ml:"10px"}}/></Typography></span>
				</Stack>
			</Box>	
		);
	}
	const dialog = (
		<Dialog open={editing} onClose={()=>{setEditing(false);}} maxWidth={"sm"} fullWidth>
			<DialogContent>
				<ContactForm contact={context.selectedContact} closeDialog={()=>{setEditing(false);setContacts(null);}}/>
			</DialogContent>
		</Dialog>	
	);
	return ( 
		<Box>
		{dialog}
		<Stack spacing={2}>
		<Grid container spacing={2}>
		<Grid item xs={4}>
		<TextField value={query} 
			label={"Search ..."} 
			helperText={"Search for contacts via outlet name"} 
			fullWidth 
			onChange={(event:any)=>{setQuery(event.target.value);}} />
		</Grid>
		<Grid item xs={1}>
			<Button size={"large"} onClick={()=>{filterContacts();}} fullWidth sx={{"height":"56px"}} color={"primary"} variant={"contained"}>
				Apply
			</Button>
		</Grid>
		<Grid item xs={1}>
			<Button size={"large"} onClick={()=>{clearContacts();}} fullWidth sx={{"height":"56px"}} color={"primary"} variant={"contained"}>
				Clear
			</Button>
		</Grid>
    <Grid item xs={6} sx={{textAlign:"right"}}>
			<Button size={"large"} onClick={()=>{setContacts(null);}} sx={{"height":"56px"}} color={"primary"} variant={"contained"}>
				Refresh
			</Button>
		</Grid>
		<Grid item xs={12}>
		<DataTable paginate={true} rows={contacts} 
			columns={COLS} 
			sort={sort} 
			onRowClicked={()=>{}} 
			onHeaderClicked={()=>{}} 
			onSortClicked={onSortClicked} 
			filters={[]} 
			headerStyles={{"Website":{"maxWidth":"300px"}}} />
		</Grid>
		</Grid>
		</Stack>
		</Box>
	);
};
export default ContactTable;
