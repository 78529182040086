import React, { useState, useContext } from "react";
import {
  Snackbar,
  Alert,
  AppBar,
  Button,
  Toolbar,
  Box,
  Card,
  CardContent,
  TextField,
  Typography
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { LoginSchema } from "../common/schemas";
import { InputWrapper } from "../components/InputWrapper";
import user from "../common/User";
import { UserContext } from "../common/userContext";
import { UserContextType } from "./../common/types";
import { useHistory } from "react-router-dom";

type LoginProps = {
  successCallback: () => void;
};

export function Login({ successCallback }: LoginProps) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(LoginSchema)
  });
  const history = useHistory();
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const context: UserContextType  = useContext(UserContext); 

  const onSubmit = async ({ username, password }: any) => {
    try {
      const { challengeName, ...rest } = await user.signIn({
        username,
        password
      });
      if (challengeName === "NEW_PASSWORD_REQUIRED") {
        history.replace("/login/change-password");
        return;
      } else {
        const role: string | null | undefined = rest.attributes["custom:UserRole"];
        if(role !== null && role !== undefined){
        	context.role = role.toUpperCase();	
				}
        successCallback();
      }
    } catch (e) {
      console.log(e);
      setErrorSnackbar(true);
    }
  };

  return (
    <>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setErrorSnackbar(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Unable to login, please try again
        </Alert>
      </Snackbar>
      <Box sx={{ height: "100vh" }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar className=".mui-fixed">
            <Toolbar></Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <img
            style={{
              width: "400px",
              marginBottom: "4rem"
            }}
            src="/assets/piji.svg"
          ></img>
          <Card
            sx={{
              minWidth: "200px",
              maxWidth: "600px",
              minHeight: "200px",
              maxHeight: "600px"
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    marginBottom: "2rem"
                  }}
                >
                  <Typography variant="h6">
                    Australian Newsroom Mapping Project
                  </Typography>
                  <Typography>Login</Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    rowGap: "1rem",
                    placeItems: "center",
                    maxWidth: "250px",
                    margin: "0 auto"
                  }}
                >
                  <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <InputWrapper errorMessage={errors.username?.message}>
                        <TextField
			  id={"username"}
                          label="Username"
                          type="email"
                          autoComplete="current-username"
                          fullWidth
                          error={!!errors.username?.message}
                          {...field}
                        />
                      </InputWrapper>
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <InputWrapper errorMessage={errors.password?.message}>
                        <TextField
		          id={"password"}
                          label="Password"
                          type="password"
                          autoComplete="current-password"
                          fullWidth
                          error={!!errors.password?.message}
                          {...field}
                        />
                      </InputWrapper>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <Button variant="contained" type="submit" id={"loginButton"}>
                    Login
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
}
