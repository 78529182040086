// vim: ts=2
import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Autocomplete,
  Typography,
  TextField,
  FormHelperText,
  Switch,
  CircularProgress
} from "@mui/material";
import { MobileDatePicker } from "@mui/lab";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { formatISO } from "date-fns";
import {
  ControlledSelect,
  ControlledMultipleSelect
} from "../components/ControlledSelect";
import { lgaChoices } from "../sources/lga";
import { broadcastChoices } from "../sources/broadcast";
import {
  stateChoices,
  primaryFormatChoices,
  publicationFormatsChoices,
  publicationScheduleChoices,
  scaleChoices,
  metroChoices
} from "../common/constants";
import { createOutletState, retireOutletState, getChangeTypeChoices } from "../common/apiHelpers";
import { OutletStateUpdateSchema } from "../common/schemas";
import { InputWrapper } from "../components/InputWrapper";
import { minDate } from "../common/formatDate";
import { useScaleWatch } from "./form-hooks/useScaleWatch";
import { useShowFields } from "./form-hooks/useShowFields";
import { useLgaCoverageError } from "./form-hooks/useLgaCoverageError";
import { OutletState } from "../common/types";
import { cleanOutletState } from "../common/cleanUpdateState";

type OutletStateUpdateFormProps = {
  closeDialog: () => void;
  outletId: string;
  /** The outlet state to retire */
  outletStateIdToRetire?: string | number | undefined;
  canRetire: boolean;
  /** used for pre-populating fields */
  outletState?: OutletState | null;
};

export function OutletStateUpdateForm({
  closeDialog,
  outletId,
  outletStateIdToRetire,
  canRetire,
  outletState
}: OutletStateUpdateFormProps) {
  const timerRef = useRef<number | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const [changeTypeChoices, setChangeTypeChoices] = useState<any|null>(null);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(OutletStateUpdateSchema(canRetire)),
    defaultValues: {
      ...(outletState ? cleanOutletState(outletState) : {}),
      isOutletInOperation: "yes",
      deprecated_at: new Date(),
      current_at: new Date()
    }
  });

  const isOutletInOperationWatcher = watch("isOutletInOperation");

  const lgaCoverageError = useLgaCoverageError(watch);
  const {
    mutate: mutateRetire,
    isLoading: isLoadingRetire,
    isError: isErrorRetire,
    isSuccess: isSuccessRetire
  } = useMutation(retireOutletState);

  const {
    mutate: mutateCreateOutletState,
    isLoading: isLoadingCreateOutlet,
    isError: isErrorCreateOutlet,
    isSuccess: isSuccessCreateOutlet
  } = useMutation(createOutletState, { onSuccess: closeDialog });
  const [mediaType, setMediaType] = useState("alternative");
  const {
    showMetro,
    showState,
    showLgaCoverage,
    showBroadcastArea
  } = useShowFields(watch, mediaType);

  const onSubmit = (event: any) => {
    const {
      isOutletInOperation,
      change_type_id,
      change_description,
      deprecated_at,
      ...rest
    } = event;

    if (canRetire) {
      // first retire outlet
      mutateRetire({
        outletId,
        outletStateId: outletStateIdToRetire || "",
        data: {
          change_type_id,
          change_description,
          deprecated_at: formatISO(deprecated_at)
        }
      });
    }

    if (isOutletInOperation === "no") {
      return;
    }

    let outletStateEdited = rest;

    // 1. if scale !== metro, metro_area = null, otherwise take value
    if (event.scale !== "metro") {
      outletStateEdited = { ...outletStateEdited, metro_area: null };
    }
    // 2. if scale === national, state = null, otherwise take value
    if (event.scale === "national") {
      outletStateEdited = { ...outletStateEdited, state: null };
    }
    // 3. if scale !== local, secondary_coverage = null, lga_name = null
    if (event.scale !== "local") {
      outletStateEdited = {
        ...outletStateEdited,
        secondary_coverage: null,
        lga_name: null
      };
    }
    // 6. if media type is not broadcast then remove a potential broadcast id
    if (mediaType === "alternative") {
      outletStateEdited = {
        ...outletStateEdited,
        broadcast_area: null
      };
    }

    outletStateEdited = {
      ...outletStateEdited,
      current_at: formatISO(event.current_at)
    };

    timerRef.current = window.setTimeout(() => {
      mutateCreateOutletState({ outletId, outletState: outletStateEdited });
    }, 750);
  };

  const scaleWatch = watch("scale");
  const { scaleCleanup } = useScaleWatch();

  useEffect(() => {
    scaleCleanup(scaleWatch, mediaType === "broadcast", setValue);
  }, [scaleWatch, mediaType]);

  const handleMediaTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMediaType(e.target?.value);
  };

  useEffect(() => {
    if (isErrorRetire) {
      enqueueSnackbar("Unable to retire current outlet state", {
        variant: "error"
      });
    }
    if (isErrorCreateOutlet) {
      enqueueSnackbar("Unable to create outlet state", { variant: "error" });
    }
  }, [isErrorRetire, isErrorCreateOutlet]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isOutletInOperationWatcher === "no" && isSuccessRetire) {
      closeDialog();
      return;
    }
    if (
      isOutletInOperationWatcher === "yes" &&
      (isSuccessRetire || !canRetire) &&
      isSuccessCreateOutlet
    ) {
      closeDialog();
      return;
    }
  }, [
    isOutletInOperationWatcher,
    isSuccessRetire,
    isSuccessCreateOutlet,
    closeDialog
  ]);

  useEffect(()=>{
		if(changeTypeChoices !== null){
			return;
		}	
		const s = async (response:any) => {
			const choices = await response.json();
			setChangeTypeChoices(choices);
		};
		const e = (error:any) => {
			console.log("Failed to get change type choices ...");
		};
		getChangeTypeChoices(s, e);
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "grid",
        gap: "16px"
      }}
    >
      {canRetire && (
        <>
          <Typography variant="h6">Change information</Typography>
          <FormHelperText style={{ transform: "translate(0 , -10px)" }}>
            Note: this operation will retire the existing active state of the
            outlet.
          </FormHelperText>
          <Controller
            name="deprecated_at"
            control={control}
            render={({ field }) => (
              <MobileDatePicker
                {...field}
                label="Deprecated at"
                renderInput={(params) => <TextField {...params} />}
                minDate={minDate}
              />
            )}
          />
	{ /* dynamically get change type choices ... */ }
	{changeTypeChoices !== null ? 
          <ControlledSelect
            name="change_type_id"
            label="Change type"
            control={control}
            choices={changeTypeChoices}
            errorMessage={errors.change_type_id?.message}
          /> : <Box sx={{padding:"15px", border:"1px solid #CDCDCD", borderRadius:"5px"}}><Typography sx={{textAlign:"center"}}>Loading change type options ...<CircularProgress sx={{verticalAlign:"middle", marginLeft:"10px"}}/></Typography></Box>}

          <Controller
            name="change_description"
            control={control}
            render={({ field }) => (
                <TextField
                  label="Change description"
                  {...field}
                />
            )}
          />
          <Controller
            name="isOutletInOperation"
            control={control}
            render={({ field }) => (
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Will this outlet remain in operation?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="is-outlet-remain-in-operation-"
                  {...field}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            )}
          />
        </>
      )}
      {isOutletInOperationWatcher === "yes" && (
        <>
          <Typography variant="h6">Updated outlet information</Typography>
          <FormControl>
            <FormLabel>Outlet type</FormLabel>
            <RadioGroup
              row
              value={mediaType}
              onChange={(e) => handleMediaTypeChange(e)}
            >
              <FormControlLabel
                value="alternative"
                control={<Radio />}
                label="Non-broadcast media"
              />
              <FormControlLabel
                value="broadcast"
                control={<Radio />}
                label="Broadcast media"
              />
            </RadioGroup>
          </FormControl>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <InputWrapper errorMessage={errors.name?.message}>
                <TextField
                  label="Outlet name"
                  error={!!errors.name?.message}
                  {...field}
                />
              </InputWrapper>
            )}
          />
          <Controller
            name="current_at"
            control={control}
            render={({ field }) => (
              <MobileDatePicker
                {...field}
                label="Current at"
                renderInput={(params) => (
                  <InputWrapper errorMessage={errors.current_at?.message}>
                    <TextField
                      {...params}
                      error={!!errors.current_at?.message}
                    />
                  </InputWrapper>
                )}
                minDate={minDate}
              />
            )}
          />
          <ControlledSelect
            name="scale"
            label="Scale"
            control={control}
            choices={scaleChoices}
            errorMessage={errors.scale?.message}
          />
          <ControlledSelect
            name="primary_format"
            label="Primary format"
            control={control}
            choices={primaryFormatChoices}
            errorMessage={errors.primary_format?.message}
          />
          <ControlledMultipleSelect
            name="publication_formats"
            label="Publication formats"
            control={control}
            choices={publicationFormatsChoices}
            errorMessage={
              errors?.publication_formats &&
              errors.publication_formats[0]?.message
            }
          />
          <ControlledMultipleSelect
            name="publication_schedule"
            label="Publication Schedule"
            control={control}
            choices={publicationScheduleChoices}
          />
          <Controller
            name="primary_coverage"
            control={control}
            render={({ field }) => (
              <InputWrapper errorMessage={errors.primary_coverage?.message}>
                <TextField
                  label="Primary coverage"
                  error={!!errors.primary_coverage?.message}
                  {...field}
                />
              </InputWrapper>
            )}
          />
          {showMetro && (
            <ControlledSelect
              name="metro_area"
              label="Metro area"
              control={control}
              choices={metroChoices}
              errorMessage={errors.metro_area?.message}
              disabled={getValues("scale") !== "metro"}
            />
          )}
          {showState && (
            <ControlledSelect
              name="state"
              label="State or territory"
              control={control}
              choices={stateChoices}
              errorMessage={errors.state?.message}
              disabled={getValues("scale") === "national"}
            />
          )}
          {showLgaCoverage && (
            <Controller
              name="lga_coverage"
              control={control}
              render={({ field }) => (
                // @ts-ignore
                <Autocomplete
                  {...field}
                  value={
                    field?.value &&
                    Array.isArray(field?.value) &&
                    field?.value?.length
                      ? lgaChoices.filter((lga) =>
                          field?.value?.includes(lga.id)
                        )
                      : []
                  }
                  onChange={(_event, newValue) => {
                    field.onChange(newValue.map((value) => value?.id));
                  }}
                  disabled={getValues("scale") !== "local"}
                  multiple
                  id="lga_coverage-auto"
                  options={lgaChoices}
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="LGA coverage"
                      helperText={
                        lgaCoverageError
                          ? "You must select at least one LGA"
                          : "Start typing for suggestions"
                      }
                      error={lgaCoverageError}
                    />
                  )}
                />
              )}
            />
          )}
          <Typography variant="h6">Visualisation</Typography>
          <Controller
            name="display"
            control={control}
            render={({ field }) => (
              <Box style={{ justifySelf: "start" }}>
                <FormControlLabel
                  label="Display on visualisations?"
                  labelPlacement="start"
                  control={<Switch {...field} checked={field.value} />}
                />
              </Box>
            )}
          />
        </>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{
            marginRight: "16px"
          }}
          variant="outlined"
          onClick={() => closeDialog()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoadingCreateOutlet || isLoadingRetire}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}
