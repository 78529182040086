import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

import user from "./common/User";

user.build().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
});
