import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import TableColumns from "./TableColumns";

export const ROW_SIZE = 74;

// @ts-ignore
const Row: React.FC<any> = React.memo(
  ({ index, style, data: { columns, items, classes } }) => {
    const item = items[index];

    return (
      <TableRow
        component="div"
        style={style}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          boxSizing: "border-box",
          minWidth: "100%",
          width: "100%"
        }}
      >
        {/* @ts-ignore */}
        {columns.map((column, colIndex) => {
          return (
            <TableCell
              key={item.id + colIndex}
              component="div"
              variant="body"
              style={{
                display: "flex",
                alignItems: "center",
                height: ROW_SIZE,
                flex: "1 0 0",
                color: item[column.dataKey] === "active" ? "green" : "inherit",
              }}
            >
              {item[column.dataKey]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
);

const itemKey = (index: any, data: any) => data.items[index].id;

const createItemData = (columns: any, data: any) => ({
  columns,
  items: data
});

export const ReactWindowTable = ({
  data,
  columns
}: {
  data: any;
  columns: any;
}) => {
  const itemData = createItemData(columns, data);

  return (
    <Box
      sx={{
        display: "block",
        flex: "1",
        height: "calc(100% - 60px)",
        width: "100%"
      }}
    >
      <Table
        component="div"
        sx={{
          height: "100%",
          width: "100%"
        }}
      >
        <TableHead component="div">
          <TableColumns columns={columns} />
        </TableHead>

        <TableBody component="div">
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={data.length}
                itemSize={ROW_SIZE}
                itemKey={itemKey}
                // @ts-ignore
                itemData={itemData}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        </TableBody>
      </Table>
    </Box>
  );
};
