// vim: ts=2
import React, { useEffect, useState, useCallback, useContext } from "react";
import DataTable from "./../components/DataTable/DataTable";
import { Link } from "react-router-dom"; 
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Dialogs from "./../common/dialogs";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Grid
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
import { OutletForm } from "./OutletForm";
import { getBusinessEntities, deleteBusiness, getHeaders, getBusinessExport } from "../common/apiHelpers";
import { OutletsResponse, SearchContextType, CreateBusinessDialog } from "../common/types";
import { SearchBar } from "../components/SearchBar";
import { SearchContext } from "./../common/searchContext";

const COLS = [
	{ headerName: "Name", field: "name" },
	{ headerName: "Status", field: "status" },
	{ headerName: "ABN", field: "abn" },
	{ headerName: "Entity Type", field: "type" },
	{ headerName: "Linked Outlets", field: "outlets" },
	{ headerName: "Child Entities", field: "child_entity_names" },
	{ headerName: "Parent Entities", field: "parent_entity_names" },
	{ headerName: "Delete", field: "delete" },
	{ headerName: "Show", field: "show" } 
];

const SORT = {
	"Name": { sorted: false, mode:"none" },
	"Linked Outlets": { sorted: false, mode: "none" }
};

export function Businesses() {
	const context: SearchContextType = useContext(SearchContext);
	const [entities, setEntities] = useState<any>(null);
	const [deleteDialog, setDeleteDialog] = useState<any>(null);
	const [createDialog, setCreateDialog] = useState<CreateBusinessDialog|null>(null);
	const [query, setQuery] = useState<string>("");
	const [sort, setSort] = useState<any>(SORT);
	const onDeleteClicked = (event: any, id: number, name: string) => {	
		const s = { open: true, id: id, name: name };
		setDeleteDialog(s);
	};
	const watchQuery = () => {
		if(query === null || query === undefined || query === null || query === ""){
			return;
		}	
		if(context.previousQuery === null || context.previousQuery === undefined || context.previousQuery !== query){
			doQuery();
			return;
		}
		if(context.previousQuery === query){
			return;
		}
	};
	const loadBusinessEntities = () => {
		if(entities !== null){
			return;
		}
		getBusinessEntities((data: any)=>{
			const index = data.reduce((a:any,c:any)=>{ a[`${c.id}`] = c; return a;}, {});
			const records = data.map((e: any, i: any)=>{
				return {
					id: e.id,
					name: e.entity_name,
					status: e.entity_status,
          common_name: e.entity_common_name,
					abn: e.entity_abn,
					type: e.entity_type,
					outlets: e.outlets.map((e:any,i:any)=>{ return e.name }).join(", "),
					parent_entity_names: e.entity_owned_by_entity_ids.map((e:any,i:any)=>{ return index[`${e}`].entity_name}).join(", "),
					child_entity_names: e.entity_owns_entity_ids.map((e:any,i:any)=>{ return index[`${e}`].entity_name}).join(", "),
					delete: (
        		<Button
							key={e.id}
          		variant="text"
          		onClick={(event)=>{onDeleteClicked(event, e.id, e.name);}}
          		startIcon={<CloseIcon />}>
          		DELETE
       			</Button>
					),
					show: (
        		<Button
							key={e.id}
          		variant="text"
          		component={Link}
          		to={`/businesses/${e.id}`}
          		startIcon={<AddIcon />}>
          	SHOW
        		</Button>
					) 
				};
			});
			context.records = [...records];
			setEntities(records);
		});
	};
	useEffect(loadBusinessEntities);
	useEffect(watchQuery);
	const clearQuery = () => {
		const copy = [...context.records];
		context.previousQuery = "";
		setQuery("");
		setEntities(copy);
	};
	const updateQuery = (event: any) => {
		const q = event.target.value;	
		if(q === ""){
			clearQuery();
			return;
		}
		setQuery(q);
	};
	const doQuery = () => {
		// query businesses here ...
		// search on either ABN or name
		const filter = (e: any) => {
			const lowerCase = query.toLowerCase();
			return e.name.toLowerCase().indexOf(lowerCase) !== -1 || 
				e.abn.toLowerCase().indexOf(lowerCase) !== -1 ||
        ( e.common_name !== null && e.common_name !== undefined && e.common_name.toLowerCase().indexOf(lowerCase) !== -1 ) ||
				( e.parent_entity_names !== null && e.parent_entity_names !== undefined && e.parent_entity_names.toLowerCase().indexOf(lowerCase) !== -1 ) || 
				( e.child_entity_names !== null && e.child_entity_names !== undefined && e.child_entity_names.toLowerCase().indexOf(lowerCase) !== -1 );
		};
		const matches = context.records.filter(filter);
		context.previousQuery = query;
		setEntities(matches);
	};
	// wait for entities to load ...
	if(entities === null){
		return (
			<Grid container>
				<Grid item sm={12}>
					<p>Loading ...</p>
				</Grid>
			</Grid>
		);	
	}
	const onDialogClosed = () => {
		const copy = {...deleteDialog}; 
		copy.open = false; 
		setDeleteDialog(copy);
	};
	const onCreateBusinessClosed = () => {
		const copy = {open: false, entities: [] };
		setCreateDialog(copy);
	};
	const onBusinessCreated = () => {
		const copy = {open: false, entities: [] };
		setCreateDialog(copy);
	};
	const doExportBusinesses = () => {
		const s = async (response:any) => {
			const data = await response.text();
			const url = URL.createObjectURL(new Blob([data]));
			const link = document.getElementById("download");
			if(link === null){
				console.log("Failed to find download link ....");
				return;
			}
			if(link instanceof HTMLAnchorElement){
				link.href = url;
				link.click();
			}
		};
		const e = (error:any) => {
				console.log("Failed to fetch ...");
		};
		getBusinessExport(s, e);
	};
	const doCreateBusiness = () => {
		const copy = {open: false, entities: context.records };
		copy.open = true;
		setCreateDialog(copy);
	};
	const sortData = (sortState:any, headerName: string) => {
		const funs: any = {
			"Name": { "asc": (left:any, right:any) => { return left.name.localeCompare(right.name)}, "desc": (left:any, right:any) => { return right.name.localeCompare(left.name)} },
			"Linked Outlets": { "asc": (left:any, right:any) => { return left.outlets - right.outlets  }, "desc": (left:any , right:any) => { return right.outlets - left.outlets } } 	
		};
		const f: any = funs[headerName][sortState.mode];
		context.records.sort(f);	
		setEntities([...context.records]);
	};
	const onSortClicked = (event: any, headerName: string) => {
		const sorted: any = {...sort};
		const sortState = sorted[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			sortData(sortState, headerName);
			setSort(sorted);
			return;
		}
		const mode = sortState.mode === "asc" ? "desc" : "asc";
		sortState.mode = mode;
		sortData(sortState, headerName);
		setSort(sorted);
	};
	const onDeleteConfirmed = () => {
		const copy = {...deleteDialog}; 
		deleteBusiness(copy.id, () => {
			// on success of deletion
			// hide dialog ...
			copy.open = false; 
			setDeleteDialog(copy);
			// refetch businesses 
			setEntities(null);
		});
	};
  return (
		<Dialogs 
			createBusinessDialog={createDialog}
			onCreateBusinessClosed={onCreateBusinessClosed}
			onBusinessCreated={onBusinessCreated}
			onBusinessEntityCreated={()=>{onBusinessCreated();setEntities(null);}}
			onBusinessEntityUpdated={()=>{onBusinessCreated();setEntities(null);}}
			deleteDialog={deleteDialog} 
			onDialogClosed={onDialogClosed}
			onDeleteConfirmed={onDeleteConfirmed}>
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				position="relative"
				marginBottom="45px"
				marginTop="30px"
			>
				<Box position="absolute" left={`calc(50% - 400px)`}>
					<SearchBar
						label="Search businesses ..."
						query={query}
						handleClearQuery={clearQuery}
						handleSetQuery={updateQuery}
						doQuery={doQuery}
					/>
					<Button variant="contained" onClick={doCreateBusiness} sx={{marginTop:"20px", marginLeft:"15px"}} size="large">CREATE</Button>
					<Button variant="contained" onClick={doExportBusinesses} sx={{marginTop:"20px", marginLeft:"15px"}} size="large">EXPORT</Button>
				</Box>
			</Box>
			<Box sx={{width:"100%"}}>
				<DataTable rows={entities} columns={COLS} sort={sort} onRowClicked={()=>{}} onHeaderClicked={()=>{}} onSortClicked={onSortClicked} filters={[]}/>
			</Box>
			<a id="download" download={"piji-businesses.csv"} href="" style={{display:"none"}} />
		</Dialogs>
  );
}
