import {useEffect, useState} from "react"

export const useLgaCoverageError = (watch: any) => {
  
  const [lgaCoverageError, setSecondaryCoverageError] = useState(false)
  const lgaCoverageWatch = watch("lga_coverage")
  useEffect(() => {
      setSecondaryCoverageError(false)
  }, [lgaCoverageWatch])
  
  return lgaCoverageError
}
