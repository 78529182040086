import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom"; 
import { ReactWindowTable } from "../components/ReactWindowTable/ReactWindowTable";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { BusinessEntityLabels } from "../common/constants";
import Dialogs from "./../common/dialogs";
import {
  Box,
	Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Grid,
	Tabs,
	Tab
} from "@mui/material";
import { TabBody } from "../components/TabBody";
import { DataDisplay } from "../components/DataDisplay";
import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
import { OutletForm } from "./OutletForm";
import { getBusinessEntity, getBusinessOutlets, deleteBusinessOutlet, getBusinessOrganisations, unlinkOrganisationFromBusiness } from "../common/apiHelpers";
import { OrganisationTable } from "./OrganisationTable";
import { BusinessEntity, BusinessEntityOutlets, BusinessEntityOutlet, UpdateBusinessDialog, SearchContextType, LinkOutletDialog } from "../common/types";
import { SearchContext } from "./../common/searchContext";
import { SearchBar } from "../components/SearchBar";

export function BusinessPage() {
	const history: any = useHistory();
	const context: SearchContextType = useContext(SearchContext);
  const { enqueueSnackbar } = useSnackbar();
	const p: any = useParams();
	const entityId: string = p.entityId;
	const debug: boolean = false;
	const [entity, setEntity] = useState<BusinessEntity|null>(null);
	const [outlets, setOutlets] = useState<BusinessEntityOutlet[]|null>(null);
	const [organisations, setOrganisations] = useState<any[]|null>(null);
	const [tabIndex, setTabIndex] = useState<number>(0);
	const [linkOutletDialog, setLinkOutletDialog] =  useState<LinkOutletDialog|null>(null);
	const [linkOrganisationDialog, setLinkOrganisationDialog] = useState<any|null>(null);
	const [updateBusinessDialog, setUpdateBusinessDialog] = useState<UpdateBusinessDialog|null>(null);
	// loading business entity data
  const loadBusinessEntity = () => {
		if(entity !== null){
			return;
		}
		const s = (data: BusinessEntity) => {
			setEntity(data);
		};
		getBusinessEntity(entityId, s);
	};
	// loading outlet data ...
	const unlinkOutlet = (outletId: number) => {
		const s = (response: any) => {
			setOutlets(null);
		};
		deleteBusinessOutlet(entityId, outletId, s);
	};
	const loadOutlets = () => {
		// don't load if already loaded 
		if(outlets !== null){
			return;
		}
		const s = (data: BusinessEntityOutlets) => {
			let outlets = data.outlets;
			outlets = outlets.map((e)=>{ return {...e, actions: (
				<Box><Button color="secondary" onClick={()=>{unlinkOutlet(e.id);}} variant="contained">Unlink</Button></Box>
			)}});
			setOutlets(outlets);
		};
		getBusinessOutlets(entityId, s);
	};
	const loadOrganisations = () => {
		if(organisations !== null){
			return;
		}
		const s = (data: any) => {
			setOrganisations(data);
		};
		getBusinessOrganisations(entityId, s);
	};
	// effects
	useEffect(loadBusinessEntity);
	useEffect(loadOutlets);
	useEffect(loadOrganisations);
	// tab content functions
	const getBusinessDetailsContent = () => {
		if(entity === null){
			return <p>Loading ...</p>;
		}
		const details: Record<string, string> = {
			name: entity.entity_name,
			abn: entity.entity_abn,
			status: entity.entity_status,
			location: entity.entity_location,
			type: entity.entity_type, 
			commonName: entity.entity_common_name === undefined  || entity.entity_common_name === null ? "None" : entity.entity_common_name
		}; 
		return (
			<TabBody>
				<DataDisplay items={details} mapping={BusinessEntityLabels} />
			</TabBody>
		);
	};
	const getParentEntities = () => {
		const parentIds: number[] | undefined | null = entity !== null ? entity.entity_owned_by_entity_ids : [];
		const hasParents = parentIds !== undefined && parentIds !== null && parentIds.length > 0;
		if(!hasParents){
			return (
				<Box>
					<Typography variant="body1">Entity does not have any parent entities.</Typography>
				</Box>
			);
		}
		const COLS = [
			{ dataKey:"name", label:"Name" }
		];
		const data = parentIds.map((e)=>{const ent: any = context.records.find((x)=>{return x.id * 1 === e});return {name: ent.name }});
		return <ReactWindowTable data={data} columns={COLS} />;
	};
	const getChildEntities = () => {
		const childIds: number[] | undefined | null = entity !== null ? entity.entity_owns_entity_ids : [];
		const hasChildren = childIds !== undefined && childIds !== null && childIds.length > 0;
		if(!hasChildren){
			return (
				<Box>
					<Typography variant="body1">Entity does not have any child entities.</Typography>
				</Box>
			);
		}
		const COLS = [
			{ dataKey:"name", label:"Name" }
		];
		const data = childIds.map((e)=>{const ent: any = context.records.find((x)=>{return x.id * 1 === e});return {name: ent.name }});
		return (
			<Box sx={{height:"100%"}}>
				<ReactWindowTable data={data} columns={COLS} />
			</Box>);
	};
	const onOutletLinked = () => {
		const dialog: LinkOutletDialog  = { open: false, entityId: entityId };
		setLinkOutletDialog(dialog);
		setOutlets(null);
	};
	const onLinkOutletClosed = () => {
		const dialog: LinkOutletDialog  = { open: false, entityId: entityId };
		setLinkOutletDialog(dialog);
	};
	const onLinkOutletClicked = () => {
		const dialog: LinkOutletDialog  = { open: true, entityId: entityId };
		setLinkOutletDialog(dialog);
	};
	const onLinkOrganisationClicked = () => {
		setLinkOrganisationDialog({
    	open: true,
			entityId: entityId,
			entityType: "Business"
		});
	};
	const deleteOrganisationLinkage = (organisationId: number, entityId: string) => {
		const s = (response:any) => {
			enqueueSnackbar("Organisation unlinked", {variant:"success"});
			setOrganisations(null);
		};
		const e = (error:any) => {
			enqueueSnackbar("Failed to unlink organisation.", {variant:"error"});
		};
		unlinkOrganisationFromBusiness(organisationId, entityId, s, e)
	};
	const getOrganisationsContent = () => {
		if(organisations === null){
			return (
    		<Box sx={{textAlign:"center", paddingTop:"30px"}}>
					<span style={{backgroundColor:"#EFEFEF", padding:"30px", borderRadius:"5px", border:"1px solid #DEDEDE"}}>
						Loading organisations ...
					</span>
				</Box>
			);
		}
		const linkOrg = (
			<Button variant="contained" size="large" onClick={onLinkOrganisationClicked} startIcon={<AddIcon/>}>Link Organisation</Button>
		);
		if(organisations.length === 0){
			return (
    		<Box sx={{textAlign:"center", paddingTop:"30px"}}>
					<span style={{backgroundColor:"#EFEFEF", padding:"30px", borderRadius:"5px", border:"1px solid #DEDEDE"}}>
						No organisations are linked against this business, please click the following button to link one <span style={{marginLeft:"15px"}}>{linkOrg}</span>
					</span>
				</Box>
			);
		}
		return (
			<Box sx={{height:"100%"}}>
				<Box sx={{marginBottom:"15px"}}>
					{linkOrg}
				</Box>
				<Box sx={{ padding:"15px", height:"100%"}}>
					<OrganisationTable organisations={organisations} onDeleteClicked={(organisationId:number)=>{deleteOrganisationLinkage(organisationId, entityId);}} />
				</Box>
			</Box>
		);
	}
	const getOutletsContent = () => {
		if(outlets === null){
			return <p>Loading outlets ...</p>;
		}	
		const COLS = [
			{ dataKey: "name", label: "Name" }, 
			{ dataKey: "business", label: "Business" }, 
			{ dataKey: "state", label: "State" }, 
			{ dataKey: "primary_coverage", label: "Primary Coverage" },
			{ dataKey: "actions", label: "Actions" } 
		];
		return (
			<Box sx={{height:"100%"}}>
				<Box sx={{marginBottom:"15px"}}>
					<Button variant="contained" size="large" onClick={onLinkOutletClicked} startIcon={<AddIcon/>}>Link Outlet</Button>
				</Box>
				<Box sx={{ padding:"15px", height:"100%"}}>
					<ReactWindowTable data={outlets} columns={COLS} />
				</Box>
			</Box>
		);
	};
	const doUpdateBusinessClosed = () => {
		const copy = { open: false, id: entityId, entities: context.records };
		setUpdateBusinessDialog(copy);
	};
	const doBusinessUpdated = () => {
		// grab details of updated business 
		const copy = { open: false, id: entityId, entities: context.records };
		setUpdateBusinessDialog(copy);
		setEntity(null);
	}
	const getContent = () => {
		switch(tabIndex){
			case 0: 
				return getBusinessDetailsContent();
			case 1:
				return getChildEntities();
			case 2:
				return getParentEntities();
			case 3:
				return getOutletsContent();
      case 4:
        return getOrganisationsContent();
		}
	};
	// display some kind of loading hint when data doesn't exist
	// might need to handle this across tabs?
	if(entity === null){
		return (
			<Grid container>
				<Grid item lg={12}>
					<p>Loading ...</p>
				</Grid>
			</Grid>
		);
	}
	// dump entity data if debugging
	if(debug){
		return (
			<p>{JSON.stringify(entity)}</p>
		);
	}
  return (
		<Dialogs
			linkOutletDialog={linkOutletDialog}
			onOutletLinked={onOutletLinked}
			onLinkOutletClosed={onLinkOutletClosed}
			deleteDialog={false} 
			onDialogClosed={()=>{}}
			onDeleteConfirmed={()=>{}}
			updateBusinessDialog={updateBusinessDialog}
			onUpdateBusinessClosed={doUpdateBusinessClosed}
			onBusinessUpdated={doBusinessUpdated}
      linkOrganisationDialog={linkOrganisationDialog}
      onLinkOrganisationClosed={()=>{setLinkOrganisationDialog(null);}}
      onOrganisationLinked={()=>{setLinkOrganisationDialog(null);enqueueSnackbar("Organisation linked", {variant:"success"});setOrganisations(null);}}
			>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom:"20px"
				}}
			>
        <Button
          variant="text"
          onClick={() => {
            history.push("/businesses");
          }}
          startIcon={<ChevronLeftIcon />}
        >
          Back
        </Button>
			</Box>
			<Box>
				<Grid container>
					<Grid item sm={6}>
						<Typography variant="h4" sx={{marginBottom:"25px"}}>
							{entity.entity_name}
						</Typography>
					</Grid>
					<Grid item sm={6} sx={{textAlign:"left"}}>
						<Button
							variant="contained"
							onClick={() => {
								setUpdateBusinessDialog({
									open: true,
									id: entityId,
									entities: context.records
								});
							}}
							startIcon={<AddIcon />}>
							UPDATE
						</Button>
					</Grid>
				</Grid>
			</Box>
      <Tabs
        value={tabIndex}
        aria-label="Business details and associated oulets ..."
				sx={{marginBottom:"20px"}}
      >
        <Tab
          onClick={() => {
            setTabIndex(0);
          }}
          label="BUSINESS DETAILS"
          id="tab-business-details"
          aria-controls="tabpanel-business-details"
        />
				<Tab 
					onClick={()=>{setTabIndex(1);}}
					label="CHILD ENTITIES"
					id="tab-child-entities"
					aria-controls="tabpanel-child-entities"
					/>
				<Tab
					onClick={()=>{setTabIndex(2);}}
					label="PARENT ENTITIES"
					id="tab-parent-entities"
					aria-controls="tabpanel-parent-entities"/>
        <Tab
          onClick={() => {
            setTabIndex(3);
          }}
          label="OUTLETS"
          id="tab-business-outlets"
          aria-controls="tabpanel-business-outlets"
        />
        <Tab
          onClick={() => {
            setTabIndex(4);
          }}
          label="ORGANISATIONS"
          id="tab-organisations"
          aria-controls="tabpanel-organisations"
        />
			</Tabs>
			{getContent()}
		</Dialogs>
  );
}
