import React, { useEffect, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, TextField, Stack, Button, Chip, Typography } from "@mui/material";
import { getBroadcastAreas, linkBroadcastAreasToOutlet } from "./../common/apiHelpers";
import { BusinessEntity, BusinessEntityOutlet, OutletSearchContextType } from "./../common/types";
import { OutletSearchContext } from "./../common/outletSearchContext";
import { Dropdown } from "./../components/fields";

const LinkBroadcastAreaForm  = (props: any) => {
	const outletId = props.outletId;
	const [broadcastAreas, setBroadcastAreas] = useState<any[]|null>(null);
	const [broadcastAreaIds, setBroadcastAreaIds] = useState<number[]>([]);
	const [currentId, setCurrentId] = useState<number>(-1);
	const [error, setError] = useState<any|null>(null);
	const [query, setQuery] = useState<string>("");
	const outletSearch: OutletSearchContextType = useContext<OutletSearchContextType>(OutletSearchContext);
	// events
	const onSubmitClicked = () => {
		if(broadcastAreaIds.length === 0){
			return;
		}
		const payload = {
			broadcast_area_ids: broadcastAreaIds	
		};
		const s = async (response: any) => {
			const status = response.status;
			if(status === 400){
				const error = await response.json();
				setError(error);
				return;
			}
			props.onBroadcastAreaLinked();
		};
		const e = (error:any) => {
		};
		linkBroadcastAreasToOutlet(outletId, payload, s, e);
	};
	// effect handlers
	// load outlets
	// correlate to dropdown options
	const loadBroadcastAreas = () => {
		if(broadcastAreas !== null){
			return;
		}
		const e = (error:any) => {
			console.log("something went wrong");
		};
		const s = async (response: any) => {
			const data = await response.json();
			let options = data.map((e:any)=>{return {value: e.id, text: e.name}});
			options = [{value:-1, text:"Please select an option ..."}, ...options];
			outletSearch.records = options;
			setBroadcastAreas(options);
		};
		getBroadcastAreas(s, e);
	};	
	// effects
	useEffect(loadBroadcastAreas);
	// event handlers	
	const applySearch = () => { 
		const matches: any[] = outletSearch.records.filter((x)=>{ return x.text.toLowerCase().indexOf(query) !== -1});
		if(matches.length > 0){
			const first = matches[0];
			setCurrentId(first.value);	
		}	
		setBroadcastAreas(matches);
	};
	const unlinkBroadcastArea = (id: number) => {
		const copy = [...broadcastAreaIds];
		const i = copy.findIndex((x)=>{ return x === id});
		if(i === undefined){
			throw new Error(`Failed to find outlet for id ${id}`);
		}
		copy.splice(i, 1);	
		setBroadcastAreaIds(copy);
	};
	const linkBroadcastArea = (event: any) => {
		const copy = [...broadcastAreaIds];
		copy.push(currentId);
		setBroadcastAreaIds(copy);
		setCurrentId(-1);
	};
	if(broadcastAreas === null){
		return (
			<p>Loading ...</p>
		);	
	}
	let linkedBusinesses = (
		<Box sx={{textAlign:"center"}}>
			<Typography>Select broadcast areas from then following dropdown and click the plus button to link them.</Typography>
		</Box>
	);
	if(broadcastAreaIds.length > 0){
		linkedBusinesses = (
			<Box>
				{broadcastAreaIds.map((e:any,i:any)=>{ return <Chip key={e} label={broadcastAreas.find((x)=>{ return x.value === e}).text} color="primary" onDelete={()=>{unlinkBroadcastArea(e);}} />})}
			</Box>
		);
	}
	let errorDetail = null;
	if(error !== null){
		errorDetail = (
			<Box sx={{backgroundColor:"rgba(255,0,0,0.2)", border:"2px solid red", textAlign:"center", padding:"5px"}}>
				<Typography sx={{color:"red"}}>{error.detail}</Typography>
			</Box>
		);
	}
	return (
		<Grid container>
			<Grid item sm={12} sx={{padding:"10px"}}>
				<Stack spacing={3}>
					{errorDetail}
					<Box sx={{border:"1px solid #CDCDCD", padding:"15px"}}>
						{linkedBusinesses}
					</Box>
					<Box sx={{border:"1px solid #CDCDCD", padding:"15px"}}>
						<Grid container>
							<Grid item sm={12} sx={{marginBottom:"15px"}}>
								<TextField value={query} onBlur={applySearch} onChange={(event)=>{setQuery(event.target.value);}} helperText={"Search for a broadcast area by entering its name ..."} label={"Search Broadcast Areas"} fullWidth />
							</Grid>
							<Grid item sm={10}>
								<Dropdown 
									options={broadcastAreas} 
									onValueChanged={(event: any)=>{setCurrentId(event.target.value);}} 
									label="Broadcast Area" 
									value={currentId} 
									helperText={"Select a broadcast area to link to this outlet."} />
							</Grid>
							<Grid item sm={2}>
								<Button variant="contained" onClick={linkBroadcastArea} sx={{marginLeft:"10px", verticalAlign:"middle"}}><AddIcon/></Button>
							</Grid>
						</Grid>
					</Box>
					<Button variant={"contained"} fullWidth onClick={onSubmitClicked} size={"large"}>Submit</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default LinkBroadcastAreaForm;
