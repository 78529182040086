export const broadcastChoices = [
  { id: "adelaide tv1", name: "adelaide tv1" },
  { id: "ali curung ra1", name: "ali curung ra1" },
  { id: "regional queensland tv1", name: "regional queensland tv1" },
  { id: "amata ra1", name: "amata ra1" },
  { id: "northern new south wales tv1", name: "northern new south wales tv1" },
  { id: "southern new south wales tv1", name: "southern new south wales tv1" },
  { id: "regional victoria tv1", name: "regional victoria tv1" },
  { id: "aurukun ra1", name: "aurukun ra1" },
  { id: "badu island ra1", name: "badu island ra1" },
  { id: "badu island tv1", name: "badu island tv1" },
  { id: "barunga ra1", name: "barunga ra1" },
  { id: "bathurst island ra1", name: "bathurst island ra1" },
  { id: "western victoria tv1", name: "western victoria tv1" },
  { id: "beswick ra1", name: "beswick ra1" },
  { id: "beswick tv1", name: "beswick tv1" },
  { id: "broken hill tv1", name: "broken hill tv1" },
  { id: "boigu island ra1", name: "boigu island ra1" },
  { id: "boigu island tv1", name: "boigu island tv1" },
  { id: "brisbane tv1", name: "brisbane tv1" },
  { id: "bulman ra1", name: "bulman ra1" },
  { id: "coconut island ra1", name: "coconut island ra1" },
  { id: "coconut island tv1", name: "coconut island tv1" },
  { id: "daguragu ra1", name: "daguragu ra1" },
  { id: "darnley island ra1", name: "darnley island ra1" },
  { id: "darnley island tv1", name: "darnley island tv1" },
  { id: "dauan island ra1", name: "dauan island ra1" },
  { id: "dauan island tv1", name: "dauan island tv1" },
  { id: "doomadgee ra1", name: "doomadgee ra1" },
  { id: "doomadgee tv1", name: "doomadgee tv1" },
  { id: "ernabella ra1", name: "ernabella ra1" },
  { id: "finke ra1", name: "finke ra1" },
  { id: "finke tv1", name: "finke tv1" },
  { id: "fregon ra1", name: "fregon ra1" },
  { id: "galiwinku ra1", name: "galiwinku ra1" },
  { id: "eastern victoria tv1", name: "eastern victoria tv1" },
  { id: "spencer gulf tv1", name: "spencer gulf tv1" },
  { id: "geraldton tv1", name: "geraldton tv1" },
  { id: "gununa ra1", name: "gununa ra1" },
  { id: "gununa tv1", name: "gununa tv1" },
  { id: "hermannsburg ra1", name: "hermannsburg ra1" },
  { id: "hope vale ra1", name: "hope vale ra1" },
  { id: "imanpa ra1", name: "imanpa ra1" },
  { id: "imanpa tv1", name: "imanpa tv1" },
  { id: "indulkana ra1", name: "indulkana ra1" },
  { id: "injinoo ra1", name: "injinoo ra1" },
  { id: "injinoo tv1", name: "injinoo tv1" },
  { id: "mt isa tv1", name: "mt isa tv1" },
  { id: "jigalong ra1", name: "jigalong ra1" },
  { id: "kintore ra1", name: "kintore ra1" },
  { id: "kowanyama ra1", name: "kowanyama ra1" },
  { id: "kowanyama tv1", name: "kowanyama tv1" },
  { id: "kubin ra1", name: "kubin ra1" },
  { id: "kubin tv1", name: "kubin tv1" },
  { id: "kununurra ra1", name: "kununurra ra1" },
  { id: "lajamanu ra1", name: "lajamanu ra1" },
  { id: "lockhart river ra1", name: "lockhart river ra1" },
  { id: "mabuiag island ra1", name: "mabuiag island ra1" },
  { id: "mabuiag island tv1", name: "mabuiag island tv1" },
  { id: "maningrida ra1", name: "maningrida ra1" },
  { id: "melbourne tv1", name: "melbourne tv1" },
  { id: "milikapiti ra1", name: "milikapiti ra1" },
  { id: "milingimbi ra1", name: "milingimbi ra1" },
  { id: "minjilang ra1", name: "minjilang ra1" },
  { id: "griffith and mia tv1", name: "griffith and mia tv1" },
  { id: "murray island ra1", name: "murray island ra1" },
  { id: "murray island tv1", name: "murray island tv1" },
  { id: "newman ra1", name: "newman ra1" },
  { id: "ngukurr ra1", name: "ngukurr ra1" },
  { id: "nturiya ra1", name: "nturiya ra1" },
  { id: "nturiya tv1", name: "nturiya tv1" },
  { id: "numbulwar ra1", name: "numbulwar ra1" },
  { id: "oenpelli ra1", name: "oenpelli ra1" },
  { id: "palumpa ra1", name: "palumpa ra1" },
  { id: "palumpa tv1", name: "palumpa tv1" },
  { id: "papunya ra1", name: "papunya ra1" },
  { id: "peppimenarti ra1", name: "peppimenarti ra1" },
  { id: "perth tv1", name: "perth tv1" },
  { id: "pmara jutunta ra1", name: "pmara jutunta ra1" },
  { id: "pmara jutunta tv1", name: "pmara jutunta tv1" },
  { id: "pormpuraaw ra1", name: "pormpuraaw ra1" },
  { id: "pormpuraaw tv1", name: "pormpuraaw tv1" },
  { id: "pularumpi ra1", name: "pularumpi ra1" },
  { id: "ramingining ra1", name: "ramingining ra1" },
  { id: "riverland tv1", name: "riverland tv1" },
  { id: "saibai island ra1", name: "saibai island ra1" },
  { id: "saibai island tv1", name: "saibai island tv1" },
  { id: "seisia ra1", name: "seisia ra1" },
  { id: "seisia tv1", name: "seisia tv1" },
  {
    id: "south west and great southern tv1",
    name: "south west and great southern tv1"
  },
  { id: "st pauls ra1", name: "st pauls ra1" },
  { id: "st pauls tv1", name: "st pauls tv1" },
  { id: "stephens island ra1", name: "stephens island ra1" },
  { id: "stephens island tv1", name: "stephens island tv1" },
  { id: "mildura/sunraysia tv1", name: "mildura/sunraysia tv1" },
  { id: "sue island ra1", name: "sue island ra1" },
  { id: "sue island tv1", name: "sue island tv1" },
  { id: "sydney tv1", name: "sydney tv1" },
  { id: "tasmania tv1", name: "tasmania tv1" },
  { id: "umbakumba ra1", name: "umbakumba ra1" },
  { id: "kalgoorlie tv1", name: "kalgoorlie tv1" },
  { id: "wadeye ra1", name: "wadeye ra1" },
  { id: "warruwi ra1", name: "warruwi ra1" },
  { id: "willowra ra1", name: "willowra ra1" },
  { id: "willowra tv1", name: "willowra tv1" },
  { id: "woorabinda ra1", name: "woorabinda ra1" },
  { id: "wujal wujal ra1", name: "wujal wujal ra1" },
  { id: "yam island ra1", name: "yam island ra1" },
  { id: "yam island tv1", name: "yam island tv1" },
  { id: "yirrkala ra1", name: "yirrkala ra1" },
  { id: "yorke islands ra1", name: "yorke islands ra1" },
  { id: "yorke islands tv1", name: "yorke islands tv1" },
  { id: "yuelamu ra1", name: "yuelamu ra1" },
  { id: "yuelamu tv1", name: "yuelamu tv1" },
  { id: "yuendumu ra1", name: "yuendumu ra1" },
  { id: "whyalla ra1", name: "whyalla ra1" },
  { id: "parramatta ra1", name: "parramatta ra1" },
  { id: "rockhampton ra2", name: "rockhampton ra2" },
  { id: "salisbury ra1", name: "salisbury ra1" },
  { id: "forster ra1", name: "forster ra1" },
  { id: "bowral ra1", name: "bowral ra1" },
  { id: "ballarat ra2", name: "ballarat ra2" },
  { id: "shepparton ra2", name: "shepparton ra2" },
  { id: "moruya ra1", name: "moruya ra1" },
  { id: "taree ra2", name: "taree ra2" },
  { id: "tenterfield ra1", name: "tenterfield ra1" },
  { id: "cairns ra2", name: "cairns ra2" },
  { id: "leongatha ra1", name: "leongatha ra1" },
  { id: "melbourne west ra1", name: "melbourne west ra1" },
  { id: "logan ra1", name: "logan ra1" },
  { id: "mornington ra1", name: "mornington ra1" },
  { id: "hay ra1", name: "hay ra1" },
  { id: "caboolture ra1", name: "caboolture ra1" },
  { id: "maclean ra1", name: "maclean ra1" },
  { id: "nowra ra2", name: "nowra ra2" },
  { id: "armidale ra1", name: "armidale ra1" },
  { id: "albury ra1", name: "albury ra1" },
  { id: "bathurst ra1", name: "bathurst ra1" },
  { id: "coffs harbour ra1", name: "coffs harbour ra1" },
  { id: "dubbo ra1", name: "dubbo ra1" },
  { id: "bega ra1", name: "bega ra1" },
  { id: "grafton ra1", name: "grafton ra1" },
  { id: "goulburn ra1", name: "goulburn ra1" },
  { id: "orange ra1", name: "orange ra1" },
  { id: "young ra1", name: "young ra1" },
  { id: "lismore ra1", name: "lismore ra1" },
  { id: "lithgow ra1", name: "lithgow ra1" },
  { id: "kempsey ra1", name: "kempsey ra1" },
  { id: "mudgee ra1", name: "mudgee ra1" },
  { id: "gunnedah ra1", name: "gunnedah ra1" },
  { id: "murwillumbah ra1", name: "murwillumbah ra1" },
  { id: "muswellbrook ra1", name: "muswellbrook ra1" },
  { id: "inverell ra1", name: "inverell ra1" },
  { id: "katoomba ra1", name: "katoomba ra1" },
  { id: "parkes ra1", name: "parkes ra1" },
  { id: "deniliquin ra1", name: "deniliquin ra1" },
  { id: "taree ra1", name: "taree ra1" },
  { id: "griffith ra1", name: "griffith ra1" },
  { id: "tamworth ra1", name: "tamworth ra1" },
  { id: "western suburbs sydney ra1", name: "western suburbs sydney ra1" },
  { id: "wagga wagga ra1", name: "wagga wagga ra1" },
  { id: "cooma ra1", name: "cooma ra1" },
  { id: "wagga wagga ra2", name: "wagga wagga ra2" },
  { id: "armidale ra2", name: "armidale ra2" },
  { id: "bellingen ra1", name: "bellingen ra1" },
  { id: "bankstown ra1", name: "bankstown ra1" },
  { id: "coffs harbour ra2", name: "coffs harbour ra2" },
  { id: "liverpool ra1", name: "liverpool ra1" },
  { id: "manly ra1", name: "manly ra1" },
  { id: "narwee ra1", name: "narwee ra1" },
  { id: "lismore ra2", name: "lismore ra2" },
  { id: "chatswood ra1", name: "chatswood ra1" },
  { id: "newcastle ra2", name: "newcastle ra2" },
  { id: "burwood ra1", name: "burwood ra1" },
  { id: "albury ra2", name: "albury ra2" },
  { id: "waverley ra1", name: "waverley ra1" },
  { id: "ryde ra1", name: "ryde ra1" },
  { id: "sydney ra2", name: "sydney ra2" },
  { id: "windsor ra1", name: "windsor ra1" },
  { id: "tamworth ra2", name: "tamworth ra2" },
  { id: "bendigo ra2", name: "bendigo ra2" },
  { id: "latrobe valley ra1", name: "latrobe valley ra1" },
  { id: "murrayville ra1", name: "murrayville ra1" },
  { id: "portland ra1", name: "portland ra1" },
  { id: "toowoomba ra2", name: "toowoomba ra2" },
  { id: "townsville ra2", name: "townsville ra2" },
  { id: "mount gambier ra2", name: "mount gambier ra2" },
  { id: "hobart ra2", name: "hobart ra2" },
  { id: "alice springs ra2", name: "alice springs ra2" },
  { id: "bathurst ra2", name: "bathurst ra2" },
  { id: "fremantle ra1", name: "fremantle ra1" },
  { id: "roma ra2", name: "roma ra2" },
  { id: "wynyard ra1", name: "wynyard ra1" },
  { id: "melbourne east ra1", name: "melbourne east ra1" },
  { id: "melbourne north east ra1", name: "melbourne north east ra1" },
  { id: "launceston ra2", name: "launceston ra2" },
  { id: "ballarat ra1", name: "ballarat ra1" },
  { id: "geelong ra1", name: "geelong ra1" },
  { id: "bendigo ra1", name: "bendigo ra1" },
  { id: "maryborough (vic) ra1", name: "maryborough (vic) ra1" },
  { id: "warragul ra1", name: "warragul ra1" },
  { id: "hamilton ra1", name: "hamilton ra1" },
  { id: "mildura ra2", name: "mildura ra2" },
  { id: "mildura ra1", name: "mildura ra1" },
  { id: "wangaratta ra1", name: "wangaratta ra1" },
  { id: "swan hill ra1", name: "swan hill ra1" },
  { id: "sale ra1", name: "sale ra1" },
  { id: "horsham ra1", name: "horsham ra1" },
  { id: "warrnambool ra1", name: "warrnambool ra1" },
  { id: "canberra ra2", name: "canberra ra2" },
  { id: "bourke ra1", name: "bourke ra1" },
  { id: "atherton ra1", name: "atherton ra1" },
  { id: "bundaberg ra1", name: "bundaberg ra1" },
  { id: "gladstone ra1", name: "gladstone ra1" },
  { id: "charters towers ra1", name: "charters towers ra1" },
  { id: "gympie ra1", name: "gympie ra1" },
  { id: "emerald ra1", name: "emerald ra1" },
  { id: "innisfail ra1", name: "innisfail ra1" },
  { id: "longreach ra1", name: "longreach ra1" },
  { id: "mt isa ra1", name: "mt isa ra1" },
  { id: "maryborough (qld) ra1", name: "maryborough (qld) ra1" },
  { id: "ipswich ra1", name: "ipswich ra1" },
  { id: "rockhampton ra1", name: "rockhampton ra1" },
  { id: "kingaroy ra1", name: "kingaroy ra1" },
  { id: "nambour ra1", name: "nambour ra1" },
  {
    id: "remote commercial radio service north east zone ra1",
    name: "remote commercial radio service north east zone ra1"
  },
  { id: "charleville ra1", name: "charleville ra1" },
  { id: "roma ra1", name: "roma ra1" },
  { id: "geelong ra2", name: "geelong ra2" },
  { id: "katherine ra1", name: "katherine ra1" },
  { id: "warrnambool ra2", name: "warrnambool ra2" },
  { id: "plenty valley ra1", name: "plenty valley ra1" },
  { id: "melbourne south east ra1", name: "melbourne south east ra1" },
  { id: "port lincoln ra1", name: "port lincoln ra1" },
  { id: "murray bridge ra1", name: "murray bridge ra1" },
  { id: "riverland ra1", name: "riverland ra1" },
  { id: "woomera ra1", name: "woomera ra1" },
  { id: "mt gambier ra1", name: "mt gambier ra1" },
  { id: "northam ra1", name: "northam ra1" },
  { id: "bridgetown ra1", name: "bridgetown ra1" },
  {
    id: "remote commercial radio service western zone ra1",
    name: "remote commercial radio service western zone ra1"
  },
  { id: "karratha ra1", name: "karratha ra1" },
  { id: "kalgoorlie ra1", name: "kalgoorlie ra1" },
  { id: "carnarvon ra1", name: "carnarvon ra1" },
  { id: "merredin ra1", name: "merredin ra1" },
  { id: "mandurah ra1", name: "mandurah ra1" },
  { id: "narrogin ra1", name: "narrogin ra1" },
  { id: "port hedland ra1", name: "port hedland ra1" },
  { id: "esperance ra1", name: "esperance ra1" },
  { id: "bunbury ra1", name: "bunbury ra1" },
  { id: "albany ra1", name: "albany ra1" },
  { id: "katanning ra1", name: "katanning ra1" },
  { id: "devonport ra1", name: "devonport ra1" },
  { id: "burnie ra1", name: "burnie ra1" },
  { id: "scottsdale ra1", name: "scottsdale ra1" },
  { id: "queenstown ra1", name: "queenstown ra1" },
  { id: "alice springs ra1", name: "alice springs ra1" },
  { id: "darwin ra1", name: "darwin ra1" },
  { id: "central zone ra2", name: "central zone ra2" },
  {
    id: "remote commercial radio service central zone ra1",
    name: "remote commercial radio service central zone ra1"
  },
  { id: "darwin ra2", name: "darwin ra2" },
  { id: "darwin ra3", name: "darwin ra3" },
  { id: "adelaide ra1", name: "adelaide ra1" },
  { id: "albany ra2", name: "albany ra2" },
  { id: "broken hill ra2", name: "broken hill ra2" },
  { id: "broken hill ra1", name: "broken hill ra1" },
  { id: "bridgetown ra2", name: "bridgetown ra2" },
  { id: "brisbane ra1", name: "brisbane ra1" },
  { id: "broome ra1", name: "broome ra1" },
  { id: "bruce rock ra1", name: "bruce rock ra1" },
  { id: "cairns ra1", name: "cairns ra1" },
  { id: "canberra ra1", name: "canberra ra1" },
  { id: "ceduna ra2", name: "ceduna ra2" },
  { id: "cobar ra2", name: "cobar ra2" },
  { id: "cunnamulla ra1", name: "cunnamulla ra1" },
  { id: "derby ra1", name: "derby ra1" },
  { id: "esperance ra2", name: "esperance ra2" },
  { id: "fitzroy crossing ra1", name: "fitzroy crossing ra1" },
  { id: "gold coast ra1", name: "gold coast ra1" },
  { id: "geraldton ra2", name: "geraldton ra2" },
  { id: "gosford ra1", name: "gosford ra1" },
  { id: "geraldton ra1", name: "geraldton ra1" },
  { id: "halls creek ra1", name: "halls creek ra1" },
  { id: "hobart ra1", name: "hobart ra1" },
  { id: "kalgoorlie ra2", name: "kalgoorlie ra2" },
  { id: "kalgoorlie ra3", name: "kalgoorlie ra3" },
  { id: "kangaroo island ra1", name: "kangaroo island ra1" },
  { id: "katanning ra2", name: "katanning ra2" },
  { id: "launceston ra1", name: "launceston ra1" },
  { id: "lord howe ra1", name: "lord howe ra1" },
  { id: "mackay ra1", name: "mackay ra1" },
  { id: "mandurah ra2", name: "mandurah ra2" },
  { id: "meekatharra ra1", name: "meekatharra ra1" },
  { id: "melbourne ra1", name: "melbourne ra1" },
  { id: "moree ra1", name: "moree ra1" },
  { id: "narrogin ra2", name: "narrogin ra2" },
  { id: "newcastle ra1", name: "newcastle ra1" },
  { id: "northampton ra1", name: "northampton ra1" },
  { id: "nowra ra1", name: "nowra ra1" },
  { id: "northam ra2", name: "northam ra2" },
  { id: "perth ra1", name: "perth ra1" },
  { id: "mount gambier/south east tv1", name: "mount gambier/south east tv1" },
  { id: "shepparton ra1", name: "shepparton ra1" },
  { id: "sydney ra1", name: "sydney ra1" },
  { id: "torres strait ra1", name: "torres strait ra1" },
  { id: "townsville ra1", name: "townsville ra1" },
  { id: "wagin ra1", name: "wagin ra1" },
  { id: "wollongong ra1", name: "wollongong ra1" },
  { id: "york ra1", name: "york ra1" },
  { id: "bunbury ra2", name: "bunbury ra2" },
  { id: "jigalong tv1", name: "jigalong tv1" },
  { id: "wingellina tv1", name: "wingellina tv1" },
  { id: "western zone tv1", name: "western zone tv1" },
  { id: "omeo ra1", name: "omeo ra1" },
  { id: "mt beauty ra1", name: "mt beauty ra1" },
  { id: "bowen ra1", name: "bowen ra1" },
  { id: "mansfield ra1", name: "mansfield ra1" },
  { id: "deniliquin ra2", name: "deniliquin ra2" },
  { id: "wangaratta ra2", name: "wangaratta ra2" },
  { id: "mackay ra2", name: "mackay ra2" },
  { id: "townsville ra3", name: "townsville ra3" },
  { id: "echuca ra1", name: "echuca ra1" },
  { id: "bendigo ra3", name: "bendigo ra3" },
  { id: "kyneton ra1", name: "kyneton ra1" },
  { id: "swan hill ra2", name: "swan hill ra2" },
  { id: "barham ra1", name: "barham ra1" },
  { id: "mildura ra3", name: "mildura ra3" },
  { id: "yarra valley ra1", name: "yarra valley ra1" },
  { id: "yeppoon ra1", name: "yeppoon ra1" },
  { id: "hervey bay ra1", name: "hervey bay ra1" },
  { id: "grafton ra2", name: "grafton ra2" },
  { id: "kempsey ra2", name: "kempsey ra2" },
  { id: "nambucca heads ra1", name: "nambucca heads ra1" },
  { id: "port macquarie ra1", name: "port macquarie ra1" },
  { id: "palm island ra1", name: "palm island ra1" },
  { id: "toowoomba/warwick ra1", name: "toowoomba/warwick ra1" },
  { id: "port stephens ra1", name: "port stephens ra1" },
  { id: "lake macquarie ra1", name: "lake macquarie ra1" },
  { id: "muswellbrook ra2", name: "muswellbrook ra2" },
  { id: "cherbourg ra1", name: "cherbourg ra1" },
  { id: "dalby ra1", name: "dalby ra1" },
  { id: "wondai ra1", name: "wondai ra1" },
  { id: "warwick ra2", name: "warwick ra2" },
  { id: "lithgow ra2", name: "lithgow ra2" },
  { id: "mudgee ra2", name: "mudgee ra2" },
  { id: "kandos ra1", name: "kandos ra1" },
  { id: "gayndah ra1", name: "gayndah ra1" },
  { id: "moree ra2", name: "moree ra2" },
  { id: "inverell ra2", name: "inverell ra2" },
  { id: "mareeba ra1", name: "mareeba ra1" },
  { id: "katoomba ra2", name: "katoomba ra2" },
  { id: "gunnedah ra2", name: "gunnedah ra2" },
  { id: "nambour ra2", name: "nambour ra2" },
  { id: "noosa ra1", name: "noosa ra1" },
  { id: "gympie ra2", name: "gympie ra2" },
  { id: "narrabri ra1", name: "narrabri ra1" },
  { id: "deepwater ra1", name: "deepwater ra1" },
  { id: "dubbo ra2", name: "dubbo ra2" },
  { id: "yass ra1", name: "yass ra1" },
  { id: "cooma ra2", name: "cooma ra2" },
  { id: "penrith ra1", name: "penrith ra1" },
  { id: "blacktown ra1", name: "blacktown ra1" },
  { id: "hornsby ra1", name: "hornsby ra1" },
  { id: "mallacoota ra1", name: "mallacoota ra1" },
  { id: "bega ra2", name: "bega ra2" },
  { id: "queanbeyan ra1", name: "queanbeyan ra1" },
  { id: "ulladulla ra1", name: "ulladulla ra1" },
  { id: "beaudesert ra1", name: "beaudesert ra1" },
  { id: "sutherland ra1", name: "sutherland ra1" },
  { id: "mountain districts ra1", name: "mountain districts ra1" },
  { id: "melbourne south ra1", name: "melbourne south ra1" },
  { id: "camberwell ra1", name: "camberwell ra1" },
  { id: "werribee ra1", name: "werribee ra1" },
  { id: "campbelltown ra1", name: "campbelltown ra1" },
  { id: "mount isa ra2", name: "mount isa ra2" },
  { id: "bourke ra2", name: "bourke ra2" },
  { id: "nhulunbuy ra1", name: "nhulunbuy ra1" },
  { id: "borroloola ra1", name: "borroloola ra1" },
  { id: "adelaide sw ra1", name: "adelaide sw ra1" },
  { id: "collie ra1", name: "collie ra1" },
  { id: "margaret river ra1", name: "margaret river ra1" },
  { id: "lightning ridge ra1", name: "lightning ridge ra1" },
  { id: "griffith ra2", name: "griffith ra2" },
  { id: "millicent ra1", name: "millicent ra1" },
  { id: "bathurst ra3", name: "bathurst ra3" },
  { id: "kalamunda ra1", name: "kalamunda ra1" },
  { id: "armadale ra1", name: "armadale ra1" },
  { id: "taree ra3", name: "taree ra3" },
  { id: "cessnock/maitland ra1", name: "cessnock/maitland ra1" },
  { id: "cocos islands ra1", name: "cocos islands ra1" },
  { id: "port hedland ra2", name: "port hedland ra2" },
  { id: "christmas island ra1", name: "christmas island ra1" },
  {
    id: "remote and regional western australia tv1",
    name: "remote and regional western australia tv1"
  },
  { id: "australia wide s40", name: "australia wide s40" },
  { id: "victor harbor ra1", name: "victor harbor ra1" },
  { id: "albany s40", name: "albany s40" },
  { id: "kalgoorlie s40", name: "kalgoorlie s40" },
  { id: "colac ra1", name: "colac ra1" },
  { id: "wollongong ra2", name: "wollongong ra2" },
  { id: "tuggeranong ra1", name: "tuggeranong ra1" },
  { id: "melbourne north west ra1", name: "melbourne north west ra1" },
  { id: "sunbury ra1", name: "sunbury ra1" },
  { id: "bacchus marsh ra1", name: "bacchus marsh ra1" },
  { id: "melbourne city ra1", name: "melbourne city ra1" },
  { id: "waverley vic ra1", name: "waverley vic ra1" },
  { id: "wynnum ra1", name: "wynnum ra1" },
  { id: "redcliffe ra1", name: "redcliffe ra1" },
  { id: "alexandra/eildon ra1", name: "alexandra/eildon ra1" },
  { id: "coober pedy ra1", name: "coober pedy ra1" },
  { id: "nimbin ra1", name: "nimbin ra1" },
  { id: "melton ra1", name: "melton ra1" },
  { id: "moranbah ra1", name: "moranbah ra1" },
  { id: "cowra ra1", name: "cowra ra1" },
  { id: "murwillumbah ra2", name: "murwillumbah ra2" },
  { id: "queensland s40", name: "queensland s40" },
  { id: "casino ra1", name: "casino ra1" },
  { id: "coraki ra1", name: "coraki ra1" },
  { id: "byron bay ra1", name: "byron bay ra1" },
  { id: "tasman peninsula ra1", name: "tasman peninsula ra1" },
  { id: "blackwater ra1", name: "blackwater ra1" },
  { id: "yorke peninsula ra1", name: "yorke peninsula ra1" },
  { id: "darwin tv1", name: "darwin tv1" },
  {
    id: "remote central & eastern australia tv1",
    name: "remote central & eastern australia tv1"
  },
  {
    id: "remote central & eastern australia tv2",
    name: "remote central & eastern australia tv2"
  },
  { id: "port adelaide ra1", name: "port adelaide ra1" },
  { id: "barossa valley ra1", name: "barossa valley ra1" },
  { id: "adelaide foothills ra1", name: "adelaide foothills ra1" },
  { id: "east gippsland ra1", name: "east gippsland ra1" },
  { id: "esk ra1", name: "esk ra1" },
  { id: "boonah ra1", name: "boonah ra1" },
  { id: "ballina ra1", name: "ballina ra1" },
  { id: "young ra2", name: "young ra2" },
  { id: "warragul ra2", name: "warragul ra2" },
  { id: "latrobe valley ra2", name: "latrobe valley ra2" },
  { id: "horsham ra2", name: "horsham ra2" },
  { id: "port pirie ra1", name: "port pirie ra1" },
  { id: "victor harbor ra2", name: "victor harbor ra2" },
  { id: "bordertown ra1", name: "bordertown ra1" },
  { id: "hobart ra3", name: "hobart ra3" },
  { id: "george town ra1", name: "george town ra1" },
  { id: "northern midlands ra1", name: "northern midlands ra1" },
  { id: "break oday ra1", name: "break oday ra1" },
  { id: "huon valley ra1", name: "huon valley ra1" },
  { id: "cootamundra ra1", name: "cootamundra ra1" },
  { id: "spencer gulf north ra1", name: "spencer gulf north ra1" },
  { id: "narrandera ra1", name: "narrandera ra1" },
  { id: "hobart ra4", name: "hobart ra4" },
  { id: "qld & nt s40", name: "qld & nt s40" },
  { id: "emerald s40", name: "emerald s40" },
  { id: "dunedoo ra1", name: "dunedoo ra1" },
  { id: "sanctuary point ra1", name: "sanctuary point ra1" },
  { id: "emerald ra2", name: "emerald ra2" },
  { id: "victoria community ra1", name: "victoria community ra1" },
  { id: "oatlands ra1", name: "oatlands ra1" },
  { id: "tiaro ra1", name: "tiaro ra1" },
  { id: "braidwood ra1", name: "braidwood ra1" },
  { id: "eden ra1", name: "eden ra1" },
  { id: "arnhem land ra1", name: "arnhem land ra1" },
  { id: "mossman ra1", name: "mossman ra1" },
  { id: "weipa ra1", name: "weipa ra1" },
  { id: "roxby downs ra1", name: "roxby downs ra1" },
  { id: "yarraman ra1", name: "yarraman ra1" },
  { id: "charleville ra2", name: "charleville ra2" },
  { id: "goolwa ra1", name: "goolwa ra1" },
  { id: "cairns ra3", name: "cairns ra3" },
  { id: "bundaberg ra2", name: "bundaberg ra2" },
  { id: "coffs harbour ra3", name: "coffs harbour ra3" },
  { id: "perth ra2", name: "perth ra2" },
  { id: "bendigo ra4", name: "bendigo ra4" },
  { id: "lismore tv1", name: "lismore tv1" },
  { id: "wanneroo ra1", name: "wanneroo ra1" },
  { id: "tumut ra1", name: "tumut ra1" },
  { id: "coonabarabran ra1", name: "coonabarabran ra1" },
  { id: "gilgandra ra1", name: "gilgandra ra1" },
  { id: "coonamble ra1", name: "coonamble ra1" },
  { id: "wodonga ra1", name: "wodonga ra1" },
  { id: "upper murray ra1", name: "upper murray ra1" },
  { id: "wagga wagga ra3", name: "wagga wagga ra3" },
  { id: "mudgee ra3", name: "mudgee ra3" },
  { id: "nsw community ra1", name: "nsw community ra1" },
  { id: "port augusta ra1", name: "port augusta ra1" },
  { id: "maryborough ra2", name: "maryborough ra2" },
  { id: "wilcannia ra1", name: "wilcannia ra1" },
  { id: "castlemaine ra1", name: "castlemaine ra1" },
  { id: "augusta ra1", name: "augusta ra1" },
  { id: "gin gin ra1", name: "gin gin ra1" },
  { id: "bendigo ra5", name: "bendigo ra5" },
  { id: "seymour ra1", name: "seymour ra1" },
  { id: "billiluna ra1", name: "billiluna ra1" },
  { id: "one arm point (ardyaloon)", name: "one arm point (ardyaloon)" },
  { id: "harvey ra1", name: "harvey ra1" },
  { id: "wellington nsw ra1", name: "wellington nsw ra1" },
  { id: "temora ra1", name: "temora ra1" },
  { id: "goulburn ra2", name: "goulburn ra2" },
  { id: "melbourne s40", name: "melbourne s40" }
];
