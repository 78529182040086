import React, { useState } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { SnackbarProvider } from "notistack";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { styled } from "@mui/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { QueryClient, QueryClientProvider } from "react-query";

import { Header } from "./components/Header";
import { MainNavigation } from "./components/MainNavigation";

import { Businesses } from "./pages/Businesses";
import { OutletChanges } from "./pages/OutletChanges";
import CodesOfPractice from "./pages/CodesOfPractice";
import ViewCodeOfPractice from "./pages/ViewCodeOfPractice";
import { Organisations } from "./pages/Organisations";
import { Outlets } from "./pages/Outlets";
import Contacts from "./pages/Contacts";
import { Outlet } from "./pages/Outlet";
import { BusinessPage } from "./pages/BusinessPage";
import { ChangeRequestPage } from "./pages/ChangeRequestPage";
import { ContentBody } from "./components/ContentBody";
import { Login } from "./pages/Login";
import { NewPassword } from "./pages/NewPassword";

import user from "./common/User";

const StyledBody = styled(Box)(() => ({
  height: "100vh",
  gap: "0 2rem",
  display: "grid",
  overflow: "hidden",
  gridTemplateColumns: "min-content auto",
  gridTemplateRows: "min-content auto",
  gridTemplateAreas: `
    "header header"
    "nav body"
  `
}));

function Body({ header, nav, routes }: any) {
  return (
    <StyledBody>
      <Box sx={{ gridArea: "header" }}>{header}</Box>
      <Box sx={{ gridArea: "nav" }}>{nav}</Box>
      <Box sx={{ gridArea: "body", overflow: "auto" }}>{routes}</Box>
    </StyledBody>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!user.currentUser);

  const handleSignout = () => {
    setIsLoggedIn(false);
  };

  const handleLoginSubmit = () => {
    setIsLoggedIn(true);
  };

  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <CssBaseline />
            {!isLoggedIn ? (
              <Switch>
                <Route path="/login/change-password">
                  <NewPassword successCallback={handleLoginSubmit} />
                </Route>
                <Route exact path="/login">
                  <Login successCallback={handleLoginSubmit} />
                </Route>
                <Redirect to="/login" />
              </Switch>
            ) : (
              <Body
                header={
                  <Header toggleSignOut={handleSignout} showTitle={true} />
                }
                nav={<MainNavigation />}
                routes={
                  <Switch>
		    						<Route exact path="/businesses">
											<ContentBody>
												<Businesses />
											</ContentBody>
		    						</Route>
		    						<Route exact path="/organisations">
											<ContentBody>
												<Organisations />
											</ContentBody>
		    						</Route>
										<Route exact path="/businesses/:entityId">
											<ContentBody>
												<BusinessPage />
											</ContentBody>
										</Route>
                    <Route exact path="/outlets">
                      <ContentBody>
                        <Outlets />
                      </ContentBody>
                    </Route>
                    <Route exact path="/changes">
                      <ContentBody>
                        <OutletChanges />
                      </ContentBody>
                    </Route>
                    <Route path="/outlets/:outletId">
                      <ContentBody>
                        <Outlet />
                      </ContentBody>
                    </Route>
                    <Route path="/contacts">
                      <ContentBody>
                        <Contacts />
                      </ContentBody>
                    </Route>
										<Route exact path="/code-of-practice">
											<ContentBody>
												<CodesOfPractice/>
											</ContentBody>
										</Route>
										<Route path="/code-of-practice/:copId">
											<ContentBody>
												<ViewCodeOfPractice />
											</ContentBody>
										</Route>
                    <Route path="/change-requests">
                      <ContentBody>
                        <ChangeRequestPage />
                      </ContentBody>
                    </Route>
                    <Redirect to="/outlets" />
                  </Switch>
                }
              />
            )}
          </BrowserRouter>
        </QueryClientProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}
export default App;
