import react, { FC } from "react";
import { InputAdornment, IconButton, TextField, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

type SearchBarProps = {
  label: string;
  query: string;
  handleSetQuery: (
    e: react.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleClearQuery: () => void;
  doQuery: () => void;
};

export const SearchBar: FC<SearchBarProps> = ({
  label,
  query,
  handleSetQuery,
  handleClearQuery,
  doQuery
}) => {
  return (
    <TextField
      margin="normal"
      variant="outlined"
      label={label}
      style={{ width: 600 }}
      value={query}
      onChange={(e) => handleSetQuery(e)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClearQuery}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};
