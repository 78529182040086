import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
export const BroadcastAreaTable = (props: any) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="Broadcast Area Table">
        <TableHead>
          <TableRow>
            <TableCell>Area ID</TableCell>
            <TableCell>Area Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.broadcastAreas.map((e:any) => (
            <TableRow
              key={e.id}
              hover
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{e.area_id}</TableCell>
              <TableCell>{e.name}</TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={()=>{props.onDeleteClicked(e.id);}}
                  startIcon={<CloseIcon />}
                >
                  DELETE
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
