import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Stack, Button, Chip, Typography } from "@mui/material";
import { getBusinessEntity, getBusinessOutletsAsync, updateBusinessEntity, createBusinessEntity } from "./../common/apiHelpers";
import { BusinessEntity, BusinessEntityOutlet } from "./../common/types";
import { BooleanDropdown, Dropdown } from "./../components/fields";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
// section displaying linked entities
// entities here are either linked parent entities or linked child entities
const BusinessLinkedEntities = (props: any) =>	{
	const relationship = props.relationship;
	const entityId: number = props.entityId;
	const entities: any[] = props.entities;
	const noContent: string = props.noContent;
	const header: string = props.header;
	const [currentId, setCurrentId] = useState<number>(0);
	const [query, setQuery] = useState<string>("");
	const [applySearch, setApplySearch] = useState<boolean>(false);
	const [linked, setLinked] = useState<number[]>(props.entityIds === null ? [] : props.entityIds);
	// effects
	const watchLinkedEntities = () => {
		if(props.entityIds !== linked){
			setLinked(props.entityIds);	
		}	
	};
	useEffect(watchLinkedEntities);
	// grab options for dropdown
	// need to filter out thjew id for then entity thast we're currently viewing
	let options = entities.map((e,i)=>{ return {text: e.name, value: e.id}} );
	options = options.filter((x)=>{ return x.value * 1 !== entityId * 1 });
	if(applySearch){
		const q = query.toLowerCase();
		options = options.filter((x)=>{ return x.text.toLowerCase().indexOf(q) !== -1;});	
	}
	options = [{text:"Please select an option ...", value:"0"}, ...options];
	let content: any = (
		<Box sx={{marginTop:"15px", marginBottom:"15px"}}>
			<Typography sx={{textAlign:"center"}}>{noContent}</Typography>
		</Box> 
	);
	if(linked !== undefined && linked !== null && linked.length > 0){
					const chips = linked.map((e,i)=>{ return <Chip key={`linked_entity_${e}`} label={entities.find((x)=>{return x.id === e}).name} color="primary" onDelete={()=>{props.onBusinessDeleted(e);}} variant="outlined" />});
		content  = (
			<Box sx={{marginTop:"20px", marginBottom:"20px"}}>
				{chips}
			</Box>	
		);
	}
	return (
		<Box sx={{border:"1px solid #CDCDCD", borderRadius:"10px", padding:"15px"}}>
			<Typography variant={"body1"}>{header}</Typography>
			<Box>
				{content}
			</Box>
			<Box>
				<Grid container>
					<Grid item xs={12}>
						<TextField value={query} onChange={(event)=>{setQuery(event.target.value);}} label={"Search business entities ..."} helperText={"Search for a business by name ..."} fullWidth sx={{marginBottom:"15px"}} onBlur={()=>{setApplySearch(true);}}/>
					</Grid>	
				</Grid>
				<Grid container>
				<Grid item xs={9}>
				<Dropdown options={options} value={currentId} onValueChanged={(e:any)=>{setCurrentId(e.target.value);}} helperText="Select a business entity to link" label="Entity"/>
				</Grid>
				<Grid item xs={3}>
				<Button size="large" variant="contained" onClick={()=>{props.onBusinessLinked(currentId);}} sx={{marginLeft:"15px"}}>Link</Button>
				</Grid>
				</Grid>
			</Box>
		</Box>
	);	
};
const UpdateBusinessForm  = (props: any) => {
	const entities = props.entities;
	const entityId = props.entityId;
	const editing: boolean = props.edit !== undefined ? props.edit : false;
	// data, fetched from backend
	const [entity, setEntity] = useState<BusinessEntity|null>(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const {enqueueSnackbar}  = useSnackbar();
	// state, derived from above
	const [name, setName] = useState<string>("");
	const [commonName, setCommonName] = useState<string|null|undefined>("");
	const [status, setStatus] = useState<string>("");
	const [abn, setABN] = useState<string>("");
	const [acn, setACN] = useState<string>("");
	const [mainLocation, setMainLocation] = useState<string>("");
	const [entityType, setEntityType] = useState<string>("");
	const [abrLink, setAbrLink] = useState<string|null|undefined>("");
	const [acncLink, setAcncLink] = useState<string|null|undefined>("");
	const [asicLink, setAsicLink] = useState<string|null|undefined>("");	
	const [childIds, setChildIds] = useState<number[]>([]);
	const [parentIds, setParentIds] = useState<number[]>([]);
  const [gstRegistration, setGstRegistration] = useState<string|undefined>("");
	// boolean flags
	const [charitableStatus, setCharitableStatus] = useState<boolean>(false);
	const [deductibleGiftStatus, setDeductibleGiftStatus] = useState<boolean>(false);
	const [gstConcession, setGstConcession] = useState<boolean>(false);
	const [fbtRebate, setFbtRebate] = useState<boolean>(false);
	const [incomeTaxExempt, setIncomeTaxExempt] = useState<boolean>(false);
	// other data
	const entityTypeOptions = [
		{ value: "other", text: "Other" },
		{ value: "co-operative", text: "Co-operative" },
		{ value: "hybrid trust", text: "Hybrid Trust" },
		{ value: "other unincorporated entity", text: "Other Unincorporated Entity" },
		{ value: "local government entity", text: "Local Government Entity" },
		{ value: "state government entity", text: "State Government Entity" },
		{ value: "discretionary investment trust", text: "Discretionary Investment Trust" },
		{ value: "australian public company", text: "Australian Public Company" },
		{ value: "commonwealth government entity", text: "Commonwealth Government Entity" },
		{ value: "individual/sole trader", text: "Individual / Sole Trader" },
		{ value: "discretionary trading trust", text: "Discretionary Trading Trust" },
		{ value: "family partnership", text: "Family Partnership" },
		{ value: "australian private company", text: "Australian Private Company" },
		{ value: "other partnership", text: "Other Partnership" },
		{ value: "fixed unit trust", text: "Fixed Unit Trust" },
		{ value: "other trust", text: "Other Trust" },
		{ value: "other incorporated entity", text: "Other Incorporated Entity" }
	];
	// events
	const onSubmitClicked = () => {
		// payload for creating and editing a business record
		const payload : BusinessEntity = {
			entity_name : name,
			entity_status: status,
			entity_abn: abn,
			entity_acn: acn,
			entity_common_name: commonName,
			entity_type: entityType,
			entity_location: mainLocation,
			entity_asic_link:  asicLink,
			entity_abr_link: abrLink,
			entity_acnc_link: acncLink,
			entity_owned_by_entity_ids: parentIds, 
			entity_owns_entity_ids: childIds,	
      entity_tax_gst: gstRegistration,
			entity_tax_gst_concession: gstConcession, 
			entity_tax_income_exemption: incomeTaxExempt, 
			entity_tax_fbt_rebate:  fbtRebate,
			entity_tax_dgr: false, 
			entity_charitable_status_short: charitableStatus, 
			entity_id: entityId,
			entity_deductible_gift_status_short: deductibleGiftStatus
		};
		const s = () => {
			if(editing){
				props.onBusinessUpdated();
				return;
			}
			props.onBusinessCreated();
		};
		const e = (status: number) => {
			if(status === 401){
				localStorage.clear();
				document.location.href = "/";
				return;
			}
			enqueueSnackbar("Something went wrong, please check backend.", {variant:"error"});
		};
		if(editing){
			updateBusinessEntity(entityId, payload, s);
			return;
		}
		createBusinessEntity(payload, s, e);
	};
	// effect handlers
	// load business entity
	const loadEntity = () => {
		if(editing && entity === null){
			const s = async (data: BusinessEntity) => {
				// set entity data first
				// this will stop this effect from running
				setEntity(data);	
				// set other data properties
				setName(data.entity_name);
				setCommonName(data.entity_common_name);
				setStatus(data.entity_status);
				setABN(data.entity_abn);
				setACN(data.entity_acn);
				setMainLocation(data.entity_location);
				setEntityType(data.entity_type);
				setAbrLink(data.entity_abr_link);
				setAcncLink(data.entity_acnc_link);
				setAsicLink(data.entity_asic_link);
				setCharitableStatus(data.entity_charitable_status_short);
				setGstRegistration(data.entity_tax_gst);
				setGstConcession(data.entity_tax_gst_concession);
				setFbtRebate(data.entity_tax_fbt_rebate);
				setIncomeTaxExempt(data.entity_tax_income_exemption);
				setDeductibleGiftStatus(data.entity_deductible_gift_status_short);
				const childIds = data.entity_owns_entity_ids;
				const parentIds = data.entity_owned_by_entity_ids;	
				setChildIds(childIds === undefined || childIds === null ? [] : childIds);
				setParentIds(parentIds === undefined || parentIds === null ? [] : parentIds);
			};
			getBusinessEntity(entityId, s);
		}
	};	
	const linkChildEntity = (e: number) => {
		const copy = [...childIds];
		copy.push(e);
		setChildIds(copy);
	};
	const linkParentEntity = (e: number) => {
		const copy = [...parentIds];
		copy.push(e);
		setParentIds(copy);
	};
	const unlinkChildEntity = (e: number) => {
		const copy = [...childIds];
		const i = copy.findIndex((x)=>{return x === e});
		if(i === undefined){
			throw new Error("Failed to find child entity during deletion.");
		}
		copy.splice(i, 1);
		setChildIds(copy);
	};
	const unlinkParentEntity = (e: number) => {
		const copy = [...parentIds];
		const i = copy.findIndex((x)=>{ return x == e });
		if(i === undefined){
			throw new Error("Failed to find parent id during deletion");
		}
		copy.splice(i,1);
		setParentIds(copy);
	};
	// effects
	useEffect(loadEntity);
	if(entity === null && editing){
		return (
			<p>Loading ...</p>
		);	
	}
	return (
		<Grid container>
			<Grid item sm={12} sx={{padding:"10px"}}>
				<Stack spacing={3}>
					<TextField value={name} onChange={(event)=>{setName(event.target.value);}} fullWidth label="Name" helperText={"The businesses official name, according to the ABR."}/>
					<TextField value={commonName} onChange={(event)=>{setCommonName(event.target.value);}} fullWidth label="Common Name" helperText={"A businesses common name might not be the same as its official name."}/>
					<TextField value={status} onChange={(event)=>{setStatus(event.target.value);}} fullWidth label="Entity Status" helperText={""} />
					<TextField value={abn} onChange={(event)=>{setABN(event.target.value);}} fullWidth label="ABN" helperText={"Australian Business Number"} />
					<TextField value={acn} onChange={(event)=>{setACN(event.target.value);}} fullWidth label="ACN" helperText={"Australian Company Number"} />
					<TextField value={mainLocation} onChange={(event)=>{setMainLocation(event.target.value);}} fullWidth label="Main Business Location" helperText={"Where does this business primarily operate?"} />
					<TextField value={gstRegistration} onChange={(event)=>{setGstRegistration(event.target.value);}} fullWidth label="GST Registration" helperText={"Is this business registration for GST, if so since when?"} />
					<Dropdown 
						value={entityType} 
						onValueChanged={(event:any)=>{setEntityType(event.target.value);}} 
						fullWidth 
						label="Business Type" 
						options={entityTypeOptions} 
						helperText={"How is this business structured? Choose other if you aren't sure."} />
					<TextField value={abrLink} onChange={(event)=>{setAbrLink(event.target.value);}} fullWidth label="Link to ABR Page" helperText={""} />
					<TextField value={acncLink} onChange={(event)=>{setAcncLink(event.target.value);}} fullWidth label="Link to ACNC Page" helperText={""} />
					<TextField value={asicLink} onChange={(event)=>{setAsicLink(event.target.value);}} fullWidth label="Link to ASIC Page" helperText={""} />
					<BooleanDropdown value={charitableStatus} 
						onChange={(value: boolean)=>{setCharitableStatus(value);}} 
						label={"Charitable Status"} 
						helperText={"Is this business registered with ACNC?"} />
					<BooleanDropdown value={gstConcession} 
						onChange={(value: boolean)=>{setGstConcession(value);}} 
						label={"GST Concession Status"} 
						helperText={"Does this business receive a GST concession?"} />
					<BooleanDropdown value={fbtRebate} 
						onChange={(value: boolean)=>{setFbtRebate(value);}} 
						label={"FBT Rebate Status"} 
						helperText={"Does this business receive an FBT rebate?"} />
					<BooleanDropdown value={incomeTaxExempt} 
						onChange={(value: boolean)=>{setIncomeTaxExempt(value);}} 
						label={"Income Tax Exemption Status"} 
						helperText={"Does this business receive an income tax exemption?"} />
					<BooleanDropdown value={deductibleGiftStatus} 
						onChange={(value: boolean)=>{setDeductibleGiftStatus(value);}} 
						label={"Deductible Gift Status"} 
						helperText={"Can this business receive tax deductible gifts?"} />
					<BusinessLinkedEntities  
							onBusinessLinked={linkChildEntity} 
							onBusinessDeleted={unlinkChildEntity} 
							relationship="child" 
							entityId={entityId} 
							onDelete={()=>{}} 
							entityIds={childIds} 
							header={"Child Entities"} 
							entities={entities} 
							noContent={"This business does not any entities."} />
					<BusinessLinkedEntities 
							onBusinessLinked={linkParentEntity} 
							onBusinessDeleted={unlinkParentEntity} 
							relationship="parent" 
							entityId={entityId} 
							onDelete={()=>{}} 
							entityIds={parentIds} 
							header={"Parent Entities"} 
							entities={entities} 
							noContent={"This business is not owned by any entities."} />
					<Button variant={"contained"} fullWidth onClick={onSubmitClicked} size={"large"}>Submit</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default UpdateBusinessForm;
