// vim: ts=2
import React, { useEffect, useState, useCallback, useContext } from "react";
import DataTable from "./../components/DataTable/DataTable";
import { Link } from "react-router-dom"; 
import { ReactWindowTable } from "../components/ReactWindowTable/ReactWindowTable";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Dialogs from "./../common/dialogs";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Grid
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
import { OutletForm } from "./OutletForm";
import { getOrganisations, getOrganisationsExport, deleteOrganisation } from "../common/apiHelpers";
import { OutletsResponse, SearchContextType, CreateBusinessDialog } from "../common/types";
import { SearchBar } from "../components/SearchBar";
import { SearchContext } from "./../common/searchContext";
const COLS = [
	{ headerName: "Name", field: "org_name" },
	{ headerName: "Date Created", field: "created_at_string" },
	{ headerName: "Type", field: "org_type" }, { headerName: "Short Description", field: "org_short_description" },
	{ headerName: "", field: "delete" },
	{ headerName: "", field: "show" }
];
const SORT = {
	"Name": { sorted: false, mode: "none" },
	"Date Created": { sorted: false, mode: "none" }
};
export function Organisations() {
	const {enqueueSnackbar } = useSnackbar();
	const context: SearchContextType = useContext(SearchContext);
	const [organisations, setOrganisations] = useState<any>(null);
  const [createOrganisation, setCreateOrganisation] = useState<null|any>(null);
  const [confirmation, setConfirmation] = useState<null|any>(null);
  const [editOrganisation, setEditOrganisation] = useState<null|any>(null);
	const [query, setQuery] = useState<string>("");
	const [sort, setSort] = useState<any>(SORT);
	const confirmDeleteOrganisation = () => {
		const entityId = confirmation.entityId;
		const s = (response:any) => {
			enqueueSnackbar("Organisation deleted.", {variant:"success"});	
      setConfirmation(null);
			setOrganisations(null);
		};
		const e = () => {
			enqueueSnackbar("Something went wrong, please check logs.", {variant:"error"});	
		};
		deleteOrganisation(entityId, s, e);
	};
	const onDeleteClicked  = (event:any, id:any, name:string) => {
		setConfirmation({entityId: id, message:`Are you sure you want to delete the organisation ${name}? This will delete all associated outlet and business linkages as well as the organisation record. Please click yes to confirm, no to cancel.`, open:true});
	};
	const doEditOrganisation = (organisationId: string) => {
		const edit = {
			open: true,
			entityId: organisationId
		};
		setEditOrganisation(edit);
	}
	const loadOrganisations = () => {
		if(organisations !== null){
			return;
		}
		const s = async (response: any) => {
			const data = await response.json();
			const records = data.map((e: any, i: any)=>{
				return {
					id: e.org_id,
					org_name: e.org_name,
					org_type: e.org_type,
          created_at: new Date(Date.parse(e.created_at)),
          created_at_string: new Date(Date.parse(e.created_at)).toLocaleDateString(),
					org_short_description: e.org_short_description,
					delete: (
        		<Button
							key={`delete_${e.org_id}`}
          		variant="text"
          		onClick={(event)=>{onDeleteClicked(event, e.org_id, e.org_name);}}
          		startIcon={<CloseIcon />}>
          		DELETE
       			</Button>
					),
					show: (
						<Button 
							key={`edit_${e.org_id}`}
							variant="text" 
							onClick={()=>{doEditOrganisation(`${e.org_id}`);}} 
          		startIcon={<AddIcon />}>
							EDIT
						</Button>
					) 
				};
			});
			context.records = [...records];
			setOrganisations(records);
		};
		const e = (error:any) => {
			enqueueSnackbar("Failed to load organisations, please check backend.", {variant:"error"});
		};
		getOrganisations(s, e);
	};
	const watchQuery = () => {
		if(query === null || query === undefined || query === null || query === ""){
			return;
		}	
		if(context.previousQuery === null || context.previousQuery === undefined || context.previousQuery !== query){
			doQuery();
			return;
		}
		if(context.previousQuery === query){
			return;
		}
	};
	useEffect(loadOrganisations);
	useEffect(watchQuery);
	// wait for organisations to load ...
	if(organisations === null){
		return (
			<Grid container>
				<Grid item sm={12}>
					<p>Loading ...</p>
				</Grid>
			</Grid>
		);	
	}
	const doQuery = () => {
		// query businesses here ...
		// search on either ABN or name
		const filter = (e: any) => {
			return e.org_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
		};
		const matches = context.records.filter(filter);
		context.previousQuery = query;
		setOrganisations(matches);
	};
	const clearQuery = () => {
		const copy = [...context.records];
		context.previousQuery = "";
		setQuery("");
		setOrganisations(copy);
	};
	const updateQuery = (event: any) => {
		const q = event.target.value;	
		if(q === ""){
			clearQuery();
			return;
		}
		setQuery(q);
	};
	const doExportOrganisations = () => {
		const s = async (response: any) => {
			const file = await response.text();
			const url = URL.createObjectURL(new Blob([file]));
			const link: any = document.getElementById("download");
			if(link !== null){
				link.href = url;
				link.click();
			}
		};
		const e = () => {
			enqueueSnackbar("Failed to generate organisation export", {variant:"error"});
		};
		getOrganisationsExport(s, e);
	};
	const doCreateOrganisation = () => {
      const create = {
          open: true
			};
			setCreateOrganisation(create);
	};
	const sortData = (sortState:any, headerName: string) => {
		const sortDateAsc = (left:any, right:any) => {
			return left.created_at.getTime() - right.created_at.getTime();
		};
		const funs: any = {
			"Name": { "asc": (left:any, right:any) => { return left.org_name.localeCompare(right.org_name)}, "desc": (left:any, right:any) => { return right.org_name.localeCompare(left.org_name)} },
			"Date Created": { "asc": sortDateAsc, "desc": (left:any , right:any) => { return right.created_at.getTime() - left.created_at.getTime() } } 	
		};
		const f: any = funs[headerName][sortState.mode];
		context.records.sort(f);	
		setOrganisations([...context.records]);
	};
	const onSortClicked = (event: any, headerName: string) => {
		const sorted: any = {...sort};
		const sortState = sorted[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			sortData(sortState, headerName);
			setSort(sorted);
			return;
		}
		const mode = sortState.mode === "asc" ? "desc" : "asc";
		sortState.mode = mode;
		sortData(sortState, headerName);
		setSort(sorted);
	};
  return (
		<Dialogs 
			createOrganisation={createOrganisation} 
			onCreateOrganisationClosed={()=>{setCreateOrganisation(null);}}
			onOrganisationCreated={()=>{enqueueSnackbar("Organisation created", {variant:"success"});setCreateOrganisation(null);setOrganisations(null);}}
			editOrganisation={editOrganisation}
			onEditOrganisationClosed={()=>{setEditOrganisation(null);}}
			onOrganisationUpdated={()=>{enqueueSnackbar("Organisation updated", {variant:"success"});setEditOrganisation(null);setOrganisations(null);}}
			confirmation={confirmation}
      onConfirmationClosed={()=>{setConfirmation(null);}}
      onConfirmationCancelled={()=>{setConfirmation(null);}}
      onConfirmationAccepted={confirmDeleteOrganisation}>
			<Box>
				<SearchBar
					label="Search organisations ..."
					query={query}
					handleClearQuery={clearQuery}
					handleSetQuery={updateQuery}
					doQuery={doQuery} />
				<Button variant="contained" onClick={doCreateOrganisation} sx={{marginTop:"20px", marginLeft:"15px"}} size="large">CREATE</Button>
				<Button variant="contained" onClick={doExportOrganisations} sx={{marginTop:"20px", marginLeft:"15px"}} size="large">EXPORT</Button>
			</Box>
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				position="relative"
				marginBottom="45px"
				marginTop="30px">
					<Box sx={{width:"100%"}}>
						<DataTable rows={organisations} columns={COLS} sort={sort} onRowClicked={()=>{}} onHeaderClicked={()=>{}} onSortClicked={onSortClicked} filters={[]} />
					</Box>
			</Box>
			<a id="download" href="#" download="organisations-export.csv" style={{display:"none"}} />
		</Dialogs>
  );
}
