import React, { useContext } from "react";
import { UserContext } from "./../common/userContext";
import { UserContextType } from "./../common/types";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import { AssignmentTurnedIn } from "@mui/icons-material";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CampaignIcon from '@mui/icons-material/Campaign';
import BusinessIcon from "@mui/icons-material/Business";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { Link, useLocation } from "react-router-dom";

export const MainNavigation = () => {
  const location = useLocation();
	const context: UserContextType = useContext(UserContext);
  return (
    <List sx={{ width: "300px" }}>
      <ListItemButton
        selected={location.pathname.includes("/outlets")}
        component={Link}
        to="/outlets">
        <ListItemIcon>
          <ChatBubbleOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="Outlets" />
      </ListItemButton>
      <ListItemButton
        selected={location.pathname.includes("/changes")}
        component={Link}
        to="/changes">
        <ListItemIcon>
          <CampaignIcon />
        </ListItemIcon>
        <ListItemText primary="Outlet Changes" />
      </ListItemButton>
			<ListItemButton
				selected={location.pathname.includes("/businesses")}
				component={Link}
				to="/businesses">
				<ListItemIcon>
					<BusinessIcon/>
				</ListItemIcon>	
				<ListItemText primary="Businesses"/>
			</ListItemButton>
			<ListItemButton
				selected={location.pathname.includes("/organisations")}
				component={Link}
				to="/organisations">
				<ListItemIcon>
					<CorporateFareIcon/>
				</ListItemIcon>	
				<ListItemText primary="Organisations"/>
			</ListItemButton>
      <ListItemButton
				id={"contactsLink"}
        selected={location.pathname.includes("/contacts")}
        component={Link}
        to="/contacts">
        <ListItemIcon>
          <PermContactCalendarIcon />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </ListItemButton>
      <ListItemButton
				id={"codeOfPracticeLink"}
        selected={location.pathname.includes("/code-of-practice")}
        component={Link}
        to="/code-of-practice">
        <ListItemIcon>
          <AssignmentTurnedIn/>
        </ListItemIcon>
        <ListItemText primary="Code of Practice" />
      </ListItemButton>
			{context.role === "ADMINISTRATOR" ? <ListItemButton
				selected={location.pathname.includes("/change-requests")}
				component={Link}
				to={"/change-requests"}>
				<ListItemIcon>
					<ChangeCircleIcon/>					
				</ListItemIcon>
				<ListItemText primary={"Change Requests"}/>
			</ListItemButton> : null}
    </List>
  );
};
