import React from "react";
import { TextField, MenuItem } from "@mui/material";
export const Dropdown = (props: any) => {
	const options = props.options;
	const value = props.value;
	return (
		<TextField select value={value} onChange={props.onValueChanged} fullWidth label={props.label} helperText={props.helperText}>
			{options.map((e:any,i:any)=>{ return <MenuItem key={`${props.name}_boolean_dropdown_option_${i}`} value={e.value}>{e.text}</MenuItem>})}
		</TextField>
	);	
};
export const BooleanDropdown = (props: any) => {
	const options = [
		{ value: "", text: "Please select an option ..." },
		{ value: "Y", text: "Yes" },
		{ value: "N", text: "No" }
	];
	const value = props.value === true ? "Y" : "N";
	const onValueChanged = (event: any) => {
		const v = event.target.value;	
		if(v === "Y"){
			props.onChange(true);
		}else props.onChange(false);
	};
	return (
		<TextField select value={value} onChange={onValueChanged} fullWidth label={props.label} helperText={props.helperText}>
			{options.map((e,i)=>{ return <MenuItem key={`${props.name}_boolean_dropdown_option_${i}`} value={e.value}>{e.text}</MenuItem>})}
		</TextField>
	);	
};
