// vim: ts=2
import React, { useEffect, useMemo, useState, useContext } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import Dialogs from "./../common/dialogs";
import { SearchContextType } from "../common/types";
import { SearchContext } from "./../common/searchContext";
import {
	Stack,
	Grid,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useMutation, useQuery } from "react-query";
import { OutletStateTable } from "./OutletStateTable";
import { BusinessEntityTable } from "./BusinessEntityTable";
import { OrganisationTable } from "./OrganisationTable";
import { CodeOfPracticeTable } from "./CodeOfPracticeTable";
import { BroadcastAreaTable } from "./BroadcastAreaTable";
import { DataDisplay } from "../components/DataDisplay";
import { ContactForm } from "./ContactForm";
import { TabBody } from "../components/TabBody";
import {
  BusinessStructureEnum,
  ContactResponse,
  OutletState,
  OutletStatesResponse,
  PublicationFormatEnum,
  PublicationScheduleEnum,
  ScaleEnum
} from "../common/types";
import { ContactLabels, OutletStateLabels } from "../common/constants";
import {
  getContact,
  getOutletStates,
  getBusinessEntitiesForOutlet,
  deleteBusinessEntityOutletLinkage,
  deleteOutletState,
  getHeaders,
	getOutletBroadcastAreas,
  getOutletOrganisations,
  deleteBroadcastAreaOutletLinkage,
	unlinkOrganisationFromOutlet,
	getOutletCodesOfPractice,
	unlinkCopFromOutlet
} from "../common/apiHelpers";
import { OutletStateEditForm } from "./OutletStateEditForm";
import { OutletStateUpdateForm } from "./OutletStateUpdateForm";
import { useSnackbar } from "notistack";
import { parse } from "date-fns";

const getExportAll = (outletId: string) => {
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/outlets/${outletId}/outlet-state/?export=true`,
    {
      headers: getHeaders()
    }
  )
    .then((response) => response.text())
    .then((text) => {
      const blob = new Blob([text], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      return url;
    });
};

export function Outlet() {
  const { outletId } = useParams<{ outletId: string }>();
	const [businesses, setBusinesses] = useState<any[]|null>(null);
	const [broadcastAreas, setBroadcastAreas] = useState<any[]|null>(null);
	const [organisations, setOrganisations] = useState<any[]|null>(null);
	const [cops, setCops] = useState<any[]|null>(null);
	const [linkBusinessDialog, setLinkBusinessDialog] = useState<any|null>(null);
	const [linkBroadcastAreaDialog, setLinkBroadcastAreaDialog] = useState<any|null>(null);
	const [linkOrganisationDialog, setLinkOrganisationDialog] = useState<any|null>(null);
	const [linkCodeOfPracticeDialog, setLinkCodeOfPracticeDialog] = useState<any|null>(null);
	const [editingContact, setEditingContact] = useState<boolean>(false);
	const context: any = useContext(SearchContext);
  const match = useRouteMatch<{ outletId: string; outletStateId: string }>(
    "/outlets/:outletId/show/:outletStateId"
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: outletStates,
    isLoading: isLoadingOutletStates,
    isError: isErrorOutletStates,
    refetch: refetchOutletStates
  } = useQuery<OutletStatesResponse>(["outletStates", outletId], () =>
    getOutletStates({ id: outletId })
  );

  const {
    data: contact,
    isLoading: isLoadingContact,
    isError: isErrorContact
  } = useQuery<ContactResponse>(["contact", outletId], () =>
    getContact({ id: outletId })
  );

  const { data: exportUrl, refetch: refetchExport } = useQuery<string>(
    ["exportOutletStates", outletId],
    () => getExportAll(outletId)
  );

  const {
    mutate: mutateOutletState,
    isLoading: isLoadingOutlet,
    isError: isErrorMutateOutlet,
    isSuccess: isSuccessOutlet
  } = useMutation(deleteOutletState, {
    onSuccess: () => {
      setDeleteDialog({
        open: false,
        id: null,
        name: null
      });
      refetchOutletStates();
    }
  });
  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(
    undefined
  );
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    id: string | number | null;
    name: string | null;
  }>({
    open: false,
    id: null,
    name: null
  });
  const [editDialog, setEditDialog] = useState<{
    open: boolean;
    outletState: OutletState | null;
  }>({
    open: false,
    outletState: null
  });
  const [updateDialog, setUpdateDialog] = useState<{
    open: boolean;
  }>({
    open: false
  });
  const [tabIndex, setTabIndex] = useState(0);

  const refetch = () => {
    refetchOutletStates();
    refetchExport();
  };

  useEffect(() => {
    if (isErrorMutateOutlet) {
      enqueueSnackbar("Unable to delete outlet state", { variant: "error" });
    }
    if (isErrorContact) {
      enqueueSnackbar("Unable to get contact", { variant: "error" });
    }
    if (isErrorOutletStates) {
      enqueueSnackbar("Unable to get all outlet states", { variant: "error" });
    }
  }, [isErrorMutateOutlet, isErrorContact, isErrorOutletStates]);
	
	// load businesses
	useEffect(()=>{
		if(businesses !== null){
			return;		
		}	
		const s = async (response: any) => {
			const data = await response.json();
			setBusinesses(data);
		};
		const e = () => { enqueueSnackbar("Failed to load business entities for outlet.", {variant: "error"}); };
		getBusinessEntitiesForOutlet(outletId, s, e);
	});
  // load broadcast areas
	useEffect(()=>{
		if(broadcastAreas !== null){
			return;
		}	
		const s = async (response:any) => {
			const data = await response.json();
			setBroadcastAreas(data);
		};
		const e = (error:any) => {
			enqueueSnackbar("Failed to load broadcast areas", {variant:"error"});
		};
		getOutletBroadcastAreas(outletId, s, e);
	});
	// load organisations
  useEffect(()=>{
		if(organisations !== null){
			return;
		}			
		const s = async (response:any) => {
			const data = await response.json();
			setOrganisations(data);
		};
		const e = () => {
			enqueueSnackbar("Failed to organisations", {variant:"error"});
		};
		getOutletOrganisations(outletId, s, e)
	});
	// load cops
	useEffect(()=>{
		if(cops !== null){
			return;
		}	
		const s = async (response:any) =>	{
			const data = await response.json();
			setCops(data);
		};
		const e = (error:any) => {
		};
		getOutletCodesOfPractice(s, e, outletId);
	});
  const closeMenu = () => {
    setAnchorElement(undefined);
  };

  const handleExportButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

	const deleteCopLinkage = (copId:number, outletId:string) => {
		const s = (response:any) => {
			enqueueSnackbar("Code of practice unlinked", {variant:"success"});
      setCops(null);
		};
		const e = (error:any) => {
		}
		unlinkCopFromOutlet(s, e, copId, outletId);
	};
	const deleteOrganisationLinkage = (organisationId: number, outletId: string) => {
      const s = () => {
					enqueueSnackbar("Organisation unlinked", {variant:"success"});
          setOrganisations(null);
      };	
			const e = () => {
				enqueueSnackbar("Failed to unlink outlet from organisation.", {variant:"error"});
			};
			unlinkOrganisationFromOutlet(organisationId, outletId, s, e);
	};
  const deleteBroadcastAreaLinkage = (areaId: number, outletId: string) => {
      const s = () => {
					enqueueSnackbar("Broadcast area unlinked", {variant:"success"});
          setBroadcastAreas(null);
      };	
			const e = () => {
				enqueueSnackbar("Failed to unlink broadcast area from organisation.", {variant:"error"});
			};
      deleteBroadcastAreaOutletLinkage(areaId, outletId, s, e);
	};

  const deleteBusinessEntityLinkage = (entityId: number, outletId: string) => {
      const s = () => {
					// TODO snackbar
          setBusinesses(null);
      };	
      deleteBusinessEntityOutletLinkage(entityId, outletId, s);
  };

  const latestOutletState = useMemo(() => {
    if (!outletStates || outletStates.outlet_state.length === 0) {
      return null;
    }
    return (
      outletStates?.outlet_state?.find(
        (outlet) => outlet.status === "active"
      ) ||
      outletStates?.outlet_state?.sort((a, b) => {
        if (!a?.deprecated_at || !b?.deprecated_at) {
          return 0;
        }
        return (
          parse(a.deprecated_at, "yyyy-MM-dd", new Date()).getTime() -
          parse(b.deprecated_at, "yyyy-MM-dd", new Date()).getTime()
        );
      })[0]
    );
  }, [outletStates]);

  if (isLoadingOutletStates) {
    return <div>Loading ...</div>;
  }

  if (isErrorOutletStates || !outletStates) {
    return <div>Error ...</div>;
  }
  const linkBroadcast = (
  <Button
    variant="contained"
  	sx={{marginRight:"16px"}}
    onClick={() => {
   		setLinkBroadcastAreaDialog({
      	open: true,
				entityId: outletId
      });
    }}
   	startIcon={<AddIcon />}
  >
		LINK BROADCAST AREA
  </Button>
	);
	const linkOrg = ( 
		<Button variant="contained"
			sx={{mr:"16px"}}
			onClick={() => {
				setLinkOrganisationDialog({
        	open: true,
					entityId: outletId,
					entityType: "Outlet"
        });
			}}
			startIcon={<AddIcon />}
		>
			LINK ORGANISATION
		</Button>
	);
	const linkCop = (
		<Button variant="contained"
			onClick={() => {
				setLinkCodeOfPracticeDialog({
        	open: true,
					entityId: outletId,
					entityType: "Outlet"
        });
			}}
			startIcon={<AddIcon />}
		>
			LINK CODE OF PRACTICE
		</Button>
	);
  const noBroadcastAreas = (
    <Box sx={{textAlign:"center", paddingTop:"30px"}}>
			<span style={{backgroundColor:"#EFEFEF", padding:"30px", borderRadius:"5px", border:"1px solid #DEDEDE"}}>No broadcast areas are linked against this outlet, please click the following button to link one <span style={{marginLeft:"15px"}}>{linkBroadcast}</span></span>
		</Box>
	);
  let noOrgs = (
    <Box sx={{textAlign:"center", paddingTop:"30px"}}>
			<span style={{backgroundColor:"#EFEFEF", padding:"30px", borderRadius:"5px", border:"1px solid #DEDEDE"}}>
				No organisations are linked against this outlet, please click the following button to link one <span style={{marginLeft:"15px"}}>{linkOrg}</span>
			</span>
		</Box>
	);
  let noCops = (
    <Box sx={{textAlign:"center", paddingTop:"30px"}}>
			<span style={{backgroundColor:"#EFEFEF", padding:"30px", borderRadius:"5px", border:"1px solid #DEDEDE"}}>
				No codes of practice are linked against this outlet, please click the following button to link one <span style={{marginLeft:"15px"}}>{linkCop}</span>
			</span>
		</Box>
	);
	if(organisations === null){
		noOrgs = (
    <Box sx={{textAlign:"center", paddingTop:"30px"}}>
			<span style={{backgroundColor:"#EFEFEF", padding:"30px", borderRadius:"5px", border:"1px solid #DEDEDE"}}>
				Loading organisations ...
			</span>
		</Box>
		);
	}
	const editContact = (contact:any) => {
		context.selectedContact = contact;
		setEditingContact(true);
	};
	const addContact = () => {
		context.selectedContact = {
			id: outletId
		};
		context.createContact = true;
		setEditingContact(true);
	};
	const editingDialog = (
		<Dialog open={editingContact} onClose={()=>{setEditingContact(false);}} maxWidth={"sm"} fullWidth>
			<DialogContent>
				<ContactForm contact={context.selectedContact} closeDialog={()=>{setEditingContact(false);context.selectedContact=null;}}/>
			</DialogContent>
		</Dialog>	
	);
	const noContact = (
		<Box sx={{backgroundColor:"#EDEDED", padding:"15px", border:"1px solid #CDCDCD", borderRadius:"5px", textAlign:"center", ml:"25%", mr:"25%"}}>
			<Typography variant={"body1"} sx={{verticalAlign:"middle"}}>
				<InfoIcon sx={{verticalAlign:"middle", width:"36px", height:"auto", marginRight:"15px"}}/>
				No contact exists for this outlet <Button size={"large"} onClick={addContact}>create one</Button>
			</Typography>	
		</Box>
	);
	{/* start rendering */}
  return (
    <Dialogs linkBusinessDialog={linkBusinessDialog} 
			onLinkBusinessClosed={()=>{setLinkBusinessDialog(null);}}
			onBusinessLinked={()=>{setLinkBusinessDialog(null);setBusinesses(null);enqueueSnackbar("Business linked", {variant:"success"});}}
			linkBroadcastAreaDialog={linkBroadcastAreaDialog}	
      onLinkBroadcastAreaClosed={()=>{setLinkBroadcastAreaDialog(null);}}
      onBroadcastAreaLinked={()=>{setLinkBroadcastAreaDialog(null);enqueueSnackbar("Broadcast area linked", {variant:"success"});setBroadcastAreas(null);}}
      linkOrganisationDialog={linkOrganisationDialog}
      onLinkOrganisationClosed={()=>{setLinkOrganisationDialog(null);}}
      onOrganisationLinked={()=>{setLinkOrganisationDialog(null);enqueueSnackbar("Organisation linked", {variant:"success"});setOrganisations(null);}}
			onCodeOfPracticeLinked={()=>{setLinkCodeOfPracticeDialog(null);enqueueSnackbar("Code of practice linked", {variant:"success"});setCops(null);}}
			onLinkCodeOfPracticeClosed={()=>{setLinkCodeOfPracticeDialog(null);}}
			linkCodeOfPracticeDialog={linkCodeOfPracticeDialog}
      >
			{editingDialog}
      <Dialog
        open={deleteDialog.open}
        onClose={() => {
          setDeleteDialog({
            ...deleteDialog,
            open: false
          });
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <Typography>This cannot be undone.</Typography>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setDeleteDialog({ open: false, id: null, name: null });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                mutateOutletState({
                  outletId,
                  outletStateId: deleteDialog.id
                });
                refetch();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={updateDialog.open}
        maxWidth="sm"
        onClose={() => {
          setUpdateDialog({
            open: false
          });
        }}
        fullWidth
      >
        <DialogContent>
          <OutletStateUpdateForm
            closeDialog={() => {
              refetch();
              setUpdateDialog({ open: false });
            }}
            outletId={outletId}
            outletStateIdToRetire={
              outletStates.outlet_state.length
                ? outletStates.outlet_state[0].id
                : ""
            }
            canRetire={Boolean(
              outletStates.outlet_state.length &&
                outletStates.outlet_state[0].status === "active"
            )}
            outletState={latestOutletState}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={editDialog.open}
        maxWidth="sm"
        onClose={() => {
          setEditDialog({
            open: false,
            outletState: null
          });
        }}
        fullWidth
      >
        <DialogContent>
          <OutletStateEditForm
            closeDialog={() => {
              refetch();
              setEditDialog({ open: false, outletState: null });
            }}
            outletId={outletId}
            outletState={editDialog.outletState}
          />
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px"
        }}
      >
        <Button
          variant="text"
          onClick={() => {
            if (match?.isExact) {
              history.push(`/outlets/${outletId}`);
              return;
            }
            history.push("/outlets");
          }}
          startIcon={<ChevronLeftIcon />}
        >
          Back
        </Button>
        <Box>
          {!match && (
            <>
              <Button
                variant="outlined"
                onClick={handleExportButton}
                sx={{ marginRight: "16px" }}
              >
                EXPORT
              </Button>
              <Menu
                open={Boolean(anchorElement)}
                onClose={closeMenu}
                anchorEl={anchorElement}
                id="export-outlet-state-menu"
              >
                <MenuItem
                  component="a"
                  href={exportUrl ? exportUrl : ""}
                  download="outlets-states.csv"
                >
                  <ListItemIcon>
                    <DownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Export all</ListItemText>
                </MenuItem>
              </Menu>
              <Button
                variant="contained"
                sx={{marginRight:"16px"}}
                onClick={() => {
                  setUpdateDialog({
                    open: true
                  });
                }}
                startIcon={<AddIcon />}
              >
                UPDATE
              </Button>
              <Button
                variant="contained"
                sx={{marginRight:"16px"}}
                onClick={() => {
                  setLinkBusinessDialog({
                    open: true,
										entityId: outletId
                  });
                }}
                startIcon={<AddIcon />}
              >
                LINK BUSINESS
              </Button>
							{linkBroadcast}
							{linkOrg}
							{linkCop}
            </>
          )}
        </Box>
      </Box>
      <Typography variant="h4" sx={{marginBottom:"25px"}}>
        {outletStates.outlet_state.length
          ? outletStates.outlet_state[0].name
          : "No outlet states exist"}
      </Typography>
      <Tabs
        value={tabIndex}
        aria-label="Outlet state information contact associated with outlet"
      >
        <Tab
          onClick={() => {
            setTabIndex(0);
          }}
          label="OUTLET INFORMATION"
          id="tab-outlet-information"
          aria-controls="tabpanel-outlet-information"
        />
        <Tab
          onClick={() => {
            setTabIndex(1);
          }}
          label="CONTACT"
          id="tab-contact"
          aria-controls="tabpanel-contact"
        />
        <Tab
          onClick={() => {
            setTabIndex(2);
          }}
          label="LINKED BUSINESSES"
          id="tab-business"
          aria-controls="tabpanel-business"
        />
        <Tab
          onClick={() => {
            setTabIndex(3);
          }}
          label="BROADCAST AREAS"
          id="tab-broadcast-areas"
          aria-controls="tabpanel-broadcast-areas"
        />
        <Tab
          onClick={() => {
            setTabIndex(4);
          }}
          label="ORGANISATIONS"
          id="tab-organisations"
          aria-controls="tabpanel-organisations"
        />
        <Tab
          onClick={() => {
            setTabIndex(5);
          }}
          label="CODE OF PRACTICE"
          id="tab-cops"
          aria-controls="tabpanel-cops"
        />
      </Tabs>
      <div
        role="tabpanel"
        hidden={tabIndex !== 0}
        id="tabpanel-outlet-information"
        aria-labelledby="tab-outlet-information"
      >
        <TabBody>
          {tabIndex === 0 && !match && (
            <OutletStateTable
              outletStates={outletStates.outlet_state}
              deleteRowHandler={(options) => {
                setDeleteDialog(options);
              }}
              outletId={outletStates.outlet.id}
              editOnClickHandler={(outletState: OutletState) => {
                setEditDialog({
                  open: true,
                  outletState
                });
              }}
            />
          )}
          {tabIndex === 0 && match?.isExact && (
            <DataDisplay
              items={
                outletStates
                  ? outletStates.outlet_state?.find(
                      (outlet_state) =>
                        String(outlet_state.id) === match.params.outletStateId
                    )
                  : undefined
              }
              mapping={OutletStateLabels}
              valueMapping={{
                scale: ScaleEnum,
                publication_format: PublicationFormatEnum,
                publication_schedule: PublicationScheduleEnum
              }}
            />
          )}
        </TabBody>
      </div>
      <div
        role="tabpanel"
        hidden={tabIndex !== 1}
        id="tabpanel-contact-information"
        aria-labelledby="tab-contact-information"
      >
        <TabBody>
          {tabIndex === 1 &&
            !isErrorContact &&
            !isLoadingContact &&
            contact?.contact && (
							<Grid container>
								<Grid item xs={11}>
              		<Box>
                		<DataDisplay items={contact.contact} mapping={ContactLabels} />
              		</Box>
								</Grid>
								<Grid item xs={1}>
									<Stack spacing={1}>
										<Button color={"primary"} onClick={()=>{editContact(contact.contact);}} variant={"contained"}>Edit</Button>
									</Stack>
								</Grid>
							</Grid>
            )}
          {isErrorContact && noContact}
        </TabBody>
      </div>
			<div
				 role="tabpanel" 
					hidden={tabIndex !== 2} 
					id="tabpanel-business" 
					aria-labelledby="tab-business">
        <TabBody>
					{tabIndex === 2 && businesses !== null ? <BusinessEntityTable businessEntities={businesses} onDeleteClicked={(entityId: number)=>{deleteBusinessEntityLinkage(entityId, outletId);}}/> :  null}
        </TabBody>
			</div>
			<div
				role="tabpanel"
				hidden={tabIndex != 3}
				id="tabpanel-broadcast-areas"
				aria-labelledby="tab-broadcast-areas">
				<TabBody>
					{tabIndex === 3 && broadcastAreas !== null && broadcastAreas.length > 0 ? <BroadcastAreaTable broadcastAreas={broadcastAreas} onDeleteClicked={(areaId:number)=>{deleteBroadcastAreaLinkage(areaId, outletId);}}/> : noBroadcastAreas}
				</TabBody>
			</div>
			<div
				role="tabpanel"
				hidden={tabIndex != 4}
				id="tabpanel-organisations"
				aria-labelledby="tab-organisations">
				<TabBody>
					{tabIndex === 4 && organisations !== null && organisations.length > 0 ? <OrganisationTable organisations={organisations} onDeleteClicked={(organisationId:number)=>{deleteOrganisationLinkage(organisationId, outletId);}} />: noOrgs}
				</TabBody>
			</div>
			<div
				role="tabpanel"
				hidden={tabIndex != 5}
				id="tabpanel-cops"
				aria-labelledby="tab-cops">
				<TabBody>
					{tabIndex === 5 && cops !== null && cops.length > 0 ? <CodeOfPracticeTable cops={cops} onDeleteClicked={(id:number)=>{deleteCopLinkage(id, outletId);}}/> : noCops}
				</TabBody>	
			</div>
    </Dialogs>
  );
}
